import { Box } from "@mui/material";
import classes from "./index.module.scss";

export default function PreparationTasksImage({ src }) {
  
  return (
    <Box className={classes.image}>
      <img src={src} alt="Preparation Tasks" width={860} />
    </Box>
  );
}
