import { PlatformLayout } from "../../../components/Layouts";
import Loader from "../../../components/Common/Loader";
import { Box, Container, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import Card from "./Card";
import classes from "./index.module.scss";
import BackLink from "../../../components/Common/BackLink";
import CoursesList from "./CoursesList";
import HTMLComponent from "../../../components/Common/HTMLComponent";

export default function PathsDetails() {
  const { id } = useParams();

  const { data, isLoading } = useQuery(
    ["path", "unregistered", id],
    () => api.get(`${process.env.REACT_APP_SINGLE_PATH}/${id}/unregistered`),
    {
      staleTime: 0,
    }
  );
  const finished =
    data?.data.paths.courses?.filter((course) => course.isComplete === true)
      .length || 0;
  const isPaid =
    data?.data.paths.courses?.some((course) => course.isPaid === true) || false;

  return (
    <PlatformLayout>
      {isLoading ? <Loader /> : null}
      <Container disableGutters maxWidth={false} className={classes.container}>
        <Box className={classes.back}>
          <BackLink
            isLogoVisible={false}
            text="Back to All Paths"
            path={"/paths"}
          />
        </Box>
        <Card
          all={data?.data.paths.courses?.length || null}
          finished={finished}
          title={data?.data.paths.title}
          description={data?.data.paths.description}
          img={data?.data.paths.promoImageUrl}
          isPaid={isPaid}
        />

        <Box className={classes.wrapper}>
          <Box className={classes.left}>
            <Typography
              component={"h3"}
              variant={"h3"}
              className={classes.title}
            >
              Path Content
            </Typography>
            <CoursesList courses={data?.data.paths.courses} />
          </Box>
          <Box className={classes.right}>
            <Typography
              component={"h3"}
              variant={"h3"}
              className={classes.title}
            >
              Who this path is for
            </Typography>
            {data?.data.paths.shortDescription ? (
              data.data.paths.shortDescription.toString().includes("</") ? (
                <HTMLComponent html={data.data.paths.shortDescription} />
              ) : (
                <Typography className={classes.text}>
                  {data.data.paths.shortDescription}
                </Typography>
              )
            ) : null}
          </Box>
        </Box>
      </Container>
    </PlatformLayout>
  );
}
