import { Box, Typography } from "@mui/material";
import { AccentButton } from "../../../../../components/MUI/buttons/button";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../../../../context/AuthContext";
import { useContext } from "react";
import useMixpanel from "../../../../../hooks/useMixpanel";
import { mixpanelLecture } from "../../../../../constants/mixpanel";
import classes from "./index.module.scss";


export default function GetFullAccess({ isFree, price, discountedPrice, courseId, title }) {
  const { pathname } = useLocation();
  const { auth, setIsPayment } = useContext(AuthContext);
  const { handleEventTrack } = useMixpanel();
  const { signUpLink, buyForButton, buyForLink } = mixpanelLecture;

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.content}>
        <Typography component={"h3"} className={classes.title}>
          🔒 Get full access
        </Typography>
        <Typography className={classes.text}>
          {isFree
            ? "You’re trying to open a lecture that is available only upon authentication on the platform"
            : "You’re trying to open a lecture that is available only after buying the course"}
        </Typography>
      </Box>
      <Box className={classes.actions}>
        {isFree ? (
          <AccentButton
            isBig={false}
            variant={"contained"}
            component={Link}
            to={"/registration"}
            onClick={(e) => {
              handleEventTrack(e, {
                ...signUpLink,
                options: {
                  ...signUpLink.options,
                  course: title,
                  courseId: courseId,
                },
              });
              localStorage.setItem("redirectPath", pathname);
            }}
          >
            Sign Up
          </AccentButton>
        ) : auth ? (
          <AccentButton
            isBig={false}
            variant="contained"
            onClick={(e) => {
              handleEventTrack(e, {
                ...buyForButton,
                options: {
                  ...buyForButton.options,
                  name: `Buy for $${price}`,
                  course: title,
                  courseId: courseId,
                },
              });
              localStorage.setItem("isPayment", true);
              setIsPayment(true);
            }}
          >
            Buy for ${discountedPrice ? discountedPrice : price}
          </AccentButton>
        ) : (
          <AccentButton
            isBig={false}
            variant="contained"
            component={Link}
            to={"/registration"}
            onClick={(e) => {
              handleEventTrack(e, {
                ...buyForLink,
                options: {
                  ...buyForLink.options,
                  name: `Buy for $${price}`,
                  course: title,
                  courseId: courseId,
                },
              });
              localStorage.setItem("redirectPath", pathname);
              localStorage.setItem("isPayment", true);
              setIsPayment(true);
            }}
          >
            Buy for ${discountedPrice ? discountedPrice : price}
          </AccentButton>
        )}
      </Box>
    </Box>
  );
}
