import { useEffect, useState } from "react";

export default function useCourseLecturesCount({ course }) {
  const [courseLecturesCount, setCourseLecturesCount] = useState(0);
  const getLecturesCount = (course) => {
    const lecturesCount = course?.reduce(
      (acc, module) => acc + module.lectures.length,
      0
    );
    setCourseLecturesCount(lecturesCount);
  };
  useEffect(() => {
    course && getLecturesCount(course?.modules);
  }, [course]);
  return { courseLecturesCount };
}
