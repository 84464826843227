import { useEffect, useRef, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Intersection } from "@splidejs/splide-extension-intersection";
import "@splidejs/react-splide/css";
import { Box } from "@mui/material";
import classes from "../index.module.scss";
import Accordion from "../Accordion/Accordion";

export default function DeskSlider({ data }) {
  const splideRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const splide = splideRef.current.splide;

    const handleWheel = (e) => {
      if (activeIndex === data.length - 1) {
        splide.options = { wheel: false };
      } else {
        splide.options = { wheel: true };
      }
    };

    splide.on("wheel", handleWheel);

    return () => {
      splide.off("wheel", handleWheel);
    };
  }, [activeIndex, data.length]);

  const handleActiveIndexChange = (index) => {
    setActiveIndex(index);
    if (splideRef.current && splideRef.current.splide) {
      splideRef.current.splide.go(index);
    }
  };

  return (
    <Box className={classes.deskSlider}>
      <Splide
        ref={splideRef}
        extensions={{ Intersection }}
        options={{
          height: "500px",
          direction: "ttb",
          loop: false,
          wheel: activeIndex < data.length - 1,
          releaseWheel: true,
          arrows: false,
          pagination: false,
          rewind: false,
          perPage: 1,
          perMove: 1,
          omitEnd: true,
          drag: "free",
          wheelSleep: 500,
          speed: 1, // super fast speed so that we dont see swipe effect but content change
        }}
        aria-label="Insiders Edge"
        onMoved={(splide, newIndex) => setActiveIndex(newIndex)}
      >
        {data.map((item, index) => (
          <SplideSlide className={classes.slide} key={index}>
            <Accordion
              data={data}
              expanded={activeIndex}
              onActiveIndexChange={handleActiveIndexChange}
            />
          </SplideSlide>
        ))}
      </Splide>
    </Box>
  );
}
