import { Box, Typography } from "@mui/material";
import { ReactComponent as Avatar } from "../../../../assets/icons/avatar.svg";
import classes from "./index.module.scss";
import { Rating } from "../../../../components/MUI";

export default function InstructorCard({ instructor, isAuth }) {
  const feedbacks =
    instructor?.alumniFeedbacks && instructor.alumniFeedbacks.length;

  const rating = feedbacks
    ? instructor.alumniFeedbacks.reduce(
        (acc, feedback) => acc + Number(feedback.rating),
        0
      )
    : false;

  const options = feedbacks && {
    rating: rating / instructor.alumniFeedbacks.length,
    votes: instructor.alumniFeedbacks.length,
    stars: rating / instructor.alumniFeedbacks.length,
    readOnly: true,
  };

  return (
    <Box className={classes.card}>
      <Box className={classes.image}>
        {instructor.photoUrl ? (
          <img
            src={instructor.photoUrl}
            alt={(
              (instructor.firstName || "") +
              " " +
              (instructor.lastName || "")
            ).trim()}
            width={145}
          />
        ) : (
          <Avatar />
        )}
      </Box>
      <Box className={classes.header}>
        <Typography component={"h1"} variant={"h1"} className={classes.title}>
          <span>{instructor.firstName || ""}</span>
          <span>
            {instructor.lastName || ""}
            {instructor.linkedinLink ? (
              <Box
                component={"a"}
                target={"_blank"}
                href={instructor.linkedinLink}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <g clipPath="url(#clip0_6384_4253)">
                    <path
                      d="M29 0.750116H3C2.41008 0.744112 1.84187 0.972377 1.42005 1.38482C0.998227 1.79727 0.757253 2.36021 0.75 2.95012V29.0551C0.758564 29.6442 1.00012 30.2058 1.42179 30.6172C1.84347 31.0285 2.41093 31.2561 3 31.2501H29C29.59 31.2548 30.1578 31.0258 30.5794 30.6131C31.001 30.2003 31.2421 29.6375 31.25 29.0476V2.94262C31.2395 2.35444 30.9972 1.79418 30.5759 1.38363C30.1546 0.973077 29.5883 0.74541 29 0.750116Z"
                      fill="#0076B2"
                    />
                    <path
                      d="M5.2643 12.1836H9.7918V26.7511H5.2643V12.1836ZM7.5293 4.93359C8.04858 4.93359 8.5562 5.08761 8.98793 5.37616C9.41966 5.66471 9.75611 6.07483 9.95472 6.55463C10.1533 7.03443 10.2052 7.56236 10.1037 8.07163C10.0022 8.58089 9.75193 9.04862 9.38457 9.41563C9.0172 9.78265 8.54924 10.0325 8.03988 10.1335C7.53051 10.2345 7.00263 10.1821 6.52302 9.98306C6.04341 9.784 5.63361 9.44715 5.34547 9.01515C5.05734 8.58314 4.9038 8.07538 4.9043 7.55609C4.90496 6.86033 5.18181 6.1933 5.67403 5.70155C6.16624 5.20981 6.83354 4.93359 7.5293 4.93359ZM12.6318 12.1836H16.9718V14.1836H17.0318C17.6368 13.0386 19.1118 11.8311 21.3143 11.8311C25.8993 11.8211 26.7493 14.8386 26.7493 18.7511V26.7511H22.2218V19.6636C22.2218 17.9761 22.1918 15.8036 19.8693 15.8036C17.5468 15.8036 17.1518 17.6436 17.1518 19.5536V26.7511H12.6318V12.1836Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6384_4253">
                      <rect width="32" height="32" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Box>
            ) : null}
          </span>
        </Typography>
        {feedbacks ? (
          <Rating options={options} aria-label="Instructor rating" />
        ) : null}
      </Box>

      {instructor.position ? (
        <Box className={classes.description}>
          <Typography className={classes.specialization}>
            {instructor.position}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
}
