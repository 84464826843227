import * as React from "react";
import { Modal as MUIModal } from "@mui/material";

export default function Modal({ children, open, handleClose }) {
  return (
    <MUIModal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <>{children}</>
    </MUIModal>
  );
}
