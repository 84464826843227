import { useEffect, useState } from "react";

export default function useAccordion(defaultExpanded = false) {
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    defaultExpanded && setExpanded(defaultExpanded);
  }, [defaultExpanded]);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return { expanded, handleChange };
}
