import { Box } from "@mui/material";
import SectionTitle from "../SectionTitle";
import Section from "../Section";
import NotFound from "../../../../../components/Common/NotFound";
import { useParams } from "react-router-dom";
import { updateStore, useStore } from "../../store";
import { useCallback, useEffect, useRef } from "react";
import { WhiteButton } from "../../../../../components/MUI/buttons/button";
import Certificate from "./Certificate";
import classes from "./index.module.scss";

export default function Certificates({
  certificates,
  windowWidth,
  currentUserId,
}) {
  const { id } = useParams();

  const step = 3;
  const defaultValue = 6;
  const key = `ratingCertificatesPagination_user-${id || currentUserId}`;
  const itemsCount = useStore((state) => state[key]);

  const dCertificates =
    certificates && itemsCount ? certificates.slice(0, itemsCount) : [];

  const observer = useRef();

  const lastCertificateElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (
            entries[0].isIntersecting &&
            itemsCount < certificates.length &&
            windowWidth <= 768
          ) {
            updateStore({ key, itemsCount, defaultValue, step });
          }
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0.5,
        }
      );
      if (node) {
        observer.current.observe(node);
      }
    },
    [itemsCount, certificates.length, windowWidth, key]
  );

  useEffect(() => {
    certificates &&
      certificates.length &&
      !itemsCount &&
      updateStore({ key, itemsCount, defaultValue, step });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certificates]);
  
  return (
    <Section>
      <SectionTitle title={"Certificates"} />
      {certificates && certificates.length ? (
        <Box className={classes.desktop}>
          <Box className={classes.desktop__content}>
            {dCertificates.map((certificate, index, self) => {
              if (
                self.length === index + 1 &&
                windowWidth <= 768 &&
                self.length < certificates.length
              ) {
                return (
                  <Box
                    ref={lastCertificateElementRef}
                    key={index}
                    sx={{ flex: "0 0 18.75rem" }}
                  >
                    <Certificate
                      certificate={certificate}
                      currentUserId={currentUserId}
                    />
                  </Box>
                );
              } else {
                return (
                  <Box key={index} sx={{ flex: "0 0 18.75rem" }}>
                    <Certificate
                      certificate={certificate}
                      currentUserId={currentUserId}
                    />
                  </Box>
                );
              }
            })}
          </Box>
          {dCertificates &&
          dCertificates.length &&
          itemsCount < certificates.length &&
          windowWidth > 768 ? (
            <WhiteButton
              isBig={false}
              variant="outlined"
              sx={{ width: "max-content", margin: "1.5rem auto 0" }}
              onClick={() =>
                updateStore({
                  key,
                  itemsCount,
                  defaultValue,
                  step,
                })
              }
            >
              Load more
            </WhiteButton>
          ) : null}
        </Box>
      ) : (
        <NotFound text={"Sorry, we couldn't find certificates."} />
      )}
    </Section>
  );
}
