import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

import "swiper/css";

import classes from "./index.module.scss";

SwiperCore.use([Autoplay]);

export default function Carousel({ sliderRef, playlist, showModal }) {
  return (
    <Swiper
      slidesPerView={"auto"}
      spaceBetween={12}
      ref={sliderRef}
      className={classes.swiper}
      loop={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
    >
      {playlist.map((video) => {
        return (
          <SwiperSlide
            className={classes.slide}
            key={video.id}
            onClick={() => {
              showModal({
                title: video.title,
                url: `https://youtu.be/${video.videoId}`,
              });
            }}
          >
            <img
              className={classes.image}
              src={video.thumbnail.url}
              width={video.thumbnail.width}
              height={video.thumbnail.height}
              alt={video.title}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
