import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Input = styled(TextField)(({ theme }) => ({
  "&.MuiFormControl-root": { margin: "1.15rem 0 0" },
  "& legend": { display: "none" },
  "& .MuiFormLabel-root": {
    color: "var(--on-surface-b-070)",
    fontFamily: "var(--proxima)",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    transform: "translate(0.5rem, -1.25rem) scale(1)",
  },
  "& .Mui-focused": {
    "&.MuiFormLabel-root": {
      color: "var(--on-surface-b-070)",
    },
  },
  "& .Mui-error": {
    "&.MuiFormLabel-root": {
      color: "var(--on-surface-b-070)",
    },
    "&.MuiFormHelperText-root": {
      color: "var(--colors-b-100)",
      fontSize: "0.75rem",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "120%",
      fontFamily: "var(--proxima)",
      marginLeft: "0.5rem",
    },
  },

  "&.Mui-disabled": {
    "&.MuiFormLabel-root": {
      color: "var(--on-surface-b-070)",
    },
  },

  "& .MuiInputBase-root": {
    fontFamily: "inherit",
    borderRadius: "0.75rem",
    lineHeight: "1.2",
    height: "3rem",

    "& fieldset": {
      border: "2px solid var(--colors-a-020)",
      top: 0,
    },
    "&:hover fieldset": {
      borderColor: "var(--colors-a-100)",
    },

    "&.Mui-error": {
      "& fieldset": {
        borderColor: "var(--colors-b-020)",
      },
    },

    "&.Mui-focused": {
      "& fieldset": {
        borderColor: "var(--colors-a-100)",
      },
    },
    "&.Mui-disabled": {
      "& fieldset": {
        borderColor: "var(--colors-a-020)",
      },
    },

    "& input": {
      padding: "0.75rem 0.875rem !important",
      background: "var(--on-surface-a-100)",
      color: "var(--on-surface-b-100)",
      fontFamily: "var(--proxima)",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",

      "&::placeholder": {
        color: "var(--on-surface-b-040)",
        fontFamily: "var(--proxima)",
        fontSize: "1rem",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        opacity: 1,
      },

      "&:-webkit-autofill": {
        WebkitBoxShadow: "0 0 0 30px white inset !important",
      },
      "&:-webkit-autofill:hover": {
        WebkitBoxShadow: "0 0 0 30px white inset !important",
      },
      "&:-webkit-autofill:focus": {
        WebkitBoxShadow: "0 0 0 30px white inset !important",
      },
      "&:-webkit-autofill:active": {
        WebkitBoxShadow: "0 0 0 30px white inset !important",
      },
    },
  },
}));
