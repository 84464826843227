import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import classes from "./index.module.scss";
import Courses from "./Courses";
import { formatTimeMilliseconds } from "../../../../helpers/formatTime";

export default function PathCard({ path }) {
  const isPaid = path?.courses?.some((course) => course.isPaid === true);
  return (
    <Box component={Link} className={classes.card} to={`/paths/${path.id}`}>
      <Box className={classes.image}>
        <img src={path.promoImageUrl} alt={path.title} />
      </Box>
      <Typography component={"h3"} className={classes.title}>
        <span>{path.title}</span>
      </Typography>
      <Typography className={classes.duration}>
        {isPaid ? `Finished ${path?.completeCoursesCount} / ` : null}
        {path?.courses.length} courses • {formatTimeMilliseconds(path.duration)}
      </Typography>

      <Courses courses={path?.courses} />
    </Box>
  );
}
