import {  Step, StepLabel, Stepper as MuiStepper } from "@mui/material";

import classes from "./index.module.scss";

export default function Stepper({ steps, activeStep }) {
  return (
    <MuiStepper
      className={classes.stepper}
      activeStep={activeStep}
      connector={null}
    >
      {steps.map(({ label }) => (
        <Step key={label}>
          <StepLabel StepIconComponent={null}>{label}</StepLabel>
        </Step>
      ))}
    </MuiStepper>
  );
}
