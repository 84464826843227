import { Box, Typography } from "@mui/material";
import { ReactComponent as Avatar } from "../../../../assets/icons/avatar.svg";

import classes from "./index.module.scss";

export default function Instructor({ image, fullName, position }) {
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.image}>
        {image ? <img src={image} alt={fullName} width={44} /> : <Avatar />}
      </Box>
      <Box className={classes.info}>
        <Typography className={classes.name}>
          {fullName || "Instructor"}
        </Typography>
        {position ? (
          <Typography className={classes.position}>{position}</Typography>
        ) : null}
      </Box>
    </Box>
  );
}
