import { BurgerButton as Button } from "../../../../MUI/buttons/button";
import classes from "./index.module.scss";

export default function BurgerButton({ handleDrawerToggle, children }) {
  return (
    <Button
      variant={"contained"}
      aria-label="open drawer"
      onClick={handleDrawerToggle}
      className={classes.burger}
    >
      {children}
    </Button>
  );
}
