import { useQuery } from "@tanstack/react-query";
import { Box, Container, Typography } from "@mui/material";
import classes from "./index.module.scss";
import { apiCmsData } from "../../../../instances/axiosInstances";

export default function YourFuture({ data }) {
  const itemsRes = useQuery(["homeYourFuture"], () =>
    apiCmsData.get(
      `/home-your-future${process.env.REACT_APP_WP_HOME_QUERY_PARAMS}&order=asc`
    )
  );

  const items = itemsRes.isSuccess ? itemsRes?.data?.data : [];

  if (!data || !items.length) return null;

  if (data?.custom_fields?.display_section[0] === "off") return null;

  return (
    <Box className={classes.section} component="section">
      <Container maxWidth="lg">
        <Typography className={classes.title} component="h2">
          {data?.title?.rendered}
        </Typography>
        <Box className={classes.list} component="ul">
          {items.map((item) => (
            <Box className={classes.listItem} key={item.id} component="li">
              <Typography className={classes.itemTitle} component="h3">
                {item?.title?.rendered}
              </Typography>
              <Box
                className={classes.itemDescription}
                dangerouslySetInnerHTML={{
                  __html: item?.content?.rendered,
                }}
              />
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
}
