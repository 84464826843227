import { useEffect, useState } from "react";

export default function useExpandedIndex({ course, lectureId }) {
  const [expandedIndex, setExpandedIndex] = useState(false);
  const getExpandedIndex = (course) => {
    const module = course?.filter(
      (module) => module.lectures.some((lecture) => lecture.id === +lectureId),
      0
    );
    setExpandedIndex(module[0]?.id || false);
  };
  useEffect(() => {
    course && getExpandedIndex(course?.data.courses.modules);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course, lectureId]);
  return { expandedIndex };
}
