import { Box, Typography } from "@mui/material";
import { PlayButton } from "../../../../../components/MUI/buttons/button";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import classes from "./index.module.scss";

export default function Slide({
  photoUrl,
  name,
  linkedinLink,
  showModal,
  title,
  url,
  description,
}) {
  return (
    <Box className={classes.card}>
      <Box
        className={classes.image}
        
      >
        <img src={photoUrl} alt={title} />
        <PlayButton
            aria-label="play"
            onClick={() => {
                showModal({
                  title,
                  url,
                });
              }}
          >
            <PlayArrowIcon />
          </PlayButton>
      </Box>
      {name || linkedinLink ? (
        <Typography component={"h3"} variant={"h3"} className={classes.name}>
          <span>{name}</span>
          {linkedinLink ? (
            <a href={linkedinLink} target="_blank" rel="noopener noreferrer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <g clipPath="url(#clip0_146_38)">
                  <path
                    d="M25.375 0.656351H2.625C2.10882 0.651098 1.61163 0.85083 1.24254 1.21172C0.873449 1.57261 0.662596 2.06518 0.65625 2.58135V25.4232C0.663744 25.9386 0.875101 26.4301 1.24407 26.79C1.61304 27.15 2.10957 27.3491 2.625 27.3439H25.375C25.8912 27.3479 26.3881 27.1475 26.757 26.7864C27.1259 26.4253 27.3368 25.9328 27.3438 25.4167V2.57479C27.3345 2.06013 27.1226 1.56991 26.7539 1.21068C26.3853 0.851442 25.8897 0.652234 25.375 0.656351Z"
                    fill="#0076B2"
                  />
                  <path
                    d="M4.60626 10.6606H8.56782V23.4072H4.60626V10.6606ZM6.58814 4.31689C7.04251 4.31689 7.48667 4.45166 7.86444 4.70414C8.2422 4.95662 8.5366 5.31547 8.71038 5.7353C8.88416 6.15513 8.92952 6.61706 8.84072 7.06267C8.75191 7.50828 8.53294 7.91754 8.2115 8.23868C7.89005 8.55981 7.48059 8.7784 7.03489 8.86678C6.5892 8.95516 6.1273 8.90936 5.70764 8.73518C5.28798 8.561 4.92941 8.26626 4.67729 7.88825C4.42517 7.51025 4.29083 7.06595 4.29126 6.61158C4.29184 6.00279 4.53409 5.41913 4.96477 4.98886C5.39546 4.55859 5.97935 4.31689 6.58814 4.31689ZM11.0528 10.6606H14.8503V12.4106H14.9028C15.4322 11.4088 16.7228 10.3522 18.65 10.3522C22.6619 10.3435 23.4056 12.9838 23.4056 16.4072V23.4072H19.4441V17.2056C19.4441 15.7291 19.4178 13.8281 17.3856 13.8281C15.3534 13.8281 15.0078 15.4381 15.0078 17.1094V23.4072H11.0528V10.6606Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_146_38">
                    <rect width="28" height="28" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          ) : null}
        </Typography>
      ) : null}
      {description ? (
        <Typography className={classes.description}>{description}</Typography>
      ) : null}
    </Box>
  );
}
