import { Box, Typography } from "@mui/material";
import ModalHeader from "./ModalHeader";
import classes from "./index.module.scss";
import { RedButton, WhiteButton } from "../../../components/MUI/buttons/button";
import errorToast from "../../../components/toasts/errorToast";
import { useMutation } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import successToast from "../../../components/toasts/successToast";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";

export default function DeleteProfileModal({ handleClose }) {
  const { removeAuthUser } = useContext(AuthContext);
  const { mutate, isLoading } = useMutation(() => api.delete("/users"), {
    onSuccess: () => {
      handleClose();
      removeAuthUser();
      successToast("Your account has been successfully deleted.");
    },
    onError: () => {
      errorToast("Something went wrong. Try again later.", {
        autoClose: false,
        hideProgressBar: true,
      });
    },
  });
  return (
    <Box className={classes.wrapper}>
      <ModalHeader title={"Delete account"} handleClose={handleClose} />
      <Typography className={classes.text}>
        Are you sure you want to delete all your personal data? This action
        can’t be reversed.
      </Typography>
      <Box className={classes.actions}>
        <WhiteButton
          onClick={handleClose}
          isBig={false}
          variant="contained"
          disabled={isLoading}
        >
          Cancel
        </WhiteButton>
        <RedButton
          onClick={mutate}
          isBig={false}
          variant="contained"
          disabled={isLoading}
        >
          Yes, delete
        </RedButton>
      </Box>
    </Box>
  );
}
