import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function useModal(ref) {
  const { pathname } = useLocation();

  const body = document.body;

  const handleOpen = () => {
    ref?.current && ref.current.showModal();
    body.style.overflow = "hidden";
  };
  const handleClose = useCallback(() => {
    ref?.current && ref.current.close();
    body.style.overflow = "auto";
  }, [body.style, ref]);

  const onBackdropClick = (e) => {
    const dialogDimensions = ref?.current.getBoundingClientRect();
    if (
      e.clientX < dialogDimensions.left ||
      e.clientX > dialogDimensions.right ||
      e.clientY < dialogDimensions.top ||
      e.clientY > dialogDimensions.bottom
    ) {
      ref?.current && ref.current.close();
      body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    handleClose();

    return () => handleClose();
  }, [handleClose, pathname]);

  return { onBackdropClick, handleOpen, handleClose };
}
