import { Box } from "@mui/material";
import classes from "./index.module.scss";

export default function ClosedLectionImage({ src }) {
  return (
    <Box className={classes.image}>
      <img src={src} alt="Preparation Tasks" width={860} />
      <Box className={classes.icon}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
        >
          <path
            d="M13.9987 51.3359C12.7154 51.3359 11.6164 50.8785 10.7017 49.9639C9.78703 49.0492 9.33048 47.951 9.33204 46.6692V23.3359C9.33204 22.0525 9.78937 20.9535 10.704 20.0389C11.6187 19.1242 12.7169 18.6677 13.9987 18.6692H16.332V14.0025C16.332 10.7748 17.4699 8.02299 19.7457 5.74721C22.0215 3.47143 24.7725 2.33432 27.9987 2.33588C31.2265 2.33588 33.9783 3.47377 36.254 5.74954C38.5298 8.02532 39.6669 10.7763 39.6654 14.0025V18.6692H41.9987C43.282 18.6692 44.381 19.1265 45.2957 20.0412C46.2104 20.9559 46.6669 22.0541 46.6654 23.3359V46.6692C46.6654 47.9525 46.208 49.0515 45.2934 49.9662C44.3787 50.8809 43.2805 51.3374 41.9987 51.3359H13.9987ZM27.9987 39.6692C29.282 39.6692 30.381 39.2119 31.2957 38.2972C32.2104 37.3825 32.6669 36.2843 32.6654 35.0025C32.6654 33.7192 32.208 32.6202 31.2934 31.7055C30.3787 30.7909 29.2805 30.3343 27.9987 30.3359C26.7154 30.3359 25.6164 30.7932 24.7017 31.7079C23.787 32.6225 23.3305 33.7208 23.332 35.0025C23.332 36.2859 23.7894 37.3849 24.704 38.2995C25.6187 39.2142 26.7169 39.6708 27.9987 39.6692ZM20.9987 18.6692H34.9987V14.0025C34.9987 12.0581 34.3181 10.4053 32.957 9.04421C31.5959 7.6831 29.9431 7.00254 27.9987 7.00254C26.0543 7.00254 24.4015 7.6831 23.0404 9.04421C21.6793 10.4053 20.9987 12.0581 20.9987 14.0025V18.6692Z"
            fill="#3D40D5"
            fillOpacity="0.8"
          />
        </svg>
      </Box>
    </Box>
  );
}
