import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/system";
import { RedirectContext } from "../../../../../context/RedirectContext";

import classes from "./index.module.scss";

export default function Logo({ handleDrawerToggle, children }) {
  const { removeCurrentCourse } = useContext(RedirectContext);

  return (
    <Box
      component={Link}
      to="/"
      onClick={() => {
        handleDrawerToggle && handleDrawerToggle();
        removeCurrentCourse();
      }}

      className={classes.logo}
    >
      {children}
    </Box>
  );
}
