import { useQuery } from "@tanstack/react-query";
import axios from "axios";

async function fetchWebinarData({ slug }) {
  const response = await axios.get(
    `${process.env.REACT_APP_WP_PATH}webinars?slug=${slug}&_fields=id,custom_fields,title`
  );
  const data = { webinar: response.data[0] };
  return data;
}

export function useWebinarData({ slug }) {
  return useQuery({
    queryKey: ["webinar", slug],
    queryFn: () => fetchWebinarData({ slug }),
    staleTime: Infinity,
    enabled: !!slug,
  });
}
