import React from "react";
import { Box } from "@mui/material";
import classes from "./index.module.scss";

export default function Poster({poster}) {
  return (
    <Box className={classes.wrapper}>
      <img src={poster} alt="Poster" width={860}/>
    </Box>
  );
}
