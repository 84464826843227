import { Box } from "@mui/material";
import classes from "./index.module.scss";
import CourseCard from "../../../components/MUI/cards/CourseCard";
import NotFound from "../../../components/Common/NotFound";

export default function CoursesList({ courses, isLoading }) {
  return (
    <Box className={classes.courses}>
      {courses && !isLoading
        ? courses.map((course) => (
            <CourseCard key={course.id} course={course} />
          ))
        : null}
      {(!courses || !courses.length) && !isLoading ? (
        <NotFound text={"Sorry, we couldn't find courses."} />
      ) : null}
    </Box>
  );
}
