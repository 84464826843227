import React from "react";
import classes from "./index.module.scss";
import bgImg from "./images/bg.png";
import avatarIcon from "./images/user.png";

export default function ImageClip({ imgUrl, variant }) {
  return (
    <>
      <svg width="0" height="0" className={classes.svgShape}>
        <defs>
          <clipPath id="svgPathSection" clipPathUnits="objectBoundingBox">
            <path d="M0.003,0.19 C0.003,0.16 0.049,0.14 0.071,0.14 H0.568 C0.595,0.14 0.615,0.114 0.615,0.084 V0.079 C0.615,0.028 0.634,0 0.656,0 H0.802 C0.828,0 0.842,0.051 0.842,0.079 V0.084 C0.842,0.114 0.862,0.14 0.889,0.14 H0.925 C0.967,0.14 0.968,0.162 0.968,0.19 V0.818 C0.968,0.846 0.948,0.869 0.926,0.869 H0.613 C0.587,0.869 0.567,0.892 0.567,0.922 V0.929 C0.567,0.957 0.548,0.979 0.526,0.979 H0.071 C0.048,0.979 0.003,0.957 0.003,0.929 V0.19 Z" />
          </clipPath>
          <clipPath id="svgPathUser" clipPathUnits="objectBoundingBox">
            <path d="M0.08,0.33 C0.08,0.22 0.18,0.12 0.29,0.12 H0.87 C0.92,0.12 0.95,0.11 0.95,0.07 V0.05 C0.95,0.01 0.97,0 0.98,0 H1 C1,0 1,0 1,0.05 V0.07 C1,0.11 1.03,0.12 1.07,0.12 H1.15 C1.24,0.12 1.26,0.14 1.26,0.18 V0.82 C1.26,0.87 1.24,0.89 1.15,0.89 H0.64 C0.61,0.89 0.6,0.9 0.6,0.92 V0.93 C0.6,0.95 0.59,0.97 0.58,0.97 H0.29 C0.18,0.97 0.08,0.88 0.08,0.77 V0.33 Z" />
          </clipPath>
        </defs>
      </svg>
      {variant === "section" ? (
        <div className={classes.sectionCard}>
          <img
            className={`${classes.bgImg} ${classes.sectionImg}`}
            src={bgImg}
          />
          <div className={`${classes.imgContainer} ${classes.sectionVar}`}>
            <div
              className={classes.maskedImage}
              style={{ backgroundImage: `url(${imgUrl})` }}
            ></div>
          </div>
        </div>
      ) : null}
      {variant === "user" ? (
        <div className={classes.userCard}>
          <img className={`${classes.bgImg} ${classes.userImg}`} src={bgImg} />
          <div className={`${classes.imgContainer} ${classes.userVar}`}>
            <div
              className={classes.maskedImage}
              style={{
                backgroundImage: `url(${imgUrl || avatarIcon})`,
                backgroundColor: "#ECECFB",
                backgroundSize: !imgUrl ? "100px 100px" : "",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
        </div>
      ) : null}
    </>
  );
}
