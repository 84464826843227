import { useQuery } from "@tanstack/react-query";
import { Box, Container, Typography } from "@mui/material";
import Slider from "./Slider/Slider";
import classes from "./index.module.scss";
import { apiCmsData } from "../../../../instances/axiosInstances";

export default function TechTrajectory({ data }) {
  const itemsRes = useQuery(["homeTrajectory"], () =>
    apiCmsData.get(
      `/home-trajectory${process.env.REACT_APP_WP_HOME_QUERY_PARAMS}&order=asc`
    )
  );

  const items = itemsRes.isSuccess ? itemsRes?.data?.data : [];

  if (!data || !items.length) return null;

  const { title, img_url, custom_fields } = data || {};

  if (custom_fields?.display_section[0] === "off") return null;

  return (
    <Box className={classes.section} component="section">
      <Container maxWidth="lg">
        <Box className={classes.container}>
          <Box className={classes.box}>
            <Typography component="h2" className={classes.title}>
              {title?.rendered}
            </Typography>
            <Box className={classes.imgContainer}>
              <img src={img_url?.large} alt={title?.rendered} />
            </Box>
          </Box>
          <Box className={classes.slider}>
            <Slider data={items} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
