import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";
import { useEffect, useState } from "react";

export default function Anchors({ post }) {
  const [sections, setSections] = useState(null);

  useEffect(() => {
    const sections = document.body.querySelectorAll("h2.wp-block-heading");
    sections &&
      sections.length &&
      sections.forEach((section, index) => {
        section.id = `anchor_${index}`;
      });

    sections && sections.length && setSections(sections);
  }, [post]);

  if (!post || !sections) return null;

  return (
    <Box className={classes.wrapper}>
      <Typography component={"h3"} variant={"h3"} className={classes.title}>
        Contents
      </Typography>
      <Box component={"ul"} className={classes.list}>
        {[...sections].map((section, index) => {
          return (
            <Box component={"li"} key={index}>
              <a
                href={`#anchor_${index}`}
                onClick={(event) => {
                  event.preventDefault();
                  const targetElement = document.getElementById(
                    `anchor_${index}`
                  );
                  if (targetElement) {
                    targetElement.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              >
                {section.innerText}
              </a>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
