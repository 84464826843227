import { useQuery } from "@tanstack/react-query";
import axios from "axios";

async function fetchEvents({
  page,
  perPage,
  search = "",
  tags = [],
  category = "",
}) {
  const queryParams = new URLSearchParams();

  if (search) {
    queryParams.set("search", search);
    queryParams.set("search_fields", "title");
  }

  if (tags && tags.length > 0) {
    queryParams.set("tags", tags.join(","));
  }

  if (category) {
    queryParams.set("categories", category);
  }

  queryParams.set("page", page || 1);
  queryParams.set("per_page", perPage);
  queryParams.set(
    "_fields",
    "id,date,title,excerpt,img_url,event_tags,featured_media,tags,slug,categories,custom_fields"
  );

  const response = await axios.get(
    `${process.env.REACT_APP_WP_PATH}events?${queryParams.toString()}`
  );
  const totalEvents = response.headers["x-wp-total"];
  const totalPages = response.headers["x-wp-totalpages"];
  const data = { events: response.data, totalEvents, totalPages };
  return data;
}

export function useEvents({ page, perPage, tags, search, category }) {
  return useQuery({
    queryKey: ["events", page, Number(perPage), tags, search, category],
    queryFn: () => fetchEvents({ page, perPage, tags, search, category }),
    keepPreviousData: true,
    staleTime: Infinity,
  });
}
