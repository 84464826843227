import { Typography } from "@mui/material";
import classes from "./index.module.scss";
import HTMLComponent from "../../../../components/Common/HTMLComponent";

export default function Content({ answer }) {
  return answer.toString().includes("</") ? (
    <HTMLComponent html={answer} />
  ) : (
    <Typography className={classes.text}>{answer}</Typography>
  );
}
