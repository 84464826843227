import { Accordion, Summary, Details } from "../Accordion/TasksAccordion";
import useAccordion from "../../../../hooks/useAccordion";
import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";
import HTMLComponent from "../../../../components/Common/HTMLComponent";

export default function AdditionalResources({ resources, isPaid }) {
  const { expanded, handleChange } = useAccordion(false);

  if (!resources || !resources.length) return null;

  return (
    <Box className={classes.resources}>
      <Accordion expanded={expanded} handleChange={handleChange} index={0}>
        <Summary index={0}>Additional Resources</Summary>
        <Details>
          {resources.map((resource) => {
            const isOpen = resource.isFree || isPaid;
            return (
              <Box key={resource.id} className={classes.resources__item}>
                {resource.title ? (
                  <Typography
                    sx={{
                      color: "var(--on-surface-b-100)",
                      fontSize: "0.875rem",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "normal",
                    }}
                  >
                    {resource.title}
                  </Typography>
                ) : null}
                {resource.fileUrl ? (
                  <Typography
                    component={"a"}
                    download
                    href={isOpen ? resource.fileUrl : ""}
                    sx={{
                      pointerEvents: isOpen ? "auto" : "none",
                      cursor: isOpen ? "pointer" : "default",
                      color: isOpen
                        ? "var(--colors-a-100)"
                        : "var(--colors-a-040)",
                      fontSize: "0.875rem",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "normal",
                      display: "flex",
                      gap: "0.5rem",
                      textDecoration: "none",
                      alignItems: "center",
                    }}
                  >
                    {isOpen ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                      >
                        <path
                          d="M7.99998 8.5V12.5M7.99998 12.5L9.99998 11.1667M7.99998 12.5L5.99998 11.1667M8.66665 2.5H5.46665C4.71998 2.5 4.34665 2.5 4.06131 2.64533C3.81041 2.77313 3.60644 2.9771 3.47865 3.228C3.33331 3.51333 3.33331 3.88667 3.33331 4.63333V12.3667C3.33331 13.1133 3.33331 13.4867 3.47865 13.772C3.60648 14.0229 3.81044 14.2268 4.06131 14.3547C4.34598 14.5 4.71931 14.5 5.46465 14.5H10.5353C11.2806 14.5 11.6533 14.5 11.938 14.3547C12.1893 14.2267 12.3933 14.0227 12.5213 13.772C12.6666 13.4867 12.6666 13.1147 12.6666 12.3693V6.5M8.66665 2.5C8.85731 2.502 8.97731 2.50933 9.09265 2.53667C9.22865 2.57 9.35865 2.62333 9.47798 2.69667C9.61265 2.77933 9.72798 2.89467 9.95798 3.12533L12.042 5.20867C12.2726 5.43933 12.3873 5.554 12.47 5.68867C12.5433 5.80867 12.5966 5.938 12.63 6.074C12.6566 6.18933 12.6646 6.31 12.6666 6.5M8.66665 2.5V4.36667C8.66665 5.11333 8.66665 5.48667 8.81198 5.772C8.93981 6.02287 9.14377 6.22684 9.39465 6.35467C9.67931 6.5 10.0526 6.5 10.798 6.5H12.6666"
                          stroke="#3D40D5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                      >
                        <g opacity="0.3">
                          <path
                            d="M3.99966 15.1674C3.63299 15.1674 3.31899 15.0367 3.05766 14.7754C2.79632 14.514 2.66588 14.2002 2.66632 13.834V7.16736C2.66632 6.80069 2.79699 6.48669 3.05832 6.22536C3.31966 5.96403 3.63343 5.83358 3.99966 5.83402H4.66632V4.50069C4.66632 3.57847 4.99143 2.79225 5.64166 2.14203C6.29188 1.4918 7.07788 1.16691 7.99966 1.16736C8.92188 1.16736 9.7081 1.49247 10.3583 2.14269C11.0085 2.79291 11.3334 3.57891 11.333 4.50069V5.83402H11.9997C12.3663 5.83402 12.6803 5.96469 12.9417 6.22603C13.203 6.48736 13.3334 6.80114 13.333 7.16736V13.834C13.333 14.2007 13.2023 14.5147 12.941 14.776C12.6797 15.0374 12.3659 15.1678 11.9997 15.1674H3.99966ZM7.99966 11.834C8.36632 11.834 8.68032 11.7034 8.94166 11.442C9.20299 11.1807 9.33343 10.8669 9.33299 10.5007C9.33299 10.134 9.20232 9.82002 8.94099 9.55869C8.67966 9.29736 8.36588 9.16691 7.99966 9.16736C7.63299 9.16736 7.31899 9.29803 7.05766 9.55936C6.79632 9.82069 6.66588 10.1345 6.66632 10.5007C6.66632 10.8674 6.79699 11.1814 7.05832 11.4427C7.31966 11.704 7.63343 11.8345 7.99966 11.834ZM5.99966 5.83402H9.99966V4.50069C9.99966 3.94514 9.80521 3.47291 9.41632 3.08403C9.02743 2.69514 8.55521 2.50069 7.99966 2.50069C7.4441 2.50069 6.97188 2.69514 6.58299 3.08403C6.1941 3.47291 5.99966 3.94514 5.99966 4.50069V5.83402Z"
                            fill="#141416"
                            fillOpacity="0.6"
                          />
                        </g>
                      </svg>
                    )}
                    {resource.fileTitle}
                  </Typography>
                ) : null}
                {resource.link ? (
                  <Typography
                    component={"a"}
                    target={"_blank"}
                    href={isOpen ? resource.link : ""}
                    sx={{
                      pointerEvents: isOpen ? "auto" : "none",
                      cursor: isOpen ? "pointer" : "default",
                      color: isOpen
                        ? "var(--colors-a-100)"
                        : "var(--colors-a-040)",
                      fontSize: "0.875rem",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "normal",
                      display: "flex",
                      gap: "0.5rem",
                      textDecoration: "none",
                      alignItems: "center",
                    }}
                  >
                    {isOpen ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                      >
                        <path
                          d="M2.63991 8.08724C2.82658 7.10724 3.74658 6.4339 4.74658 6.4339H6.69991C7.04658 6.4339 7.33324 6.14724 7.33324 5.80057C7.33324 5.4539 7.04658 5.16724 6.69991 5.16724H4.81324C3.07324 5.16724 1.51991 6.44057 1.35325 8.1739C1.30715 8.63672 1.35853 9.10406 1.50405 9.54582C1.64958 9.98757 1.88603 10.3939 2.19818 10.7388C2.51032 11.0836 2.89124 11.3591 3.31638 11.5478C3.74153 11.7364 4.20147 11.8339 4.66658 11.8339H6.69991C7.04658 11.8339 7.33324 11.5472 7.33324 11.2006C7.33324 10.8539 7.04658 10.5672 6.69991 10.5672H4.66658C4.36036 10.5663 4.05815 10.4975 3.78167 10.3659C3.50519 10.2343 3.2613 10.043 3.06753 9.80589C2.87376 9.56877 2.73492 9.29168 2.66098 8.99452C2.58704 8.69736 2.57985 8.38751 2.63991 8.08724ZM5.99991 9.16724H9.99991C10.3666 9.16724 10.6666 8.86724 10.6666 8.50057C10.6666 8.1339 10.3666 7.8339 9.99991 7.8339H5.99991C5.63324 7.8339 5.33324 8.1339 5.33324 8.50057C5.33324 8.86724 5.63324 9.16724 5.99991 9.16724ZM11.1866 5.16724H9.29991C8.95324 5.16724 8.66658 5.4539 8.66658 5.80057C8.66658 6.14724 8.95324 6.4339 9.29991 6.4339H11.2532C12.2532 6.4339 13.1732 7.10724 13.3599 8.08724C13.42 8.38751 13.4128 8.69736 13.3388 8.99452C13.2649 9.29168 13.1261 9.56877 12.9323 9.80589C12.7385 10.043 12.4946 10.2343 12.2182 10.3659C11.9417 10.4975 11.6395 10.5663 11.3332 10.5672H9.29991C8.95324 10.5672 8.66658 10.8539 8.66658 11.2006C8.66658 11.5472 8.95324 11.8339 9.29991 11.8339H11.3332C13.2799 11.8339 14.8399 10.1606 14.6532 8.1739C14.4866 6.44057 12.9266 5.16724 11.1866 5.16724Z"
                          fill="#3D40D5"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                      >
                        <g opacity="0.3">
                          <path
                            d="M3.99966 15.1674C3.63299 15.1674 3.31899 15.0367 3.05766 14.7754C2.79632 14.514 2.66588 14.2002 2.66632 13.834V7.16736C2.66632 6.80069 2.79699 6.48669 3.05832 6.22536C3.31966 5.96403 3.63343 5.83358 3.99966 5.83402H4.66632V4.50069C4.66632 3.57847 4.99143 2.79225 5.64166 2.14203C6.29188 1.4918 7.07788 1.16691 7.99966 1.16736C8.92188 1.16736 9.7081 1.49247 10.3583 2.14269C11.0085 2.79291 11.3334 3.57891 11.333 4.50069V5.83402H11.9997C12.3663 5.83402 12.6803 5.96469 12.9417 6.22603C13.203 6.48736 13.3334 6.80114 13.333 7.16736V13.834C13.333 14.2007 13.2023 14.5147 12.941 14.776C12.6797 15.0374 12.3659 15.1678 11.9997 15.1674H3.99966ZM7.99966 11.834C8.36632 11.834 8.68032 11.7034 8.94166 11.442C9.20299 11.1807 9.33343 10.8669 9.33299 10.5007C9.33299 10.134 9.20232 9.82002 8.94099 9.55869C8.67966 9.29736 8.36588 9.16691 7.99966 9.16736C7.63299 9.16736 7.31899 9.29803 7.05766 9.55936C6.79632 9.82069 6.66588 10.1345 6.66632 10.5007C6.66632 10.8674 6.79699 11.1814 7.05832 11.4427C7.31966 11.704 7.63343 11.8345 7.99966 11.834ZM5.99966 5.83402H9.99966V4.50069C9.99966 3.94514 9.80521 3.47291 9.41632 3.08403C9.02743 2.69514 8.55521 2.50069 7.99966 2.50069C7.4441 2.50069 6.97188 2.69514 6.58299 3.08403C6.1941 3.47291 5.99966 3.94514 5.99966 4.50069V5.83402Z"
                            fill="#141416"
                            fillOpacity="0.6"
                          />
                        </g>
                      </svg>
                    )}
                    {resource.linkTitle}
                  </Typography>
                ) : null}
                {resource.description ? (
                  resource.description.toString().includes("</") ? (
                    <HTMLComponent
                      html={resource.description}
                      textColor={"dark-060"}
                    />
                  ) : (
                    <Typography
                      sx={{
                        color: "var(--on-surface-b-060)",
                        fontSize: "0.875rem",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                      }}
                    >
                      {resource.description}
                    </Typography>
                  )
                ) : null}
              </Box>
            );
          })}
        </Details>
      </Accordion>
    </Box>
  );
}
