import axios from "axios";
import { useQuery } from "@tanstack/react-query";

async function fetchEventsCategories() {
  const response = await axios.get(
    `${process.env.REACT_APP_WP_PATH}categories?_fields=name,id,slug`
  );
  return response.data.filter(
    (category) => category.name === "Webinars" || category.name === "Tutorials"
  );
}

export function useEventsCategories() {
  return useQuery({
    queryKey: ["categories"],
    queryFn: () => fetchEventsCategories(),
    staleTime: Infinity,
  });
}
