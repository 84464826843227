import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";

export default function Header({ title, count }) {
  return (
    <Box className={classes.header}>
      <Typography component="h3" variant="p" className={classes.header__title}>
        {title}
      </Typography>
      <Typography className={classes.header__count}>
        {count} {count === 1 ? "task" : "tasks"}
      </Typography>
    </Box>
  );
}
