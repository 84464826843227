import { Link } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import { ReactComponent as PlayIcon } from "../../../../assets/icons/Play.svg";
import classes from "./index.module.scss";
import {
  TransparentButton,
  WhiteButton,
} from "../../../../components/MUI/buttons/button";
import { useContext } from "react";
import VideoModal from "../../../../components/Common/EmbedVideos/VideoWrapper";
import { ModalContext } from "../../../../context/ModalContext";

export default function Hero({ data }) {
  const { handleModalOpen } = useContext(ModalContext);
  if (!data) return null;

  const { img_url, title, content, custom_fields } = data || {};

  const showModal = ({ url, title }) => {
    handleModalOpen(<VideoModal title={title} url={url} />);
  };

  return (
    <Box className={classes.section} component="section">
      <Container maxWidth="lg">
        <Box className={classes.container}>
          <Box className={classes.infoBox}>
            <Typography component="h1" className={classes.title}>
              {title?.rendered}
            </Typography>
            <Box
              className={classes.textContent}
              dangerouslySetInnerHTML={{
                __html: content?.rendered,
              }}
            />
            <Box className={classes.actions}>
              <WhiteButton
                isBig={false}
                component={Link}
                variant="contained"
                className={classes.button}
                to={custom_fields?.button_link[0]}
              >
                {custom_fields?.button}
              </WhiteButton>
              {custom_fields.video_link ? (
                <TransparentButton
                  isBig={false}
                  variant="contained"
                  className={classes.play}
                  startIcon={<PlayIcon />}
                  onClick={() =>
                    showModal({
                      url: custom_fields.video_link[0],
                      title: custom_fields?.video_link_title[0] || "Intro",
                    })
                  }
                >
                  {custom_fields?.video_link_button_name[0] || "Watch Intro"}
                </TransparentButton>
              ) : null}
            </Box>
          </Box>
          <Box className={classes.imgBox}>
            <img src={img_url?.full} alt="Hero" />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
