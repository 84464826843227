import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Box, Container, Typography } from "@mui/material";
import classes from "./index.module.scss";
import { AccentButton } from "../../../../components/MUI/buttons/button";
import { apiCmsData } from "../../../../instances/axiosInstances";

export default function Expertise({ data }) {
  const itemsRes = useQuery(["homeExpertise"], () =>
    apiCmsData.get(
      `/home-expertise${process.env.REACT_APP_WP_HOME_QUERY_PARAMS}`
    )
  );

  const items = itemsRes.isSuccess ? itemsRes?.data?.data : [];

  if (!data || !items.length) return null;

  const { title, content, custom_fields, display_section } = data || {};

  if (custom_fields?.display_section[0] === "off") return null;

  return (
    <Box className={classes.section} component="section">
      <Container className={classes.container} maxWidth="lg">
        <Typography component="h2" className={classes.title}>
          {title?.rendered}
        </Typography>
        <Box
          className={classes.textContent}
          dangerouslySetInnerHTML={{
            __html: content?.rendered,
          }}
        />
        <Box className={classes.list}>
          {items.length > 0
            ? items.map((item) => (
                <Box key={item.id} className={classes.item} component="article">
                  <img src={item?.img_url?.large} alt="Logo" />
                  <Typography component="h3">
                    {item?.title?.rendered}
                  </Typography>
                </Box>
              ))
            : null}
        </Box>
        <Box className={classes.btnBox}>
          <AccentButton
            isBig={false}
            component={Link}
            variant="contained"
            className={classes.button}
            to={custom_fields?.button_link[0]}
          >
            {custom_fields?.button}
          </AccentButton>
        </Box>
      </Container>
    </Box>
  );
}
