import { Box } from "@mui/material";
import classes from "./index.module.scss";

export default function SectionGrid({ children, className = "", ...rest }) {
  return (
    <Box className={`${classes.grid} ${className}`} {...rest}>
      {children}
    </Box>
  );
}
