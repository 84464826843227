import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";
import HTMLComponent from "../../../../components/Common/HTMLComponent";

export default function ModalContent({ handleClose, rules }) {
  return (
    <Box className={classes.modal}>
      <Box className={classes.modal__header}>
        <Typography component={"h2"} className={classes.modal__title}>
          {rules.title.rendered}
        </Typography>
        <button onClick={handleClose} className={classes.modal__closeBtn}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M6.2252 19.4377L4.5752 17.7877L10.3502 12.0127L4.5752 6.23771L6.2252 4.58771L12.0002 10.3627L17.7752 4.58771L19.4252 6.23771L13.6502 12.0127L19.4252 17.7877L17.7752 19.4377L12.0002 13.6627L6.2252 19.4377Z"
              fill="#3D40D5"
            />
          </svg>
        </button>
      </Box>
      <Box className={classes.modal__rules}>
        <HTMLComponent html={rules.content.rendered} />
      </Box>
    </Box>
  );
}
