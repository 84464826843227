import { createContext, useMemo, useState } from "react";
export const ModalContext = createContext();

export default function ModalContextProvider({ children }) {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const handleModalClose = () => {
    setModalContent("");
    setShowModal(false);
  };

  const handleModalOpen = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const value = useMemo(
    () => ({
      showModal,
      setShowModal,
      modalContent,
      setModalContent,
      handleModalClose,
      handleModalOpen,
    }),
    [modalContent, showModal]
  );

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
}
