import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Modal, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import classes from "./index.module.scss";
import CouponForm from "./CouponForm";
import CouponDetails from "./CouponDetails";
import Paypal from "./Paypal";
import Square from "./Square";

const CustomModal = styled(Modal)(() => ({
  "&.MuiModal-root": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ".MuiBackdrop-root": {
    backgroundColor: "rgba(255, 255, 255, 0.57)",
  },
}));

export default function BuyCourseModal({ course }) {
  const { user, auth, isPayment, setIsPayment } = React.useContext(AuthContext);
  const [open, setOpen] = React.useState(false);
  const isLocalstoragePayment = localStorage.getItem("isPayment");

  const handleOpen = (e) => {
    e?.preventDefault();
    setOpen(true);
  };

  const handleClose = React.useCallback(
    (e) => {
      e?.preventDefault();
      localStorage.removeItem("isPayment");
      setIsPayment(false);
      setOpen(false);
    },
    [setIsPayment]
  );

  React.useEffect(() => {
    auth && (isPayment || isLocalstoragePayment) && !course?.isPaid
      ? handleOpen()
      : handleClose();
  }, [auth, course?.isPaid, handleClose, isPayment, isLocalstoragePayment]);

  if (!course || course?.isPaid) {
    return null;
  }

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-Payment-Modal"
      aria-describedby="modal-modal-description"
    >
      <Box
        className={classes.body}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Box className={classes.header}>
          <Typography
            className={classes.header__title}
            variant="h2"
            component="h2"
          >
            Checkout
          </Typography>
          <button
            aria-labelledby="close modal"
            onClick={handleClose}
            className={classes.header__close}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                d="M6.54673 20.1348L4.89673 18.4848L10.6717 12.7098L4.89673 6.93482L6.54673 5.28482L12.3217 11.0598L18.0967 5.28482L19.7467 6.93482L13.9717 12.7098L19.7467 18.4848L18.0967 20.1348L12.3217 14.3598L6.54673 20.1348Z"
                fill="#3D40D5"
              />
            </svg>
          </button>
        </Box>

        <Box className={classes.wrapper}>
          <Box className={classes.course}>
            <Box className={classes.course__data}>
              <Typography
                className={classes.course__title}
                variant="h3"
                component="h3"
              >
                {course?.title}
              </Typography>
              <img
                className={classes.course__image}
                src={course?.promoImageUrl}
                alt={course?.title}
                width={303}
                height={157}
              />
              <Typography className={classes.course__title}>
                ${course?.price}
              </Typography>
            </Box>

            <Box className={classes.coupon}>
              {course?.discountedPrice && course?.coupon ? (
                <CouponDetails
                  coupon={course?.coupon}
                  discountedPrice={course?.discountedPrice}
                  defaultPrice={course?.price}
                />
              ) : (
                <CouponForm courseId={course?.id} />
              )}
            </Box>

            <Box className={classes.course__price}>
              <Typography className={classes.total}>Order Total</Typography>
              <Typography className={classes.course__title}>
                {course?.discountedPrice ? (
                  <>
                    <span className={classes.oldPrice}>${course?.price}</span> $
                    {course?.discountedPrice}
                  </>
                ) : (
                  `$${course?.price}`
                )}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.content}>
            {user?.email || JSON.parse(localStorage.getItem("user"))?.email ? (
              <Typography className={classes.user}>
                {user?.email || JSON.parse(localStorage.getItem("user"))?.email}
              </Typography>
            ) : null}

            <Box className={classes.payment}>
              <Paypal courseId={course?.id} handleClose={handleClose} />
              <Square
                handleClose={handleClose}
                courseId={course?.id}
                coursePrice={course?.price}
                courseDiscountedPrice={course?.discountedPrice}
                courseTitle={course?.title}
              />
              <Typography className={classes.terms}>
                by paying you agree to the{" "}
                <Typography
                  className={classes.terms__link}
                  component={Link}
                  target={"_blank"}
                  to="/terms-and-conditions"
                >
                  Terms & Conditions
                </Typography>
              </Typography>
            </Box>

            <Typography className={classes.info}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <path
                  d="M11.3218 17.9388H13.3218V11.9388H11.3218V17.9388ZM12.3218 9.93884C12.6051 9.93884 12.8428 9.84284 13.0348 9.65084C13.2268 9.45884 13.3224 9.22151 13.3218 8.93884C13.3218 8.65551 13.2258 8.41784 13.0338 8.22584C12.8418 8.03384 12.6044 7.93818 12.3218 7.93884C12.0384 7.93884 11.8008 8.03484 11.6088 8.22684C11.4168 8.41884 11.3211 8.65618 11.3218 8.93884C11.3218 9.22218 11.4178 9.45984 11.6098 9.65184C11.8018 9.84384 12.0391 9.93951 12.3218 9.93884ZM12.3218 22.9388C10.9384 22.9388 9.63844 22.6762 8.42178 22.1508C7.20511 21.6255 6.14678 20.9132 5.24678 20.0138C4.34678 19.1138 3.63444 18.0555 3.10978 16.8388C2.58511 15.6222 2.32244 14.3222 2.32178 12.9388C2.32178 11.5555 2.58444 10.2555 3.10978 9.03884C3.63511 7.82218 4.34744 6.76384 5.24678 5.86384C6.14678 4.96384 7.20511 4.25151 8.42178 3.72684C9.63844 3.20218 10.9384 2.93951 12.3218 2.93884C13.7051 2.93884 15.0051 3.20151 16.2218 3.72684C17.4384 4.25218 18.4968 4.96451 19.3968 5.86384C20.2968 6.76384 21.0094 7.82218 21.5348 9.03884C22.0601 10.2555 22.3224 11.5555 22.3218 12.9388C22.3218 14.3222 22.0591 15.6222 21.5338 16.8388C21.0084 18.0555 20.2961 19.1138 19.3968 20.0138C18.4968 20.9138 17.4384 21.6265 16.2218 22.1518C15.0051 22.6772 13.7051 22.9395 12.3218 22.9388ZM12.3218 20.9388C14.5551 20.9388 16.4468 20.1638 17.9968 18.6138C19.5468 17.0638 20.3218 15.1722 20.3218 12.9388C20.3218 10.7055 19.5468 8.81384 17.9968 7.26384C16.4468 5.71384 14.5551 4.93884 12.3218 4.93884C10.0884 4.93884 8.19678 5.71384 6.64678 7.26384C5.09678 8.81384 4.32178 10.7055 4.32178 12.9388C4.32178 15.1722 5.09678 17.0638 6.64678 18.6138C8.19678 20.1638 10.0884 20.9388 12.3218 20.9388Z"
                  fill="#3D40D5"
                  fillOpacity="0.6"
                />
              </svg>
              Your security is important to us. We do not store your credit card
              information.
            </Typography>
          </Box>
        </Box>
      </Box>
    </CustomModal>
  );
}
