import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

export const ModuleAccordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: `none`,
  background: "var(--on-surface-a-100)",
  boxShadow: "none",
  borderRadius: 0,

  "&:before": {
    display: "none",
  },
}));

export const ModuleAccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M6 9.48666L12 15.4867L18 9.48666L16.5134 8.00006L12 12.5135L7.4866 8.00006L6 9.48666Z"
          fill="#3D40D5"
        />
      </svg>
    }
    {...props}
  />
))(() => ({
  borderRadius: 0,
  padding: "0.75rem 0",
  background: "var(--on-surface-a-100)",
  flexDirection: "row",
  minHeight: "unset",
  justifyContent: "space-between",
  gap: "0.5rem",
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "scale(1)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "scale(-1)",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
}));

export const ModuleAccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
  border: "none",
}));

export const TasksAccordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: `none`,
  background: "var(--on-surface-a-100)",
  boxShadow: "none",
  borderRadius: 0,

  marginLeft: "1.875rem",
  "&:not(:last-child)": {
    borderBottom: "1px solid var(--colors-a-010)",
  },

  "&:not(:first-child) > .MuiAccordionSummary-root": {
    padding: "0.75rem 0",
  },
  "&:first-child > .MuiAccordionSummary-root": {
    paddingBottom: "0.75rem",
  },

  "&:before": {
    display: "none",
  },
}));

export const TasksAccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M6 9.48666L12 15.4867L18 9.48666L16.5134 8.00006L12 12.5135L7.4866 8.00006L6 9.48666Z"
          fill="#3D40D5"
        />
      </svg>
    }
    {...props}
  />
))(() => ({
  borderRadius: 0,
  background: "var(--on-surface-a-100)",
  flexDirection: "row",
  minHeight: "unset",
  justifyContent: "space-between",
  gap: "0.5rem",
  padding: 0,

  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "scale(1)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "scale(-1)",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
}));

export const TasksAccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: "0 0 0.75rem 0",
  border: "none",
}));
