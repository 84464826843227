import { Box, Typography } from "@mui/material";
import { useDeferredValue } from "react";
import Review from "./Review";
import classes from "./index.module.scss";
import HTMLComponent from "../../../../components/Common/HTMLComponent";

export default function InstructorInfo({ instructor }) {
  const deferredReviews = useDeferredValue(instructor?.alumniFeedbacks);
  const feedbacks =
    instructor?.alumniFeedbacks && instructor.alumniFeedbacks.length;

  const rating = feedbacks
    ? instructor.alumniFeedbacks.reduce(
        (acc, feedback) => acc + Number(feedback.rating),
        0
      ) / instructor.alumniFeedbacks.length
    : false;

  return (
    <Box className={classes.info}>
      <Box className={classes.blockAbout}>
        <Typography component={"h2"} variant={"h2"} className={classes.title}>
          About
        </Typography>
        <HTMLComponent html={instructor.introductionText} />
      </Box>
      {feedbacks && deferredReviews ? (
        <Box className={classes.blockReviews}>
          <Typography component={"h2"} variant={"h2"} className={classes.title}>
            Reviews
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M21.947 9.17901C21.8841 8.99388 21.7684 8.83121 21.6142 8.71107C21.4599 8.59094 21.2739 8.51861 21.079 8.50301L15.378 8.05001L12.911 2.58901C12.8324 2.41313 12.7046 2.26374 12.543 2.15887C12.3814 2.05401 12.193 1.99815 12.0003 1.99805C11.8077 1.99794 11.6191 2.05359 11.4574 2.15828C11.2957 2.26296 11.1678 2.41221 11.089 2.58801L8.622 8.05001L2.921 8.50301C2.72945 8.51819 2.54633 8.58822 2.39355 8.70475C2.24077 8.82127 2.12479 8.97934 2.05949 9.16004C1.99419 9.34075 1.98233 9.53645 2.02534 9.72372C2.06835 9.91099 2.1644 10.0819 2.302 10.216L6.515 14.323L5.025 20.775C4.97975 20.9703 4.99425 21.1747 5.06662 21.3617C5.13898 21.5486 5.26586 21.7095 5.4308 21.8235C5.59574 21.9374 5.79111 21.9991 5.99158 22.0007C6.19204 22.0022 6.38834 21.9434 6.555 21.832L12 18.202L17.445 21.832C17.6153 21.9451 17.8162 22.0033 18.0206 21.9988C18.225 21.9944 18.4232 21.9274 18.5884 21.8069C18.7536 21.6865 18.878 21.5183 18.9448 21.3251C19.0116 21.1318 19.0176 20.9228 18.962 20.726L17.133 14.326L21.669 10.244C21.966 9.97601 22.075 9.55801 21.947 9.17901Z"
                  fill="#F6C344"
                />
              </svg>
              {rating.toFixed(1)}
            </span>
          </Typography>

          <Box className={classes.reviews}>
            {deferredReviews.map((review) => (
              <Review key={review.id} review={review} />
            ))}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
