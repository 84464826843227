export const topics = [
  {
    label: "Mobile Test Automation",
    value: "Mobile Test Automation",
  },
  {
    label: "Web TA",
    value: "Web TA",
  },
  {
    label: "Back-end TA",
    value: "Back-end TA",
  },
  {
    label: "Performance (iOS & Android)",
    value: "Performance (iOS & Android)",
  },
  {
    label: "Performance back-end",
    value: "Performance back-end",
  },
  {
    label: "Security testing",
    value: "Security testing",
  },
  {
    label: "Blockchain testing",
    value: "Blockchain testing",
  },
  {
    label: "Accessibility testing",
    value: "Accessibility testing",
  }
];
