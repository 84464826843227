import { Accordion, Summary, Details } from "../Accordion/TasksAccordion";
import { Box, Typography } from "@mui/material";
import useAccordion from "../../../../hooks/useAccordion";
import instructorsIcon from "../../../../assets/images/carbon_user-speaker.svg";
import classes from "./index.module.scss";
import React from "react";
import SectionText from "../components/SectionText";
import HTMLComponent from "../../../../components/Common/HTMLComponent";

export default function Content({ list }) {
  const { expanded, handleChange } = useAccordion();
  return (
    <Box className={classes.modulesList}>
      {list.map((item, index) => {
        return (
          <Accordion
            expanded={expanded}
            handleChange={handleChange}
            index={index}
            key={item.id}
          >
            <Summary index={index} className={classes.summary}>
              <Typography component="h3" className={classes.summary__title}>
                {item.title}
              </Typography>
            </Summary>
            <Details>
              <Box className={classes.details}>
                {item?.instructors && item?.instructors.length ? (
                  <Box className={classes.details__header}>
                    <img src={instructorsIcon} width={16} alt={"Instructors"} />
                    <Typography
                      component={"h4"}
                      className={classes.details__title}
                    >
                      {item.instructors.map((instructor, index, self) => (
                        <React.Fragment key={instructor.id}>
                          {instructor.firstName + " " + instructor.lastName}
                          {index === self.length - 1 ? null : ", "}
                        </React.Fragment>
                      ))}
                    </Typography>
                  </Box>
                ) : null}
                <Box className={classes.details__content}>
                  {item.text && item.text.length ? (
                    item.text.toString().includes("</") ? (
                      <HTMLComponent html={item.text} />
                    ) : (
                      <Box component={"ul"} className={classes.details__list}>
                        {item.text.map((text, index) => (
                          <SectionText key={index} component={"li"}>
                            {text}
                          </SectionText>
                        ))}
                      </Box>
                    )
                  ) : null}

                  {item?.recordLink ? (
                    <Box className={classes.record}>
                      <Typography
                        component={"h3"}
                        className={classes.record__title}
                      >
                        Link to the recording
                      </Typography>
                      <Typography
                        component={"a"}
                        target={"_blank"}
                        href={item.recordLink}
                        className={classes.record__link}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="7"
                          viewBox="0 0 14 7"
                          fill="none"
                        >
                          <path
                            d="M1.63991 3.08748C1.82658 2.10748 2.74658 1.43415 3.74658 1.43415H5.69991C6.04658 1.43415 6.33324 1.14748 6.33324 0.800814C6.33324 0.454147 6.04658 0.16748 5.69991 0.16748H3.81324C2.07324 0.16748 0.519912 1.44081 0.353245 3.17415C0.307153 3.63697 0.358526 4.10431 0.504052 4.54606C0.649579 4.98782 0.886032 5.39419 1.19818 5.73899C1.51032 6.0838 1.89124 6.35939 2.31638 6.54802C2.74153 6.73664 3.20147 6.83411 3.66658 6.83415H5.69991C6.04658 6.83415 6.33324 6.54748 6.33324 6.20081C6.33324 5.85415 6.04658 5.56748 5.69991 5.56748H3.66658C3.36036 5.56654 3.05815 5.49777 2.78167 5.36613C2.50519 5.23449 2.2613 5.04324 2.06753 4.80613C1.87376 4.56901 1.73492 4.29192 1.66098 3.99476C1.58704 3.6976 1.57985 3.38775 1.63991 3.08748ZM4.99991 4.16748H8.99991C9.36658 4.16748 9.66658 3.86748 9.66658 3.50081C9.66658 3.13415 9.36658 2.83415 8.99991 2.83415H4.99991C4.63324 2.83415 4.33324 3.13415 4.33324 3.50081C4.33324 3.86748 4.63324 4.16748 4.99991 4.16748ZM10.1866 0.16748H8.29991C7.95324 0.16748 7.66658 0.454147 7.66658 0.800814C7.66658 1.14748 7.95324 1.43415 8.29991 1.43415H10.2532C11.2532 1.43415 12.1732 2.10748 12.3599 3.08748C12.42 3.38775 12.4128 3.6976 12.3388 3.99476C12.2649 4.29192 12.1261 4.56901 11.9323 4.80613C11.7385 5.04324 11.4946 5.23449 11.2182 5.36613C10.9417 5.49777 10.6395 5.56654 10.3332 5.56748H8.29991C7.95324 5.56748 7.66658 5.85415 7.66658 6.20081C7.66658 6.54748 7.95324 6.83415 8.29991 6.83415H10.3332C12.2799 6.83415 13.8399 5.16081 13.6532 3.17415C13.4866 1.44081 11.9266 0.16748 10.1866 0.16748Z"
                            fill="#3D40D5"
                          />
                        </svg>
                        {item?.recordTitle || item.recordLink}
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </Details>
          </Accordion>
        );
      })}
    </Box>
  );
}
