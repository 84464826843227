import { ReactComponent as RecordedIcon } from "../images/recorded.svg";
import { ReactComponent as LiveIcon } from "../images/live.svg";
import { Box } from "@mui/material";
import classes from "./index.module.scss";

export default function CourseType({ type }) {
  
  if (!type || (type !== "recorded" && type !== "live")) return null;
  return (
    <Box className={`${classes.chip} ${classes["chip__" + type]}`}>
      {type === "recorded" ? <RecordedIcon /> : null}
      {type === "live" ? <LiveIcon /> : null}
      <span>
        {type === "recorded" ? "Recorded course" : null}
        {type === "live" ? "Live course" : null}
      </span>
    </Box>
  );
}
