import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";

export default function Workplace({ linkTitle, link }) {
  return (
    <Box className={classes.wrapper}>
      <Typography component={"h4"} className={classes.title}>Link to workspace</Typography>
      <Typography component={"a"} href={link} className={classes.link} target="_blank">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
        >
          <path
            d="M2.63997 8.0873C2.82664 7.1073 3.74664 6.43396 4.74664 6.43396H6.69997C7.04664 6.43396 7.33331 6.1473 7.33331 5.80063C7.33331 5.45396 7.04664 5.1673 6.69997 5.1673H4.81331C3.07331 5.1673 1.51997 6.44063 1.35331 8.17396C1.30721 8.63678 1.35859 9.10412 1.50411 9.54588C1.64964 9.98764 1.88609 10.394 2.19824 10.7388C2.51039 11.0836 2.8913 11.3592 3.31644 11.5478C3.74159 11.7365 4.20153 11.8339 4.66664 11.834H6.69997C7.04664 11.834 7.33331 11.5473 7.33331 11.2006C7.33331 10.854 7.04664 10.5673 6.69997 10.5673H4.66664C4.36042 10.5664 4.05821 10.4976 3.78173 10.366C3.50525 10.2343 3.26136 10.0431 3.06759 9.80595C2.87382 9.56883 2.73498 9.29174 2.66104 8.99458C2.5871 8.69742 2.57991 8.38757 2.63997 8.0873ZM5.99997 9.1673H9.99997C10.3666 9.1673 10.6666 8.8673 10.6666 8.50063C10.6666 8.13396 10.3666 7.83396 9.99997 7.83396H5.99997C5.63331 7.83396 5.33331 8.13396 5.33331 8.50063C5.33331 8.8673 5.63331 9.1673 5.99997 9.1673ZM11.1866 5.1673H9.29997C8.95331 5.1673 8.66664 5.45396 8.66664 5.80063C8.66664 6.1473 8.95331 6.43396 9.29997 6.43396H11.2533C12.2533 6.43396 13.1733 7.1073 13.36 8.0873C13.42 8.38757 13.4128 8.69742 13.3389 8.99458C13.265 9.29174 13.1261 9.56883 12.9324 9.80595C12.7386 10.0431 12.4947 10.2343 12.2182 10.366C11.9417 10.4976 11.6395 10.5664 11.3333 10.5673H9.29997C8.95331 10.5673 8.66664 10.854 8.66664 11.2006C8.66664 11.5473 8.95331 11.834 9.29997 11.834H11.3333C13.28 11.834 14.84 10.1606 14.6533 8.17396C14.4866 6.44063 12.9266 5.1673 11.1866 5.1673Z"
            fill="#3D40D5"
          />
        </svg>
        {linkTitle}
      </Typography>
    </Box>
  );
}
