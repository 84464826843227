import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { filterUniqueByKey } from "../../../../helpers/filterUniqueByKey";

async function fetchTags() {
  const response = await axios.get(
    `${process.env.REACT_APP_WP_PATH}events?_fields=event_tags,tags`
  );

  const data = response.data.reduce((acc, tags) => {
    tags.event_tags && tags.event_tags.forEach((tag) => acc.push(tag));
    return filterUniqueByKey(acc, "id");
  }, []);

  return data;
}

export function useEventsTags() {
  return useQuery({
    queryKey: ["events", "tags"],
    queryFn: () => fetchTags(),
    staleTime: Infinity,
  });
}
