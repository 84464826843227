import { Box, Typography } from "@mui/material";

import { useDeferredValue } from "react";
import classes from "./index.module.scss";

export default function Courses({ courses }) {
  const deferredCourses = useDeferredValue(courses || []);

  if (!courses || !courses.length) return null;

  return (
    <Box component={"ul"} className={classes.list}>
      {[...deferredCourses.slice(0, 3)].map((course) => (
        <Typography
          component={"li"}
          key={course.id}
          className={classes.list__item}
        >
          <span>{course.title}</span>
        </Typography>
      ))}
      {deferredCourses.length > 3 ? (
        <Typography
          component={"li"}
          key={"rest"}
          className={classes.list__item}
        >
          <span>
            +{deferredCourses.length - 3} more{" "}
            {deferredCourses.length - 3 === 1 ? "course" : "courses"}
          </span>
        </Typography>
      ) : null}
    </Box>
  );
}
