import { createContext, useContext, useEffect, useMemo, useState } from "react";
import errorToast from "../components/toasts/errorToast";
import { api } from "../instances/axiosInstances";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "./AuthContext";
export const QuestionnairePopupContext = createContext();

export default function QuestionnairePopupContextProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const { auth, isAuthLoading } = useContext(AuthContext);

  const queryClient = useQueryClient();

  const { mutate: skipPopup, isLoading: isSkipPopupLoading } = useMutation(
    () => api.put("/users/questionnaireSkip"),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ["user"],
          refetchType: "all",
          exact: true,
        });

        handleClose();
      },
      onError: (error) => {
        errorToast(error.response.data.message);
      },
    }
  );

  const handleOpen = (user) => {
    !open &&
      user &&
      !user.isQuestionnaireComplete &&
      !user.isSkipQuestionnaireReminder &&
      setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleOpen(currentUser);
      clearTimeout(timeout);
    }, 180000);

    return () => {
      clearTimeout(timeout);
      handleClose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, currentUser, isAuthLoading]);

  const value = useMemo(
    () => ({
      open,
      handleOpen,
      handleClose,
      skipPopup,
      isSkipPopupLoading,
      setOpen,
      setCurrentUser,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open, isSkipPopupLoading]
  );

  return (
    <QuestionnairePopupContext.Provider value={value}>
      {children}
    </QuestionnairePopupContext.Provider>
  );
}
