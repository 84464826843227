import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import classes from "./index.module.scss";

export default function Card({ post }) {
  return (
    <Box className={classes.card}>
      <Box
        component={Link}
        to={`/posts/${post.slug}`}
        className={classes.card__image}
      >
        <img
          src={post.img_url.full}
          alt={post.title}
          width={post.img_url.fullWidth}
        />
      </Box>
      <Box className={classes.card__text}>
        <Typography
          className={classes.card__title}
          component={Link}
          to={`/posts/${post.slug}`}
        >
          <span>{post.title.rendered}</span>
        </Typography>
        <Typography className={classes.card__date}>
          {new Date(post.date).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
          {post.post_tags.length ? (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="4"
                height="5"
                viewBox="0 0 4 5"
                fill="none"
              >
                <circle
                  cx="2"
                  cy="2.5"
                  r="2"
                  fill="#141416"
                  fillOpacity="0.4"
                />
              </svg>
              {post.post_tags
                .reduce((acc, { name }) => [...acc, name], [])
                .join(", ")}
            </>
          ) : null}
        </Typography>
      </Box>
    </Box>
  );
}
