import { Container } from "@mui/material";
import { LandingLayout, PlatformLayout } from "../../../components/Layouts";
import RatingScreen from "./RatingScreen";
import classes from "./index.module.scss";

export default function Rating({ isAuth }) {
  return isAuth ? (
    <PlatformLayout>
      <RatingScreen isAuth={isAuth}/>
    </PlatformLayout>
  ) : (
    <LandingLayout>
      <Container className={classes.wrapper} maxWidth={false} disableGutters>
        <Container className={classes.container} maxWidth="lg">
          <RatingScreen isAuth={isAuth}/>
        </Container>
      </Container>
    </LandingLayout>
  );
}
