import classes from "./index.module.scss";

export default function ScrollToAnchor({ anchor, children }) {
  return (
    <a
      className={classes.anchor}
      href={`#${anchor}`}
      onClick={(event) => {
        event.preventDefault();
        const targetElement = document.getElementById(`${anchor}`);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      }}
    >
      {children}
    </a>
  );
}
