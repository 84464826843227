import { useQuery } from "@tanstack/react-query";
import axios from "axios";

async function fetchPopup({ slug }) {
  const response = await axios.get(
    `${process.env.REACT_APP_WP_PATH}popups?slug=${slug}&_fields=id,custom_fields`
  );
  const data = { popup: response.data[0] };
  return data;
}

export function usePopup({ slug }) {
  return useQuery({
    queryKey: ["popup", slug],
    queryFn: () => fetchPopup({ slug }),
    staleTime: Infinity,
  });
}
