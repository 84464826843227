import { Box } from "@mui/material";
import Badge from "./Badge";
import classes from "./index.module.scss";

export default function Info({
  info: { completeCoursesCount, score, position },
}) {
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.info}>
        <Badge
          title={"Rating"}
          icon={"🏅"}
          data={position || position === 0 ? position : "-"}
        />
        <Badge
          title={"Points"}
          icon={"💎"}
          data={score || score === 0 ? score : 0}
        />
        <Badge
          title={"Finished courses"}
          icon={"🎓"}
          data={
            completeCoursesCount || completeCoursesCount === 0
              ? completeCoursesCount
              : 0
          }
        />
      </Box>
    </Box>
  );
}
