import { useQuery } from "@tanstack/react-query";
import { Box, Container, Typography } from "@mui/material";
import classes from "./index.module.scss";
import Slider from "./Slider/Slider";
import { api } from "../../../../instances/axiosInstances";

export default function LearningPath({ data }) {
  const itemsRes = useQuery(
    ["learningPaths"],
    () => api.get(`/paths/unregistered`),
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
    }
  );

  const items = itemsRes.isSuccess ? itemsRes.data?.data?.paths : [];

  if (!data || !items.length) return null;

  if (data?.custom_fields?.display_section[0] === "off") return null;

  return (
    <Box className={classes.section} component="section">
      <Container maxWidth="lg">
        <Typography className={classes.title} component="h2">
          {data?.title?.rendered}
        </Typography>
        <Slider data={items} />
      </Container>
    </Box>
  );
}
