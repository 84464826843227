import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";

export default function Card({ title, text, Icon }) {
  return (
    <Box className={classes.card}>
      <Box className={classes.card__image}>
        <Icon />
      </Box>
      <Typography
        variant={"h3"}
        component={"h3"}
        className={classes.card__title}
      >
        {title}
      </Typography>
      <Typography className={classes.card__text}>{text}</Typography>
    </Box>
  );
}
