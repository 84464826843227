import { Box, Typography } from "@mui/material";
import { AccentButton, RedButton } from "../../components/MUI/buttons/button";
import QuestionnaireUnfilled from "./QuestionnaireUnfilled";
import QuestionnaireFilled from "./QuestionnaireFilled";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "../../instances/axiosInstances";
import * as yup from "yup";
import useShowPassword from "../../hooks/useShowPassword";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef } from "react";
import ShowPasswordIcon from "../../components/Common/ShowPasswordIcon";
import errorToast from "../../components/toasts/errorToast";
import successToast from "../../components/toasts/successToast";
import { IconButton, InputAdornment } from "@mui/material";
import { Input } from "../../components/MUI/inputs";
import { PASSWORD_REGEX } from "../../constants/regex";
import ImageUpload from "./ImageUpload";
import { PlatformLayout } from "../../components/Layouts";
import useModal from "../../components/Common/Modal/useModal";
import Modal from "../../components/Common/Modal";
import DeleteProfileModal from "./DeleteProfileModal";
import classes from "./index.module.scss";

const updatePasswordSchema = yup.object().shape({
  currentPassword: yup.string().required("Current password is required"),
  newPassword: yup
    .string()
    .required("New password is required")
    .matches(
      PASSWORD_REGEX,
      "Your password should have minimum eight characters, at least one uppercase letter, one lowercase letter and one digit"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Repeat password is required"),
});

export default function Profile({ user }) {
  const { handleClickShowPassword, showPassword } = useShowPassword();
  const password = useRef({});
  const queryClient = useQueryClient();
  const modalRef = useRef();
  const { handleOpen, handleClose } = useModal(modalRef);

  const data = queryClient.getQueryData(["user"]);
  const state = queryClient.getQueryState(["user"]);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updatePasswordSchema),
  });

  password.current = watch("password", "");

  const { mutate, isLoading } = useMutation(
    (data) => api.put("/users/password", data),
    {
      onSuccess: ({ data }) => {
        // setAuthUser(data);
        successToast("Password updated successfully");
        reset();
      },
      onError: (error) => {
        errorToast(error.response.data.message);
      },
    }
  );

  const onSubmit = (data) => {
    delete data.confirmPassword;
    mutate(data);
  };

  useEffect(() => {
    localStorage.removeItem("redirectPath");
  }, []);

  useEffect(() => {
    return () => {
      handleClose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal modalRef={modalRef} className={classes.modal}>
        <DeleteProfileModal handleClose={handleClose} />
      </Modal>

      <PlatformLayout>
        <Box className={classes.body}>
          <Typography component={"h1"} variant={"h1"} className={classes.title}>
            Profile
          </Typography>
          <Box className={classes.content}>
            <Typography
              component={"h2"}
              variant={"h2"}
              className={classes.content__title}
            >
              General
            </Typography>
            {data && data.data.users.isQuestionnaireComplete ? (
              <QuestionnaireFilled
                user={data.data.users}
                isUserLoading={state.status === "loading"}
              />
            ) : (
              <QuestionnaireUnfilled email={user.email} />
            )}
          </Box>
          <Box className={classes.content}>
            <Typography
              component={"h2"}
              variant={"h2"}
              className={classes.content__title}
            >
              Profile picture
            </Typography>
            <ImageUpload
              profilePhotoUrl={data?.data.users.profilePhotoUrl}
              userId={data?.data.users.id}
            />
          </Box>
          <Box className={classes.content}>
            <Typography
              component={"h2"}
              variant={"h2"}
              className={classes.content__title}
            >
              Change password
            </Typography>
            <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
              <Box className={classes.password}>
                <Input
                  label="Current password*"
                  type={showPassword ? "text" : "password"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  autoComplete="new-password"
                  disabled={isLoading}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          <ShowPasswordIcon showPassword={showPassword} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                  placeholder="Current password"
                  {...register("currentPassword")}
                  error={!!errors.currentPassword}
                  helperText={
                    errors.currentPassword && errors.currentPassword.message
                  }
                />
                <Input
                  label="New password*"
                  type={showPassword ? "text" : "password"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  disabled={isLoading}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          <ShowPasswordIcon showPassword={showPassword} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                  placeholder="New password"
                  {...register("newPassword")}
                  error={!!errors.newPassword}
                  helperText={errors.newPassword && errors.newPassword.message}
                />
                <Input
                  label="Repeat new password*"
                  type={showPassword ? "text" : "password"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  disabled={isLoading}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          <ShowPasswordIcon showPassword={showPassword} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                  placeholder="Repeat new password"
                  {...register("confirmPassword")}
                  error={!!errors.confirmPassword}
                  helperText={
                    errors.confirmPassword && errors.confirmPassword.message
                  }
                />
              </Box>
              <Box className={classes.actions}>
                <AccentButton
                  isBig={false}
                  variant="contained"
                  fullWidth
                  type={"submit"}
                  disabled={isLoading}
                >
                  Update password
                </AccentButton>
              </Box>
            </Box>
          </Box>
          <Box className={classes.content}>
            <Typography
              component={"h2"}
              variant={"h2"}
              className={classes.content__title}
            >
              Delete account
            </Typography>
            <Box className={classes.delete}>
              <Typography className={classes.delete__text}>
                All your personal data, purchases, access to courses, rating and
                progress will be permanently deleted. This action can’t be
                reversed.
              </Typography>
              <Box className={classes.actions}>
                <RedButton
                  onClick={handleOpen}
                  isBig={false}
                  variant="contained"
                  fullWidth
                >
                  Delete account
                </RedButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </PlatformLayout>
    </>
  );
}
