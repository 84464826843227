import { Box } from "@mui/material";
import classes from "./index.module.scss";
import SectionTitle from "../components/SectionTitle";
import SectionText from "../components/SectionText";
import SectionGrid from "../components/SectionGrid";
import HTMLComponent from "../../../../components/Common/HTMLComponent";

export default function Requirements({ requirements, className = "" }) {
  if (!requirements || !requirements.length) {
    return null;
  }
  return (
    <SectionGrid className={className}>
      <SectionTitle>⚠️ Course requirements</SectionTitle>
      {requirements.toString().includes("</") ? (
        <HTMLComponent html={requirements} />
      ) : (
        <Box component="ul" className={classes.list}>
          {requirements.map((requirement, index) => (
            <SectionText key={index} component="li">
              {requirement}
            </SectionText>
          ))}
        </Box>
      )}
    </SectionGrid>
  );
}
