import { useEffect, useState } from "react";

export default function useProgress({
  course,
  courseLecturesCount,
  finishedCourseLecturesCount,
}) {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    course &&
      courseLecturesCount &&
      setProgress((finishedCourseLecturesCount / courseLecturesCount) * 100);
  }, [course, courseLecturesCount, finishedCourseLecturesCount]);
  return { progress };
}
