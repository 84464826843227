import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RedirectContext } from "../context/RedirectContext";
import { AuthContext } from "../context/AuthContext";
import { useQueryClient } from "@tanstack/react-query";

export default function useButtonClick() {
  const { addCurrentCourseId, addCurrentLectureId } =
    useContext(RedirectContext);
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const client = useQueryClient();
  const { pathname } = useLocation();

  const onStartForFreeClick = () => {
    localStorage.setItem("redirectPath", pathname);
    navigate("/registration");
  };

  const onExternalLinkClick = (isFree) => {
    localStorage.setItem("externalLink", JSON.stringify(isFree));
  };
  const removeExternalLink = () => {
    localStorage.removeItem("externalLink");
  };

  const onSignUpClick = () => {
    const state = client.getQueryState(["courses", "unregistered"]);
    const redirectCourseID = state?.data.data.courses?.at(0)?.id;
    addCurrentCourseId(redirectCourseID);
    auth ? navigate(`/courses/${redirectCourseID}`) : navigate("/registration");
  };

  const onCoursePaymentClick = (id) => {
    addCurrentCourseId(id);
    auth ? navigate(`/courses/${id}`) : navigate("/registration");
  };

  const onLecturePaymentClick = (courseId, lectureId) => {
    addCurrentCourseId(courseId);
    addCurrentLectureId(lectureId);
    auth
      ? navigate(`/courses/${courseId}/lecture/${lectureId}`)
      : navigate("/registration");
  };

  return {
    onStartForFreeClick,
    onSignUpClick,
    onCoursePaymentClick,
    onLecturePaymentClick,
    onExternalLinkClick,
removeExternalLink
  };
}
