import * as React from "react";
import Box from "@mui/material/Box";
import classes from "./index.module.scss";
import { Container } from "@mui/material";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function PlatformLayout({ children }) {
  React.useEffect(() => {
    localStorage.removeItem("redirectPath");
  }, []);

  return (
    <Container disableGutters maxWidth={false} className={classes.container}>
      <Header variant="platform" />
      <Container maxWidth="lg" className={classes.container__content}>
        <Box component={"main"} className={classes.outlet}>
          {children}
        </Box>
      </Container>
      <Box className={classes.footer}>
        <Footer />
      </Box>
    </Container>
  );
}
