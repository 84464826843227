import { useContext, useRef } from "react";
import { ModalContext } from "../../../../context/ModalContext";
import CustomNavigation from "./CustomNavigation";
import { Box } from "@mui/material";
import VideoModal from "../../../../components/Common/EmbedVideos/VideoWrapper";
import classes from "./index.module.scss";
import Carousel from "./Carousel";
import SectionTitle from "../components/SectionTitle";

export default function RelatedMaterials({ playlist }) {
  const sliderRef = useRef(null);
  const { handleModalOpen } = useContext(ModalContext);

  const showModal = ({ url, title }) => {
    handleModalOpen(<VideoModal title={title} url={url} />);
  };

  if (!playlist || !playlist?.length) {
    return null;
  }

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.top}>
        <SectionTitle>🎬 Subscribe to our free Youtube channel</SectionTitle>
        <CustomNavigation sliderRef={sliderRef} variant="outlined" />
      </Box>
      <Box className={classes.carousel}>
        <Carousel
          sliderRef={sliderRef}
          playlist={playlist}
          showModal={showModal}
        />
      </Box>
    </Box>
  );
}
