import React, { useState } from "react";
import { Input } from "../../../components/MUI/inputs";
import { Autocomplete, Box, Typography } from "@mui/material";
import { ReactComponent as Chevron } from "../../../assets/images/bx-chevron-down.svg.svg";
import countries from "../../../constants/countries.json";
import states from "../../../constants/states.json";
import classes from "./index.module.scss";
import BackLink from "../../../components/Common/BackLink";


export default function Step2({
  register,
  errors,
  clearErrors,
  resetField,
  getValues,
  onPrevStep,
}) {
  const [country, setCountry] = useState(null);

  return (
    <>
      <BackLink onClick={onPrevStep}/>
      <Typography component={"h2"} variant={"h2"} className={classes.title}>Location</Typography>
      <Autocomplete
        id="country-select"
        fullWidth
        options={countries}
        defaultValue={getValues("country") ? getValues("country") : null}
        autoHighlight
        popupIcon={<Chevron />}
        getOptionLabel={(option) => option}
        sx={{
          "& .MuiInputBase-root": {
            padding: "0 4rem 0 0",
          },
        }}
        onChange={(_, value) => {
          resetField("country");
          setCountry(value || null);
          clearErrors(["country"]);
          resetField("state");
        }}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            {option}
          </Box>
        )}
        renderInput={(params) => (
          <Input
            {...params}
            label="Country*"
            type="text"
            margin="normal"
            variant="outlined"
            fullWidth
            autoComplete="new-password"
            InputLabelProps={{
              shrink: true,
              disableAnimation: true,
            }}
            placeholder="Country"
            {...register("country")}
            error={!!errors.country}
            helperText={errors.country && errors.country.message}
          />
        )}
      />
      {(getValues("country") || country) === "USA" && (
        <Autocomplete
          id="state-select"
          fullWidth
          options={states}
          autoHighlight
          getOptionLabel={(option) => option}
          defaultValue={getValues("state") ? getValues("state") : null}
          popupIcon={<Chevron />}
          onChange={(_, value) => {
            resetField("state");
            clearErrors(["state"]);
          }}
          sx={{
            "& .MuiInputBase-root": {
              padding: "0 4rem 0 0",
            },
          }}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option}
            </Box>
          )}
          renderInput={(params) => (
            <Input
              {...params}
              label="State*"
              type="text"
              margin="normal"
              variant="outlined"
              fullWidth
              autoComplete="new-password"
              InputLabelProps={{
                shrink: true,
                disableAnimation: true,
              }}
              placeholder="State"
              {...register("state")}
              error={!!errors.state}
              helperText={errors.state && errors.state.message}
            />
          )}
        />
      )}

      <Input
        label="City*"
        type="text"
        margin="normal"
        variant="outlined"
        fullWidth
        autoComplete="new-password"
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        placeholder="City"
        {...register("city")}
        error={!!errors.city}
        helperText={errors.city && errors.city.message}
      />
    </>
  );
}
