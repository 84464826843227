import { toast } from "react-toastify";
const successToast = (message, options = {}) => {
  const defaultOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "light",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="14"
        viewBox="0 0 18 14"
        fill="none"
      >
        <path
          d="M5.99997 11.1701L1.82997 7.00009L0.409973 8.41009L5.99997 14.0001L18 2.00009L16.59 0.590088L5.99997 11.1701Z"
          fill="#29CC6A"
        />
      </svg>
    ),
  };
  const mergedOptions = { ...defaultOptions, ...options };

  toast.success(message, mergedOptions);
};

export default successToast;
