import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import classes from "./index.module.scss";

export default function ShowMore({ maxHeight = 280, children, overlayColor="blue" }) {
  const [showAllItems, setShowAllItems] = useState(null);
  const listRef = useRef(null);

  const toggleItems = () => {
    setShowAllItems(!showAllItems);
  };

  useEffect(() => {
    setShowAllItems(false)
  }, [children])
  

  return (
    <Box>
      <Box
        className={`${classes.container} ${
          showAllItems ? classes.showAll : ""
        }`}
        ref={listRef}
        sx={
          showAllItems
            ? { maxHeight: listRef.current.scrollHeight }
            : { maxHeight: maxHeight }
        }
      >
        {children}
      </Box>
      {listRef?.current?.scrollHeight > maxHeight ? (
        <Box className={classes.wrapper}>
          {!showAllItems ? <Box className={`${classes.overlay} ${classes["overlay_" + overlayColor]}`}></Box> : null}
          <button onClick={toggleItems} className={classes.showMoreBtn}>
            {showAllItems ? "Show Less" : "Show More"}
          </button>
        </Box>
      ) : null}
    </Box>
  );
}
