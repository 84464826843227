import { Box, Container, Typography } from "@mui/material";
import { ReactComponent as Icon3 } from "./images/graduation-hat-02.svg";
import { ReactComponent as Icon2 } from "./images/Icons.svg";
import { ReactComponent as Icon1 } from "./images/laptop-01.svg";
import { ReactComponent as Icon4 } from "./images/marker-pin-05.svg";
import { WhiteButton } from "../../../../components/MUI/buttons/button";

import classes from "./index.module.scss";

export default function DiscordSupport() {
  return (
    <Container maxWidth="lg" className={classes.container}>
      <Box className={classes.body} component={"section"}>
        <Box className={classes.image}></Box>
        <Box className={classes.content}>
          <Typography component={"h2"} variant={"h2"} className={classes.title}>
            🗓️ Why should you book a mentorship session?
          </Typography>
          <Box component={"ul"} className={classes.list}>
            <Box component={"li"} className={classes.item}>
              <Icon1 className={classes.item__icon} />
              <Typography component={"h3"} className={classes.item__title}>
                Experience in IT consulting
              </Typography>
              <Typography className={classes.item__text}>
                Our mentors are seasoned IT specialists who have a combined of
                18+ years of experience
              </Typography>
            </Box>
            <Box component={"li"} className={classes.item}>
              <Icon2 className={classes.item__icon} />
              <Typography component={"h3"} className={classes.item__title}>
                You are not alone!
              </Typography>
              <Typography className={classes.item__text}>
                Join Discord community to get 24/7 support and share experience
                with other students
              </Typography>
            </Box>
            <Box component={"li"} className={classes.item}>
              <Icon3 className={classes.item__icon} />
              <Typography component={"h3"} className={classes.item__title}>
                Engenious University
              </Typography>
              <Typography className={classes.item__text}>
                Get quick access to our exclusive courses, internship
                opportunities and real-world projects.
              </Typography>
            </Box>
            <Box component={"li"} className={classes.item}>
              <Icon4 className={classes.item__icon} />
              <Typography component={"h3"} className={classes.item__title}>
                Focus on you
              </Typography>
              <Typography className={classes.item__text}>
                Mentorship is individually tailored to your needs and requests -
                we will guide you until you're 100% sure of yourself.{" "}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.actions}>
            <WhiteButton
              variant={"contained"}
              component={"a"}
              href="https://calendly.com/mentors-engenious"
              target={"_blank"}
              fullWidth
            >
              Book your session
            </WhiteButton>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
