import { Box } from "@mui/material";
import TagsList from "./TagsList";
import classes from "./index.module.scss";
import Search from "./Search";

export default function Filters({
  setSearch,
  search,
  setPage,
  page,
  setTags,
  tags,
  tagsList,
}) {
  return (
    <Box className={classes.filters}>
      <Search
        setSearch={setSearch}
        search={search}
        setPage={setPage}
        page={page}
      />
      <TagsList
        page={page}
        setPage={setPage}
        setTags={setTags}
        tags={tags}
        tagsList={tagsList}
      />
    </Box>
  );
}
