import { useRef } from "react";
import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { debounce } from "../../../helpers/debounce";
import classes from "./index.module.scss";
import { Input } from '../../MUI/inputs';

export default function Search({ setSearch, search, setPage, page }) {
  const ref = useRef();

  const onChange = (e) => {
    page !== 1 && setPage(1);
    setSearch(e.target.value);
  };

  const handleClear = () => {
    page !== 1 && setPage(1);
    setSearch("");
    let input = ref?.current.childNodes[1];
    input.value = "";
  };

  const debouncedOnChange = debounce(onChange);

  return (
    <Input
      className={classes.search}
      label="Search"
      type="text"
      margin="normal"
      variant="outlined"
      fullWidth
      autoComplete="new-password"
      InputLabelProps={{
        shrink: true,
        disableAnimation: true,
      }}
      placeholder="Search"
      defaultValue={search}
      onChange={debouncedOnChange}
      InputProps={{
        ref: ref,
        startAdornment: (
          <InputAdornment position="start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M8.33342 15C9.81256 14.9997 11.2491 14.5045 12.4142 13.5934L16.0776 17.2567L17.2559 16.0784L13.5926 12.415C14.5042 11.2497 14.9997 9.81288 15.0001 8.33335C15.0001 4.65752 12.0092 1.66669 8.33342 1.66669C4.65758 1.66669 1.66675 4.65752 1.66675 8.33335C1.66675 12.0092 4.65758 15 8.33342 15ZM8.33342 3.33335C11.0909 3.33335 13.3334 5.57585 13.3334 8.33335C13.3334 11.0909 11.0909 13.3334 8.33342 13.3334C5.57591 13.3334 3.33341 11.0909 3.33341 8.33335C3.33341 5.57585 5.57591 3.33335 8.33342 3.33335Z"
                fill="#3D40D5"
                fillOpacity="0.4"
              />
            </svg>
          </InputAdornment>
        ),
        endAdornment: search ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="Clear search input"
              onClick={() => {
                handleClear();
              }}
              disableRipple
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M6.2252 19.425L4.5752 17.775L10.3502 12L4.5752 6.22501L6.2252 4.57501L12.0002 10.35L17.7752 4.57501L19.4252 6.22501L13.6502 12L19.4252 17.775L17.7752 19.425L12.0002 13.65L6.2252 19.425Z"
                  fill="#3D40D5"
                />
              </svg>
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    />
  );
}
