import { useContext, useEffect, useRef } from "react";
import Modal from "../Modal";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close-icon.svg";

import { WhiteButton } from "../../MUI/buttons/button";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { usePopup } from "./hooks/usePopup";
import { useQueryClient } from "@tanstack/react-query";
import { WebinarPopupContext } from "../../../context/WebinarPopupContext";
import classes from "./index.module.scss";

export default function WebinarPopup() {
  const {
    isWebinarPopupFieldsLoaded,
    setIsWebinarPopupFieldsLoaded,
    webinarPopupRef,
    setWebinarPopupRef,
    handleWebinarPopupClose,
    setCurrentWebinarId,
  } = useContext(WebinarPopupContext);
  const modalRef = useRef();
  const { data, isFetching } = usePopup({ slug: "webinar" });
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData(["user"])?.data;

  const customFields = data?.popup?.custom_fields;
  const date = customFields?.date;
  const link = customFields?.link;
  const subtitle = customFields?.subtitle;
  const buttonText = customFields?.button_text;
  const text = customFields?.text;
  const time = customFields?.time;
  const title = customFields?.title;
  const type = customFields?.type;
  const webinarId = customFields?.webinar_id?.toString();

  const handleChange = () => {
    const webinars = localStorage.getItem("webinars");

    if (webinars) {
      const parsedWebinars = JSON.parse(webinars);
      const newWebinars = parsedWebinars.includes(webinarId)
        ? parsedWebinars.filter((id) => id !== webinarId)
        : [...parsedWebinars, webinarId];
      newWebinars.length
        ? localStorage.setItem("webinars", JSON.stringify(newWebinars))
        : localStorage.removeItem("webinars");
    } else {
      localStorage.setItem("webinars", JSON.stringify([webinarId]));
    }
  };

  useEffect(() => {
    customFields && setIsWebinarPopupFieldsLoaded(true);
  }, [customFields]);

  useEffect(() => {
    modalRef && setWebinarPopupRef(modalRef);
  }, [modalRef]);

  useEffect(() => {
    webinarId && setCurrentWebinarId(webinarId);
  }, [webinarId]);

  if (!isWebinarPopupFieldsLoaded || isFetching) return null;

  return (
    <Modal modalRef={webinarPopupRef} className={classes.dialog}>
      <Box className={classes.modal}>
        <Box className={classes.modal__header}>
          <Box className={classes.modal__top}>
            {type ? (
              <Box className={classes.modal__badge}>{type.toString()}</Box>
            ) : null}
            <button
              onClick={handleWebinarPopupClose}
              className={classes.modal__closeBtn}
            >
              <CloseIcon />
            </button>
          </Box>
          {title ? (
            <Typography
              component={"h2"}
              variant={"h2"}
              className={classes.modal__title}
            >
              {title.toString()}
            </Typography>
          ) : null}
          {subtitle ? (
            <Typography component={"h3"} className={classes.modal__subtitle}>
              {subtitle.toString()}
            </Typography>
          ) : null}
        </Box>

        {text ? (
          <Typography className={classes.modal__text}>
            {text.toString()}
          </Typography>
        ) : null}
        {date || time ? (
          <Typography className={classes.modal__date}>
            {date ? <span>{date.toString()}</span> : null}
            {time ? <span>{time.toString()}</span> : null}
          </Typography>
        ) : null}

        <Box className={classes.modal__actions}>
          <WhiteButton
            component={"a"}
            target={"_blank"}
            href={link.toString()}
            variant="contained"
            endIcon={<ChevronRightIcon />}
            onClick={() => {
              window.dataLayer.push({
                event: "button_click",
                type: "webinar",
                user: !!user ? user.users.email : "Unauthorized",
              });
              handleWebinarPopupClose();
            }}
          >
            {buttonText ? buttonText.toString() : "Join the Webinar"}
          </WhiteButton>
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleChange}
              disableRipple
              sx={{
                color: "#fff",
                "&.Mui-checked": {
                  color: "#fff",
                },
              }}
            />
          }
          className={classes.modal__checkbox}
          label={
            <Typography className={classes.modal__text}>
              Don’t show again
            </Typography>
          }
        />
      </Box>
    </Modal>
  );
}
