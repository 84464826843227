import { Box } from "@mui/material";
import classes from "./index.module.scss";
import { cards } from "../data";
import Card from "../Card";

export default function Cards() {
  return (
    <Box className={classes.wrapper}>
      <Box component={"ul"} className={classes.list}>
        {cards.map(({ title, text, Icon }, index) => (
          <Box component={"li"} key={index} className={classes.list__item}>
            <Card title={title} text={text} Icon={Icon} />
          </Box>
        ))}
      </Box>
    </Box>
  );
}
