import { Container } from "@mui/material";
import Hero from "./Hero";
import DiscordSupport from "./DiscordSupport";
import { LandingLayout } from "../../../components/Layouts";
import classes from "./index.module.scss";
import EngeniousUniversity from "./EngeniousUniversity";
import Mentors from "./Mentors";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMentors } from "./hooks/useMentors";
import { useSpecializations } from "./hooks/useSpecializations";
import Loader from "../../../components/Common/Loader";

export default function Mentorship({ isAuth }) {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const [filters, setFilters] = useState(
    searchParams.get("specializations")?.split(",") || []
  );

  const setDynamicParams = (paramName, value) => {
    searchParams.set(paramName, value);
    const newSearchString = searchParams.toString();
    navigate(
      {
        search: newSearchString,
      },
      { replace: true }
    );
  };

  const deleteDynamicParams = (paramName) => {
    searchParams.delete(paramName);
    const newSearchString = searchParams.toString();
    navigate(
      {
        search: newSearchString,
      },
      { replace: true }
    );
  };

  const { data: mentorsData, isFetching: mentorsIsFetching } = useMentors({
    filters,
  });
  const {
    data: mentorsSpecializations,
    isFetching: mentorsSpecializationsIsFetching,
  } = useSpecializations();

  const toggleFilters = (itemId) => {
    const newFilter = itemId.toString();
    setFilters((prev) =>
      prev.includes(newFilter)
        ? prev.filter((id) => id !== newFilter)
        : [...prev, newFilter]
    );
  };

  useEffect(() => {
    filters && filters.length
      ? setDynamicParams("specializations", filters)
      : deleteDynamicParams("specializations");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <LandingLayout>
      {mentorsIsFetching || mentorsSpecializationsIsFetching ? (
        <Loader />
      ) : null}
      <Container maxWidth={false} disableGutters className={classes.container}>
        <Hero />
        <Mentors
          mentors={mentorsData}
          specializations={mentorsSpecializations}
          toggleFilters={toggleFilters}
          filters={filters}
        />
        <DiscordSupport />
        <EngeniousUniversity />
      </Container>
    </LandingLayout>
  );
}
