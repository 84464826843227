import { useQuery } from "@tanstack/react-query";
import axios from "axios";

async function fetchPosts({ page, perPage, tags = [], id }) {
  const queryParams = new URLSearchParams();

  if (tags && tags.length > 0) {
    queryParams.set("tags", tags?.join(","));
  }

  queryParams.set("page", page || 1);
  queryParams.set("per_page", perPage || 5);
  queryParams.set("exclude", id || "");
  queryParams.set(
    "_fields",
    "id,date,title,img_url,post_tags,featured_media,tags,slug"
  );

  const response = await axios.get(
    `${process.env.REACT_APP_WP_PATH}posts?${queryParams.toString()}`
  );

  const data = { relatedPosts: response.data };
  return data;
}

export function useRelatedPosts({ tags, enabled, id }) {
  return useQuery({
    queryKey: ["posts", "related", tags?.join(",")],
    queryFn: () => fetchPosts({ tags, id }),
    staleTime: Infinity,
    enabled: enabled,
  });
}
