import { Box } from "@mui/material";
import Card from "./Card";
import classes from "./index.module.scss";
import { useDeferredValue } from "react";

export default function List({ posts }) {
  const deferredPosts = useDeferredValue(posts);
  if (!posts || !posts.length) return null;
  return (
    <Box component={"ul"} className={classes.list}>
      {deferredPosts.map((post) => (
        <Box component={"li"} key={post.id}>
          <Card post={post} />
        </Box>
      ))}
    </Box>
  );
}
