import React from "react";

export default function Test() {
    const postMessage = (event) => {
        event.source.postMessage(
          {
            code: new URLSearchParams(window.location.search).get("code"),
          },
          event.origin
        );
      }
  React.useEffect(() => {
    window.addEventListener("message", postMessage);

    return ()=>{
        window.removeEventListener("message", postMessage);
    }
  }, []);
  return null
}
