import { useQuery } from "@tanstack/react-query";
import { api } from "../../../../instances/axiosInstances";

async function fetchStudentRating({ id }) {
  const response = await api.get(`/usersRating/user/${id}`);
  const data = { userRating: response.data };
  return data;
}

export function useStudentRating({ id }) {
  return useQuery({
    queryKey: ["userRating", id],
    queryFn: () => fetchStudentRating({ id }),
    staleTime: Infinity,
    enabled: !!id,
  });
}
