import useModal from "./useModal";
import classes from "./index.module.scss";

export default function Modal({ children, modalRef, className = "" }) {
  const { onBackdropClick } = useModal(modalRef);
  return (
    <dialog
      ref={modalRef}
      className={`${classes.modal} ${className}`.trim()}
      onClick={onBackdropClick}
    >
      {children}
    </dialog>
  );
}
