import { useEffect, useState } from "react";

export default function usePages({ course }) {
  const [pages, setPages] = useState([]);
  const getPagesIds = (modules) => {
    const ids = modules.reduce((acc, module) => {
      module.lectures.forEach(
        (lecture) =>
          (acc = [
            ...acc,
            {
              id: lecture.id,
              isFree: lecture.isFree,
              isComplete: lecture?.userLecture?.isComplete,
              duration: lecture.duration,
              lectureTitle: lecture?.title
            },
          ])
      );
      return acc;
    }, []);

    setPages(ids);
  };
  useEffect(() => {
    course && getPagesIds(course?.data.courses.modules);
  }, [course]);
  return { pages };
}
