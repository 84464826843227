import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";
import HTMLComponent from "../../../../components/Common/HTMLComponent";

export default function Rules({ rules }) {
  if (!rules) return null;
  return (
    <Box className={classes.rules}>
      <Typography
        component={"h2"}
        variant={"h2"}
        className={classes.rules__title}
      >
        {rules.title.rendered}
      </Typography>
      <HTMLComponent html={rules.content.rendered} />
    </Box>
  );
}
