import React from "react";
import { Container } from "@mui/material";
import Loader from "../../../components/Common/Loader";
import { LandingLayout } from "../../../components/Layouts";

import Hero from "./Hero";
import Expertise from "./Expertise/Expertise";
import Webinar from "./Webinar/Webinar";
import LearningPath from "./LearningPath/LearningPath";
import InsidersEdge from "./InsidersEdge";
import OurAlumniAboutUs from "./OurAlumniAboutUs/index";
import YourFuture from "./YourFuture/YourFuture";
import Solution from "./Solution/Solution";
import TechTrajectory from "./TechTrajectory/TechTrajectory";
import Companies from "./Companies/Companies";
import TopStudents from "./TopStudents/TopStudents";
import Resources from "./Resources/Resources";
import Questions from "./Questions/Questions";
import { apiCmsData } from "../../../instances/axiosInstances";
import { useQuery } from "@tanstack/react-query";

const componentMap = {
  Hero,
  Webinar,
  Expertise,
  LearningPath,
  InsidersEdge,
  YourFuture,
  TechTrajectory,
  Solution,
  OurAlumniAboutUs,
  Companies,
  TopStudents,
  Resources,
  Questions,
};

export default function Home() {
  const homeData = useQuery(["homeSections"], () =>
    apiCmsData.get(`/home${process.env.REACT_APP_WP_HOME_QUERY_PARAMS}`)
  );

  if (homeData.isLoading) {
    return <Loader />;
  }

  const sectionData = homeData.isSuccess ? homeData?.data?.data : null;

  const sortedSections =
    sectionData &&
    sectionData.sort((a, b) => {
      return parseInt(a.custom_fields.order) - parseInt(b.custom_fields.order);
    });

  return (
    <LandingLayout>
      <Container maxWidth={false} disableGutters>
        <Hero data={sectionData[0]} />
        {sortedSections
          ? sortedSections.map((section) => {
              const { section_name } = section.custom_fields;
              const SectionComponent = componentMap[section_name];
              if (!SectionComponent) {
                console.error("No component found for section:", section_name);
                return null;
              }
              return <SectionComponent key={section.id} data={section} />;
            })
          : null}
      </Container>
    </LandingLayout>
  );
}
