import { usePost } from "../Posts/hooks/usePost";
import { useParams } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import { LandingLayout } from "../../../../components/Layouts";
import Loader from "../../../../components/Common/Loader";
import BackLink from "../../../../components/Common/BackLink";
import { useEffect } from "react";
import RelatedPosts from "./RelatedPosts";
import classes from "./index.module.scss";
import Anchors from "./Anchors";
import Share from "../../../../components/Common/Share";
import SubscribeForm from "../../../../components/Common/SubscribeForm";
import Discord from "../../../../components/Common/Discord";

export default function Post() {
  const { slug } = useParams();
  const { data, isFetching } = usePost({ slug });

  useEffect(() => {
    const codeBlocks = document.querySelectorAll("pre code");
    codeBlocks.forEach((block) => {
      addCopyButton(block);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <LandingLayout>
      {isFetching ? <Loader /> : null}
      <Container maxWidth={"lg"} className={classes.container}>
        <BackLink
          isLogoVisible={false}
          text={"Back to All Articles"}
          path={"/posts"}
        />

        <Box component={"section"} className={classes.content}>
          <Box className={classes.main}>
            <Box component={"section"} className={classes.header}>
              <Typography
                variant={"h1"}
                component={"h1"}
                className={classes.title}
              >
                {data?.post[0].title.rendered}
              </Typography>
              <Typography className={classes.date}>
                {new Date(data?.post[0].date).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
                {data?.post[0].post_tags.length ? (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="4"
                      height="5"
                      viewBox="0 0 4 5"
                      fill="none"
                    >
                      <circle
                        cx="2"
                        cy="2.5"
                        r="2"
                        fill="#141416"
                        fillOpacity="0.4"
                      />
                    </svg>
                    {data?.post[0].post_tags
                      .reduce((acc, { name }) => [...acc, name], [])
                      .join(", ")}
                  </>
                ) : null}
              </Typography>
              <Typography className={classes.author}>
                Author: <span>{data?.post[0].author_name}</span>
              </Typography>
            </Box>
            <Box
              component={"section"}
              className={classes.post}
              dangerouslySetInnerHTML={{
                __html: data?.post[0].content.rendered,
              }}
            />
            <Share />
          </Box>
          <Box component={"aside"} className={classes.sidebar}>
            <Box className={classes.posts}>
              <Anchors post={data} />
            </Box>
            <Box className={classes.posts}>
              <RelatedPosts tags={data?.post[0].tags} id={data?.post[0].id} />
            </Box>
            <Discord />
            <SubscribeForm />
          </Box>
        </Box>
      </Container>
    </LandingLayout>
  );
}

function handleCopyClick(textToCopy) {
  const textArea = document.createElement("textarea");
  textArea.value = textToCopy;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand("copy");
  document.body.removeChild(textArea);
}

function addCopyButton(codeBlock) {
  if (codeBlock.parentNode.querySelector(".html-code-copy-button") === null) {
    const button = document.createElement("button");

    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute("width", "24");
    svg.setAttribute("height", "24");
    svg.setAttribute("viewBox", "0 0 24 24");
    svg.setAttribute("fill", "none");

    const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
    path.setAttribute(
      "d",
      "M7.5 3H14.6C16.8402 3 17.9603 3 18.816 3.43597C19.5686 3.81947 20.1805 4.43139 20.564 5.18404C21 6.03969 21 7.15979 21 9.4V16.5M6.2 21H14.3C15.4201 21 15.9802 21 16.408 20.782C16.7843 20.5903 17.0903 20.2843 17.282 19.908C17.5 19.4802 17.5 18.9201 17.5 17.8V9.7C17.5 8.57989 17.5 8.01984 17.282 7.59202C17.0903 7.21569 16.7843 6.90973 16.408 6.71799C15.9802 6.5 15.4201 6.5 14.3 6.5H6.2C5.0799 6.5 4.51984 6.5 4.09202 6.71799C3.71569 6.90973 3.40973 7.21569 3.21799 7.59202C3 8.01984 3 8.57989 3 9.7V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.0799 21 6.2 21Z"
    );

    path.setAttribute("stroke", "#3D40D5");
    path.setAttribute("stroke-width", "2");
    path.setAttribute("stroke-linecap", "round");
    path.setAttribute("stroke-linejoin", "round");

    svg.appendChild(path);

    button.appendChild(svg);
    button.classList.add("html-code-copy-button");
    button.addEventListener("click", () => {
      handleCopyClick(codeBlock.textContent);
    });
    codeBlock.parentElement.appendChild(button);
  }
}
