import React from "react";

export function formatDate(date, displayTimeZone = false, displayTime = false) {
  if (!date) {
    return null;
  }

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "America/Los_Angeles",
  };

  if (displayTime) {
    options.hour = "numeric";
    options.minute = "numeric";
  }

  if (displayTimeZone) {
    options.timeZoneName = "short";
  }

  let formattedDate = new Date(date).toLocaleString("en-US", options);

  if (displayTimeZone) {
    const timeZoneAbbreviation = formattedDate.match(/\b(PDT|PST)\b/);
    if (timeZoneAbbreviation) {
      formattedDate = formattedDate.replace(
        ` ${timeZoneAbbreviation[0]}`,
        ` (${timeZoneAbbreviation[0]})`
      );
    }
  }

  return formattedDate;
}



export function getTagNames(tags, separator = ", ") {
  if (!tags || tags.length === 0) {
    return null;
  }

  const formattedTags = tags.map((tag, index) => (
    <React.Fragment key={index}>
      {tag.name}
      {index < tags.length - 1 && separator}
    </React.Fragment>
  ));

  return formattedTags;
}
