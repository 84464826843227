import { Box } from "@mui/material";
import RelatedMaterials from "../RelatedMaterials";
import Recorded from "./Recorded";
import Live from "./Live";
import classes from "./index.module.scss";
import BackLink from "../../../../components/Common/BackLink";

export default function Details({ data, playlist, feedbacks }) {

  return (
    <Box className={classes.container}>
      <Box className={classes.link}>
        <BackLink text="Back to All Courses" path={"/courses"} />
      </Box>
      {data?.type === "recorded" ? (
        <Recorded data={data} feedbacks={feedbacks} />
      ) : null}
      {data?.type === "live" ? (
        <Live data={data} feedbacks={feedbacks} />
      ) : null}
      {playlist ? <RelatedMaterials playlist={playlist} /> : null}
    </Box>
  );
}
