import { Box, Typography } from "@mui/material";
import { WhiteButton } from "../../../../../components/MUI/buttons/button";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import image from "../images/hero.png";

import classes from "./index.module.scss";

export default function Intro() {
  return (
    <Box className={classes.body} component="section">
      <Box className={classes.content}>
        <Typography className={classes.title} component={"h1"} variant={"h1"}>
          Personalized Tech Mentorship
        </Typography>
        <Typography
          className={classes.subtitle}
          component={"h2"}
          variant={"h2"}
        >
          Need a helping hand? Mentors are there for you!
        </Typography>
        <Box component={"ul"} className={classes.list}>
          <Typography className={classes.list__item} component={"li"}>
            We know how to stay ahead of layoffs
          </Typography>
          <Typography className={classes.list__item} component={"li"}>
            18+ years of experience in IT consulting combined
          </Typography>
          <Typography className={classes.list__item} component={"li"}>
            First 30-minutes FREE to define your goals
          </Typography>
        </Box>
        <Box className={classes.actions}>
          <WhiteButton
            className={classes.actions__start}
            endIcon={<ChevronRightIcon />}
            variant={"contained"}
            component={"a"}
            href="https://calendly.com/mentors-engenious"
            target={"_blank"}
          >
            First 30m session FREE
          </WhiteButton>
        </Box>
      </Box>
      <Box className={classes.image}>
        <Box className={classes.image__wrapper}>
          <img src={image} alt="Robot" width={684} />
        </Box>
      </Box>
    </Box>
  );
}
