import { ReactComponent as Icon1 } from "./images/icon-1.svg";
import { ReactComponent as Icon2 } from "./images/icon-2.svg";
import { ReactComponent as Icon3 } from "./images/icon-3.svg";

export const cards = [
  {
    title: "Stuck in work/studying?",
    text: "Book first 30-minute session FREE - our mentors will help identify your needs.",
    Icon: Icon1,
  },
  {
    title: "Define action plan",
    text: "Our experts will build up a career/development plan and guide you on 1-hour mentoring sessions further on.",
    Icon: Icon2,
  },
  {
    title: "Get results",
    text: "With the guidance of our experts your skills will develop and career will boost in weeks instead of months.",
    Icon: Icon3,
  },
];
