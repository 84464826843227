import { useQuery } from "@tanstack/react-query";
import axios from "axios";

async function fetchPosts({ slug }) {
  const response = await axios.get(
    `${process.env.REACT_APP_WP_PATH}posts?slug=${slug}&_fields=id,date,title,content,author_name,author,post_tags,
tags`
  );
  const data = { post: response.data };
  return data;
}

export function usePost({ slug }) {
  return useQuery({
    queryKey: ["post", slug],
    queryFn: () => fetchPosts({ slug }),
    staleTime: Infinity,
  });
}
