import { Box } from "@mui/material";
import Card from "./Card";
import SectionTitle from "../components/SectionTitle";
import SectionGrid from "../components/SectionGrid";
import classes from "./index.module.scss";

export default function Instructors({ instructors }) {
  if (!instructors || !instructors.length) {
    return null;
  }
  return (
    <SectionGrid>
      <SectionTitle>Instructors</SectionTitle>
      <Box className={classes.content}>
        {instructors.map((instructor) => {
          return <Card key={instructor.id} instructor={instructor} />;
        })}
      </Box>
    </SectionGrid>
  );
}
