import { useTheme } from "@emotion/react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import React from "react";

export default function ShowPasswordIcon({ showPassword }) {
  const theme = useTheme();
  return showPassword ? (
    <VisibilityOff sx={{ color: theme.palette.accent }} />
  ) : (
    <Visibility sx={{ color: theme.palette.accent }} />
  );
}
