import { Box } from "@mui/material";
import { Checkbox as MUICheckbox, FormControlLabel } from "@mui/material";
import { ReactComponent as CheckboxCheckedIcon } from "../../../../assets/icons/checkbox-checked.svg";

import classes from "./index.module.scss";

export default function Checkbox({ Controller, control, errors, name }) {
  return (
    <Box className={classes.wrapper}>
      <FormControlLabel
        className={classes.checkbox}
        control={
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <MUICheckbox
                checkedIcon={<CheckboxCheckedIcon />}
                disableRipple
                {...field}
              />
            )}
          />
        }
        label="You would like to give us a video feedback for a promocode for the next course."
      />
      {errors[name] && (
        <span className={classes.error}>{errors[name].message}</span>
      )}
    </Box>
  );
}
