import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";

export default function Header({ question }) {
  return (
    <Box className={classes.header}>
      <Typography component="h3" variant="h3" className={classes.header__title}>
        {question}
      </Typography>
    </Box>
  );
}
