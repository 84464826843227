import { Box } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { CarouselButton } from "../../MUI/buttons/button";
import useMixpanel from "../../../hooks/useMixpanel";

import classes from "./index.module.scss";

export default function CarouselNavigation({ splideRef, variant, mixpanelLeftData = null, mixpanelRightData = null, position = 'horizontal'  }) {
  const { handleEventTrack } = useMixpanel();
  const handlePrev = () => {
    if (splideRef.current) {
      splideRef.current.go("-1");
    }
  };

  const handleNext = () => {
    if (splideRef.current) {
      splideRef.current.go("+1");
    }
  };

  const onClickPrev = mixpanelLeftData
    ? (e) => {
      handlePrev();
      handleEventTrack(e, mixpanelLeftData);
    }
    : handlePrev;

  const onClickNext = mixpanelRightData
    ? (e) => {
      handleNext();
      handleEventTrack(e, mixpanelRightData);
    }
    : handleNext;

    
  const verticalPosStyles = position === 'vertical' && classes.vertical;

  return (
    <Box className={`${classes.navigation} ${verticalPosStyles}`}>
      <Box className={`${classes.button} ${verticalPosStyles}`}>
        <CarouselButton variant={variant} onClick={onClickPrev}>
          <ChevronLeftIcon />
        </CarouselButton>
      </Box>
      <Box className={`${classes.button} ${verticalPosStyles}`}>
        <CarouselButton variant={variant} onClick={onClickNext}>
          <ChevronRightIcon />
        </CarouselButton>
      </Box>
    </Box>
  );
}
