import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import { v4 as uuid } from "uuid";

import "swiper/css";

import classes from "./index.module.scss";
import Slide from "./Slide";

SwiperCore.use([Autoplay]);

export default function Carousel({ sliderRef, slides, showModal }) {
  return (
    <Swiper
      slidesPerView={"auto"}
      spaceBetween={16}
      ref={sliderRef}
      className={classes.swiper}
      loop={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
    >
      {[...slides, ...slides]?.map((slide) => {
        return (
          <SwiperSlide className={classes.slide} key={uuid()}>
            <Slide
              photoUrl={slide.photoUrl}
              name={slide.name}
              linkedinLink={slide.linkedinLink}
              title={slide.videoTitle}
              url={slide.videoUrl}
              showModal={showModal}
              description={slide.videoTitle}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
