import { Typography } from "@mui/material";
import classes from "./index.module.scss";

export default function SectionTitle({children}) {
  return (
    <Typography component="h2" variant="h2" className={classes.title}>
      {children}
    </Typography>
  );
}
