import React from "react";
import classes from "./index.module.scss";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function CardSubtitle({ paths }) {
  if (!paths) return null;
  return (
    <Typography className={classes.subtitle}>
      Part of{" "}
      {paths
        .filter((path) => !!path)
        .map((path, index, self) => (
          <React.Fragment key={path.id}>
            <Link to={`/paths/${path.id}`}>{path.title} Path</Link>
            {index + 1 !== self.length ? `, ` : ""}
          </React.Fragment>
        ))}
    </Typography>
  );
}
