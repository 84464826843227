import ShowMore from "../../../../components/Common/ShowMore";
import SectionTitle from "../components/SectionTitle";
import SectionText from "../components/SectionText";
import SectionGrid from "../components/SectionGrid";
import { Box } from "@mui/material";
import classes from "./index.module.scss";
import HTMLComponent from "../../../../components/Common/HTMLComponent";

export default function Description({ description, className = "" }) {
  if (!description || !description.length) {
    return null;
  }
  return (
    <SectionGrid className={className}>
      <SectionTitle>Description</SectionTitle>

      <ShowMore maxHeight={150}>
        {description.toString().includes("</") ? (
          <HTMLComponent html={description} />
        ) : (
          <Box component={"ul"} className={classes.list}>
            {description?.map((text, index) => (
              <SectionText component={"li"} key={index}>
                {text}
              </SectionText>
            ))}
          </Box>
        )}
      </ShowMore>
    </SectionGrid>
  );
}
