import { useParams } from "react-router-dom";
import { useCertificate } from "../hooks/useCertificate";
import classes from "./index.module.scss";
import { LandingLayout, PlatformLayout } from "../../../../components/Layouts";
import { Container } from "@mui/material";
import Details from "./Details";
import Loader from "../../../../components/Common/Loader";
import { useCourse } from "../hooks/useCourse";

export default function CertificateDetails({ isAuth }) {
  const { certificateId, userId } = useParams();
  const { data: certificate, isLoading: isCertificateLoading } = useCertificate(
    {
      isAuth,
      certificateId,
    }
  );
  const courseId = certificate?.certificate.usersRating.course.id;
  const { data: course, isLoading: isCourseLoading } = useCourse({
    isAuth,
    courseId,
  });

  return (
    <>
      {isCertificateLoading || isCourseLoading ? <Loader /> : null}
      {isAuth ? (
        <PlatformLayout>
          <Details
            isAuth={isAuth}
            userId={userId}
            certificate={certificate}
            course={course}
          />
        </PlatformLayout>
      ) : (
        <LandingLayout>
          <Container
            className={classes.wrapper}
            maxWidth={false}
            disableGutters
          >
            <Container className={classes.container} maxWidth="lg">
              <Details
                isAuth={isAuth}
                userId={userId}
                certificate={certificate}
                course={course}
              />
            </Container>
          </Container>
        </LandingLayout>
      )}
    </>
  );
}
