import { useQuery } from "@tanstack/react-query";
import { api } from "../../../../instances/axiosInstances";

async function fetchMentor({ id, isAuth }) {
  const response = await api.get(
    isAuth ? `/mentors/platform/${id}` : `/mentors/platform/${id}/unregistered`
  );
  const data = { mentor: response.data };
  return data;
}

export function useMentor({ id, isAuth }) {
  return useQuery({
    queryKey: isAuth ? ["mentor", id] : ["mentor", "unregistered", id],
    queryFn: () => fetchMentor({ id, isAuth }),
    staleTime: Infinity,
  });
}
