import * as React from "react";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import { api } from "../../../../instances/axiosInstances";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import errorToast from "../../../toasts/errorToast";
import successToast from "../../../toasts/successToast";
import { QuestionnairePopupContext } from "../../../../context/QuestionnairePopupContext";
import useMixpanel from "../../../../hooks/useMixpanel";
import { mixpanelPayment } from "../../../../constants/mixpanel";

const SquarePaymentForm = ({
  handleClose,
  price,
  courseId,
  discountedPrice,
  title,
}) => {
  const queryClient = useQueryClient();

  const user = queryClient.getQueryData(["user"])?.data;
  const { handleOpen: handleQuestionnairePopupOpen } = React.useContext(
    QuestionnairePopupContext
  );
  const { handleEventTrack } = useMixpanel();
  const { purchaseCourse } = mixpanelPayment;

  const { mutate, isLoading } = useMutation(
    (data) => api.post(process.env.REACT_APP_SQUARE_PAYMENT_PATH, data),
    {
      onSuccess: async ({ data }) => {
        handleEventTrack(null, {
          ...purchaseCourse,
          options: {
            ...purchaseCourse.options,
            name: `Course by $${discountedPrice}`,
            course: title,
            courseId: courseId,
          },
        });
        window.dataLayer.push({
          event: "purchase",
          ecommerce: {
            transaction_id: data.transactions.id.toString(),
            value: Number(price),
            currency: data.transactions.currency,
            items: [
              {
                item_name: title,
                item_id: courseId.toString(),
                price: discountedPrice
                  ? discountedPrice.toString()
                  : price
                  ? price.toString()
                  : "0",
                discount: discountedPrice
                  ? (price - discountedPrice).toString()
                  : "0",
                quantity: "1",
              },
            ],
          },
        });
        successToast("You have full access to course content");

        await queryClient.invalidateQueries({
          queryKey: ["course", "content", "registered", courseId.toString()],
          refetchType: "all",
          exact: true,
        });

        await queryClient.invalidateQueries({
          queryKey: ["course", "registered", courseId.toString()],
          refetchType: "all",
          exact: true,
        });

        await queryClient.invalidateQueries({
          queryKey: ["lecture", "registered"],
          refetchType: "all",
          exact: true,
        });

        await queryClient.invalidateQueries({
          queryKey: ["my-courses", "registered"],
          refetchType: "all",
          exact: true,
        });

        await queryClient.invalidateQueries({
          queryKey: ["all-courses", "registered"],
          refetchType: "all",
          exact: true,
        });

        await queryClient.invalidateQueries({
          queryKey: ["landing", "registered"],
          refetchType: "all",
          exact: true,
        });

        await handleClose();
        await handleQuestionnairePopupOpen(user?.users);

        await queryClient.invalidateQueries({
          queryKey: ["user"],
          refetchType: "all",
          exact: true,
        });

        await queryClient.invalidateQueries({
          queryKey: ["paths", "registered"],
          refetchType: "all",
          exact: true,
        });

        await queryClient.invalidateQueries({
          queryKey: ["path", "registered"],
          refetchType: "all",
        });
      },
      onError: (error) => {
        console.error(error);
        errorToast("Something went wrong. Try again.");
      },
    }
  );
  return (
    <PaymentForm
      autoBuild={false}
      applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID}
      locationId={process.env.REACT_APP_SQUARE_LOCATION_ID}
      cardTokenizeResponseReceived={async (token) => {
        let body = JSON.stringify({
          sourceId: token.token,
          courseId: courseId,
        });

        mutate(body);
      }}
    >
      <CreditCard
        buttonProps={{
          isLoading: isLoading,
        }}
      />
    </PaymentForm>
  );
};

export default SquarePaymentForm;
