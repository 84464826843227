import { useQuery } from "@tanstack/react-query";
import { api } from "../../../../instances/axiosInstances";

async function fetchCourse({ courseId, isAuth }) {  const response = await api.get(
    `/courses${"/" + courseId}/short${isAuth ? "" : "/unregistered"}`
  );

  const data = { course: response.data };
  return data;
}

export function useCourse({ courseId, isAuth }) {
  return useQuery({
    queryKey: isAuth
      ? ["userCertificate", "course", "registered", courseId]
      : ["userCertificate", "course", "unregistered", courseId],
    queryFn: () => fetchCourse({ courseId, isAuth }),
    staleTime: Infinity,
    enabled: !!courseId,
  });
}
