import { Box, Typography } from "@mui/material";
import { ReactComponent as DiscordIcon } from "../../../assets/images/discord-blue.svg";
import discordSmall from "../../../assets/icons/discord-small.png";
import { WhiteButton } from '../../MUI/buttons/button';
import classes from "./index.module.scss";

export default function Discord() {
  return (
    <Box className={classes.card}>
      <Box className={classes.card__header}>
        <Typography
          component={"h3"}
          variant={"h3"}
          className={classes.card__title}
        >
          👋 24/7 Discord Support
        </Typography>
        <Box className={classes.card__image}>
          <img src={discordSmall} alt="Discord" width={87} />
        </Box>
      </Box>
      <WhiteButton
        fullWidth
        isBig={false}
        variant="contained"
        component={"a"}
        startIcon={<DiscordIcon />}
        href={process.env.REACT_APP_DISCORD_LINK}
        target="_blank"
        rel="noreferrer"
      >
        Join our Discord
      </WhiteButton>
    </Box>
  );
}
