import * as React from "react";
import { Box, Rating as MUIRating, Typography } from "@mui/material";
import classes from "./index.module.scss";

export default function Rating({ options, ...stack }) {
  const {
    rating,
    votes,
    name = "half-rating-read",
    stars,
    precision = 0.1,
    ...rest
  } = options;
  return (
    <Box className={classes.rating} {...stack}>
      {rating ? (
        <Typography className={classes.count}>{rating.toFixed(1)}</Typography>
      ) : null}
      <MUIRating
        emptyIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M14.6313 6.11934C14.5894 5.99592 14.5122 5.88747 14.4094 5.80738C14.3066 5.72729 14.1825 5.67908 14.0526 5.66868L10.2519 5.36668L8.60727 1.72601C8.55489 1.60875 8.4697 1.50916 8.36197 1.43925C8.25424 1.36934 8.12858 1.3321 8.00015 1.33203C7.87173 1.33196 7.74603 1.36906 7.63822 1.43885C7.53041 1.50864 7.44511 1.60814 7.3926 1.72534L5.74794 5.36668L1.94727 5.66868C1.81957 5.67879 1.69749 5.72548 1.59564 5.80316C1.49378 5.88084 1.41646 5.98622 1.37293 6.1067C1.3294 6.22717 1.32149 6.35763 1.35017 6.48248C1.37884 6.60733 1.44287 6.72127 1.5346 6.81068L4.34327 9.54868L3.34994 13.85C3.31977 13.9802 3.32944 14.1165 3.37768 14.2411C3.42592 14.3657 3.51051 14.473 3.62047 14.549C3.73043 14.6249 3.86068 14.6661 3.99433 14.6671C4.12797 14.6681 4.25883 14.629 4.36994 14.5547L7.99994 12.1347L11.6299 14.5547C11.7435 14.6301 11.8774 14.6689 12.0137 14.6659C12.15 14.6629 12.2821 14.6183 12.3922 14.538C12.5023 14.4577 12.5853 14.3456 12.6298 14.2167C12.6743 14.0879 12.6783 13.9485 12.6413 13.8173L11.4219 9.55068L14.4459 6.82934C14.6439 6.65068 14.7166 6.37201 14.6313 6.11934Z"
              fill="#F6C344"
              fillOpacity="0.4"
            />
          </svg>
        }
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M14.6313 6.11934C14.5894 5.99592 14.5122 5.88747 14.4094 5.80738C14.3066 5.72729 14.1825 5.67908 14.0526 5.66868L10.2519 5.36668L8.60727 1.72601C8.55489 1.60875 8.4697 1.50916 8.36197 1.43925C8.25424 1.36934 8.12858 1.3321 8.00015 1.33203C7.87173 1.33196 7.74603 1.36906 7.63822 1.43885C7.53041 1.50864 7.44511 1.60814 7.3926 1.72534L5.74794 5.36668L1.94727 5.66868C1.81957 5.67879 1.69749 5.72548 1.59564 5.80316C1.49378 5.88084 1.41646 5.98622 1.37293 6.1067C1.3294 6.22717 1.32149 6.35763 1.35017 6.48248C1.37884 6.60733 1.44287 6.72127 1.5346 6.81068L4.34327 9.54868L3.34994 13.85C3.31977 13.9802 3.32944 14.1165 3.37768 14.2411C3.42592 14.3657 3.51051 14.473 3.62047 14.549C3.73043 14.6249 3.86068 14.6661 3.99433 14.6671C4.12797 14.6681 4.25883 14.629 4.36994 14.5547L7.99994 12.1347L11.6299 14.5547C11.7435 14.6301 11.8774 14.6689 12.0137 14.6659C12.15 14.6629 12.2821 14.6183 12.3922 14.538C12.5023 14.4577 12.5853 14.3456 12.6298 14.2167C12.6743 14.0879 12.6783 13.9485 12.6413 13.8173L11.4219 9.55068L14.4459 6.82934C14.6439 6.65068 14.7166 6.37201 14.6313 6.11934Z"
              fill="#F6C344"
            />
          </svg>
        }
        name={name}
        value={stars}
        precision={precision}
        {...rest}
      />
      {votes ? (
        <Typography className={classes.votes}>({votes})</Typography>
      ) : null}
    </Box>
  );
}
