import { Box, Typography } from "@mui/material";
import Progress from "./Progress";
import classes from "./index.module.scss";
import HTMLComponent from "../../../components/Common/HTMLComponent";

export default function Card({
  title,
  img,
  description,
  finished,
  all,
  isPaid,
}) {
  return (
    <Box className={classes.card}>
      <Box className={classes.card__content}>
        <Typography
          component={"h1"}
          variant={"h1"}
          className={classes.card__title}
        >
          {title}
        </Typography>
        <HTMLComponent
          html={description}
          fontSize={"regular"}
          textColor={"dark-060"}
        />

        {isPaid ? <Progress finished={finished} all={all} /> : null}
      </Box>
      <Box className={classes.card__image}>
        <img src={img} alt={title} width={512} />
      </Box>
    </Box>
  );
}
