import { useSyncExternalStore } from "react";

function createStore(initialState) {
  let currentState = initialState;
  const listeners = new Set();
  return {
    getState: () => currentState,
    setState: (newState) => {
      currentState = newState;
      listeners.forEach((listener) => listener(currentState));
    },
    subscribe: (listener) => {
      listeners.add(listener);
      return () => listeners.delete(listener);
    },
  };
}

const store = createStore({});

export const useStore = (selector = (state) => state) =>
  useSyncExternalStore(store.subscribe, () => selector(store.getState()));

export const updateStore = ({ key, itemsCount, defaultValue, step }) => {
  const state = store.getState();
  store.setState({
    ...state,
    [key]: itemsCount ? itemsCount + step : defaultValue,
  });
};

export default store;
