import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";
import {
  AccentButton,
  WhiteButton,
} from "../../../../../components/MUI/buttons/button";
import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";
import useModal from "../../../../../components/Common/Modal/useModal";
import Modal from "../../../../../components/Common/Modal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "../../../../../instances/axiosInstances";
import errorToast from "../../../../../components/toasts/errorToast";
import CourseReview from "../../../../../components/Common/CourseReview";

export default function LiveEnded({
  isPaid,
  isAuth,
  isFree,
  pathname,
  isComplete,
  courseId,
  isFeedbackComplete,
  certificate,
  completeDate,
  instructors,
}) {
  const queryClient = useQueryClient();
  const modalRef = useRef();
  const { handleOpen, handleClose } = useModal(modalRef);

  const user = queryClient.getQueryData(["user"]);
  const userId = user?.data.users.id;

  const scrollToBlock = (event) => {
    event && event.preventDefault();
    const targetElement = document.getElementById(`syllabus`);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
      localStorage.removeItem("anchor");
    }
  };

  const {
    mutate: finishCourseMutation,
    isLoading: isFinishCourseMutationLoading,
  } = useMutation(() => api.post(`/courses/${courseId}/live/finish`), {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["course", "registered", courseId.toString()],
        refetchType: "all",
        exact: true,
      });
       await queryClient.invalidateQueries({
          queryKey: ["user"],
          refetchType: "all",
          exact: true,
        });
        await queryClient.invalidateQueries({
          queryKey: ["usersRating", "registered", "general"],
          refetchType: "all",
          exact: true,
        });
        await queryClient.invalidateQueries({
          queryKey: ["userRating", userId],
          refetchType: "all",
          exact: true,
        });
      handleOpen();
    },
    onError: (error) => {
      errorToast(error.response.data.message);
    },
  });

  useEffect(() => {
    const anchor = localStorage.getItem("anchor");
    anchor && scrollToBlock();

    return () => {
      handleClose();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isComplete) return null;

  return (
    <>
      <Modal modalRef={modalRef}>
        <CourseReview
          isFeedbackComplete={isFeedbackComplete}
          userId={userId}
          courseId={courseId}
          instructors={instructors}
          handleClose={handleClose}
        />
      </Modal>

      <Box className={classes.wrapper}>
        {isPaid ? (
          <>
            <Typography className={classes.title}>
              🤩 Congratulations, you have completed the course
            </Typography>
            <Typography className={classes.text}>
              You will still have access to the lectures.
            </Typography>
          </>
        ) : (
          <>
            <Typography className={classes.title}>
              😭 Sorry, the course is over
            </Typography>
            <Typography className={classes.text}>
              {isFree
                ? "But don't worry, you can watch the lectures in the recording."
                : "But soon the course will be available again."}
            </Typography>
          </>
        )}

        {(isPaid || isFree) && isAuth ? (
          <Box className={classes.actions}>
            {isPaid ? (
              !certificate ? (
                <AccentButton
                  className={classes.button}
                  isBig={false}
                  variant="contained"
                  onClick={() =>
                    completeDate ? handleOpen() : finishCourseMutation()
                  }
                  disabled={isFinishCourseMutationLoading}
                >
                  Generate certificate
                </AccentButton>
              ) : (
                <AccentButton
                  className={classes.button}
                  isBig={false}
                  variant="contained"
                  component={Link}
                  to={`/rating/${userId}/certificate/${certificate.id}`}
                >
                  Go to certificate
                </AccentButton>
              )
            ) : null}
            {certificate || isPaid ? (
              <WhiteButton
                className={classes.button}
                isBig={false}
                variant="outlined"
                component={"a"}
                href={"#syllabus"}
                onClick={scrollToBlock}
              >
                Watch in recording
              </WhiteButton>
            ) : (
              <AccentButton
                className={classes.button}
                isBig={false}
                variant="outlined"
                component={"a"}
                href={"#syllabus"}
                onClick={scrollToBlock}
              >
                Watch in recording
              </AccentButton>
            )}
          </Box>
        ) : null}
        {isFree && !isAuth ? (
          <Box className={classes.actions}>
            <AccentButton
              className={classes.button}
              isBig={false}
              variant="contained"
              component={Link}
              to={"/registration"}
              onClick={() => {
                localStorage.setItem("redirectPath", pathname);
                localStorage.setItem("anchor", "syllabus");
              }}
            >
              Watch in recording
            </AccentButton>
          </Box>
        ) : null}
      </Box>
    </>
  );
}
