import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: "var(--proxima) ",
    h1: {
      fontFamily: "var(--biotif) ",
      fontWeight: 700,
    },
    h2: {
      fontFamily: "var(--biotif) ",
    },
    h3: {
      fontFamily: "var(--biotif) ",
    },
    h4: {
      fontFamily: "var(--biotif) ",
    },
  },
  palette: {
    accent: "#3D40D5",
    "accent-06": "rgba(61, 64, 213, 0.6)",
    accentLight: "#eaeffe",
    yellow: "#F6C344",
    black: "#000000",
    white: "#FFFFFf",
    "white-10": "rgba(255, 255, 255, 0.1)",
    "white-20": "rgba(255, 255, 255, 0.2)",
    "white-60": "rgba(255, 255, 255, 0.6)",
    "white-70": "rgba(255, 255, 255, 0.7)",
    "white-90": "rgba(255, 255, 255, 0.9)",
    dark: "#141416",
    "dark-05": "rgba(20, 20, 22, 0.05)",
    "dark-10": "rgba(20, 20, 22, 0.10)",
    "dark-20": "rgba(20, 20, 22, 0.2)",
    "dark-40": "rgba(20, 20, 22, 0.4)",
    "dark-60": "rgba(20, 20, 22, 0.6)",
    "dark-70": "rgba(20, 20, 22, 0.7)",
    "dark-90": "rgba(20, 20, 22, 0.9)",
    danger: "#FF3D2E",
    primary: {
      main: "#3D40D5",
      dark: "#3D40D5",
      contrastText: "#fff",
    },
    warning: {
      main: "#F6C344",
      dark: "#F6C344",
      contrastText: "#fff",
    },
    secondary: {
      main: "#fff",
      dark: "#fff",
      contrastText: "#3D40D5",
    },
    neutral: {
      main: "#eaeffe",
      dark: "#eaeffe",
      contrastText: "#3D40D5",
    },
    "neutral-dark": {
      main: "rgba(61, 64, 213, 0.1)",
      dark: "rgba(61, 64, 213, 0.1)",
      contrastText: "#3D40D5",
    },
    "dark-btn-20": {
      main: "rgba(20, 20, 22, 0.2)",
      dark: "rgba(20, 20, 22, 0.2)",
      contrastText: "#ffffff",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      "sm-440": 440,
      "sm-560": 560,
      "sm-640": 640,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1440,
      "courses-container": 1168,
    },
  },

  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(255, 255, 255, 0.57)",
          backdropFilter: "blur(5px)",
          webkitBackdropFilter: "blur(5px)",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: "var(--proxima)",
          color: "var(--on-surface-b-100)",
          lineHeight: 1.2,
          letterSpacing: "initial",
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale",
        },
      },
    },
  },
});
