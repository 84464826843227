import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";

import classes from "./index.module.scss";
import { Box, Typography } from "@mui/material";
import successToast from "../../toasts/successToast";

export default function Share() {
  const location = window.location.href;

  const handleCopy = () => {
    if (location) {
      const textarea = document.createElement("textarea");
      textarea.value = location;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      successToast("Link successfully copied to clipboard");
    }
  };

  return (
    <Box className={classes.wrapper}>
      <Typography variant={"h3"} component={"h3"} className={classes.title}>
        Share on:
      </Typography>
      <Box className={classes.buttons}>
        <FacebookShareButton url={location} quote={"Engenious University"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <rect width="32" height="32" rx="16" fill="#1877F2" />
            <path
              d="M22.2281 20.625L22.9375 16H18.5V13C18.5 11.7344 19.1188 10.5 21.1063 10.5H23.125V6.5625C23.125 6.5625 21.2938 6.25 19.5438 6.25C15.8875 6.25 13.5 8.46563 13.5 12.475V16H9.4375V20.625H13.5V31.8062C14.3156 31.9344 15.15 32 16 32C16.85 32 17.6844 31.9344 18.5 31.8062V20.625H22.2281Z"
              fill="white"
            />
          </svg>
        </FacebookShareButton>

        <TwitterShareButton url={location} title={"Engenious University"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <rect width="32" height="32" rx="16" fill="black" />
            <path
              d="M17.3152 14.7749L23.142 8H21.7613L16.7018 13.8826L12.6608 8H8L14.1108 16.8955L8 24H9.38086L14.7238 17.7878L18.9914 24H23.6522L17.3148 14.7749H17.3152ZM15.4239 16.9738L14.8047 16.0881L9.8784 9.03974H11.9993L15.9749 14.728L16.5941 15.6137L21.7619 23.0075H19.641L15.4239 16.9742V16.9738Z"
              fill="white"
            />
          </svg>
        </TwitterShareButton>

        <LinkedinShareButton url={location} title={"Engenious University"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <rect width="32" height="32" rx="16" fill="#0A66C2" />
            <path
              d="M23.501 7.19995H8.49942C8.15484 7.19995 7.82438 7.33683 7.58073 7.58048C7.33708 7.82414 7.2002 8.1546 7.2002 8.49917V23.5007C7.2002 23.8453 7.33708 24.1758 7.58073 24.4194C7.82438 24.6631 8.15484 24.8 8.49942 24.8H23.501C23.8455 24.8 24.176 24.6631 24.4197 24.4194C24.6633 24.1758 24.8002 23.8453 24.8002 23.5007V8.49917C24.8002 8.1546 24.6633 7.82414 24.4197 7.58048C24.176 7.33683 23.8455 7.19995 23.501 7.19995ZM12.446 22.193H9.79986V13.7877H12.446V22.193ZM11.1211 12.623C10.8209 12.6213 10.528 12.5307 10.2793 12.3627C10.0305 12.1947 9.83714 11.9567 9.72351 11.6789C9.60987 11.4011 9.58107 11.0959 9.64075 10.8017C9.70043 10.5075 9.84592 10.2376 10.0588 10.0261C10.2718 9.81449 10.5426 9.67075 10.8371 9.61296C11.1317 9.55516 11.4367 9.58592 11.7138 9.70134C11.9909 9.81676 12.2276 10.0117 12.394 10.2615C12.5604 10.5113 12.6491 10.8048 12.6489 11.105C12.6517 11.3059 12.614 11.5054 12.5381 11.6915C12.4622 11.8776 12.3496 12.0465 12.2071 12.1881C12.0645 12.3298 11.8949 12.4413 11.7083 12.516C11.5217 12.5907 11.322 12.6271 11.1211 12.623ZM22.1993 22.2003H19.5544V17.6084C19.5544 16.2542 18.9788 15.8362 18.2356 15.8362C17.451 15.8362 16.681 16.4277 16.681 17.6426V22.2003H14.0349V13.7938H16.5795V14.9586H16.6138C16.8692 14.4416 17.7639 13.558 19.1291 13.558C20.6055 13.558 22.2005 14.4343 22.2005 17.001L22.1993 22.2003Z"
              fill="white"
            />
          </svg>{" "}
        </LinkedinShareButton>
        <button className={classes.copy} onClick={handleCopy}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M3.29972 9.48411C3.53305 8.25911 4.68305 7.41744 5.93305 7.41744H8.37472C8.80805 7.41744 9.16639 7.05911 9.16639 6.62577C9.16639 6.19244 8.80805 5.83411 8.37472 5.83411H6.01639C3.84139 5.83411 1.89972 7.42577 1.69139 9.59244C1.63377 10.171 1.69799 10.7551 1.8799 11.3073C2.06181 11.8595 2.35737 12.3675 2.74756 12.7985C3.13774 13.2295 3.61388 13.574 4.14531 13.8098C4.67674 14.0456 5.25167 14.1674 5.83305 14.1674H8.37472C8.80805 14.1674 9.16639 13.8091 9.16639 13.3758C9.16639 12.9424 8.80805 12.5841 8.37472 12.5841H5.83305C5.45028 12.5829 5.07252 12.497 4.72692 12.3324C4.38132 12.1679 4.07646 11.9288 3.83424 11.6324C3.59203 11.336 3.41848 10.9897 3.32606 10.6182C3.23364 10.2468 3.22464 9.85945 3.29972 9.48411ZM7.49972 10.8341H12.4997C12.9581 10.8341 13.3331 10.4591 13.3331 10.0008C13.3331 9.54244 12.9581 9.16744 12.4997 9.16744H7.49972C7.04139 9.16744 6.66639 9.54244 6.66639 10.0008C6.66639 10.4591 7.04139 10.8341 7.49972 10.8341ZM13.9831 5.83411H11.6247C11.1914 5.83411 10.8331 6.19244 10.8331 6.62577C10.8331 7.05911 11.1914 7.41744 11.6247 7.41744H14.0664C15.3164 7.41744 16.4664 8.25911 16.6997 9.48411C16.7748 9.85945 16.7658 10.2468 16.6734 10.6182C16.581 10.9897 16.4074 11.336 16.1652 11.6324C15.923 11.9288 15.6181 12.1679 15.2725 12.3324C14.9269 12.497 14.5492 12.5829 14.1664 12.5841H11.6247C11.1914 12.5841 10.8331 12.9424 10.8331 13.3758C10.8331 13.8091 11.1914 14.1674 11.6247 14.1674H14.1664C16.5997 14.1674 18.5497 12.0758 18.3164 9.59244C18.1081 7.42577 16.1581 5.83411 13.9831 5.83411Z"
              fill="#3D40D5"
            />
          </svg>
        </button>
      </Box>
    </Box>
  );
}
