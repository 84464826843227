import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import { LandingLayout } from "../../../../components/Layouts";
import { usePosts } from "./hooks/usePosts";
import List from "./List";
import Loader from "../../../../components/Common/Loader";
import classes from "./index.module.scss";
import SubscribeForm from "../../../../components/Common/SubscribeForm";
import Discord from "../../../../components/Common/Discord";
import Pagination from "../../../../components/Common/Pagination";
import Filters from "../../../../components/Common/PostFilters";
import { usePostsTags } from "./hooks/usePostsTags";

export default function Posts() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const [page, setPage] = useState(Number(searchParams.get("page")) || 1);
  const [tags, setTags] = useState(searchParams.get("tags")?.split(",") || []);
  const [search, setSearch] = useState(searchParams.get("search") || "");

  const perPage = searchParams.get("per_page") || 10;

  const setDynamicParams = (paramName, value) => {
    searchParams.set(paramName, value);
    const newSearchString = searchParams.toString();
    navigate(
      {
        search: newSearchString,
      },
      { replace: true }
    );
  };

  const deleteDynamicParams = (paramName) => {
    searchParams.delete(paramName);
    const newSearchString = searchParams.toString();
    navigate(
      {
        search: newSearchString,
      },
      { replace: true }
    );
  };

  const { data, isFetching } = usePosts({ page, perPage, search, tags });
  const tagsList = usePostsTags();

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      clearTimeout(timeout);
    }, 100);
  }, [data]);
  useEffect(() => {
    setDynamicParams("page", page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  useEffect(() => {
    setDynamicParams("per_page", perPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    tags && tags.length
      ? setDynamicParams("tags", tags)
      : deleteDynamicParams("tags");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);
  useEffect(() => {
    search ? setDynamicParams("search", search) : deleteDynamicParams("search");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <LandingLayout>
      {isFetching ? <Loader /> : null}
      <Container maxWidth={"lg"} className={classes.container}>
        <Box className={classes.wrapper}>
          <Typography variant={"h1"} component={"h1"} className={classes.title}>
            🗞️ Blog
          </Typography>
          <Box component={"section"} className={classes.content}>
            <Box className={classes.posts}>
              <Box className={classes.posts__filters}>
                <Filters
                  setSearch={setSearch}
                  search={search}
                  setPage={setPage}
                  setTags={setTags}
                  tags={tags}
                  tagsList={tagsList?.data}
                  page={page}
                />
              </Box>
              <List posts={data?.posts} />
              <Pagination
                count={Number(data?.totalPages)}
                page={page}
                setPage={setPage}
                disabled={isFetching}
              />
              {(!data || !data?.posts || !data?.posts.length) && !isFetching ? (
                <Typography className={classes.empty}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path
                      d="M13.3332 24C15.6998 23.9995 17.9982 23.2073 19.8625 21.7494L25.7238 27.6107L27.6092 25.7254L21.7478 19.864C23.2065 17.9996 23.9993 15.7006 23.9998 13.3334C23.9998 7.45202 19.2145 2.66669 13.3332 2.66669C7.45184 2.66669 2.6665 7.45202 2.6665 13.3334C2.6665 19.2147 7.45184 24 13.3332 24ZM13.3332 5.33335C17.7452 5.33335 21.3332 8.92135 21.3332 13.3334C21.3332 17.7454 17.7452 21.3334 13.3332 21.3334C8.92117 21.3334 5.33317 17.7454 5.33317 13.3334C5.33317 8.92135 8.92117 5.33335 13.3332 5.33335Z"
                      fill="#3D40D5"
                      fillOpacity="0.4"
                    />
                  </svg>
                  Sorry, we couldn't find the texts you are looking for.
                </Typography>
              ) : null}
            </Box>
            <Box component={"aside"} className={classes.sidebar}>
              <Box className={classes.sidebar__filters}>
                <Filters
                  setSearch={setSearch}
                  search={search}
                  setPage={setPage}
                  setTags={setTags}
                  tags={tags}
                  tagsList={tagsList?.data}
                  page={page}
                />
              </Box>
              <Discord />
              <SubscribeForm />
            </Box>
          </Box>
        </Box>
      </Container>
    </LandingLayout>
  );
}
