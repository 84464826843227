import { Link } from "react-router-dom";
import classes from "./index.module.scss";
import { Box, Typography } from "@mui/material";
import { formatDate, getTagNames } from "./helpers";
import { ReactComponent as CircleIcon } from "./images/circle.svg";
import { ReactComponent as CaldendarIcon } from "./images/calendar.svg";

export default function PostCard({ data, variant = "post", link = "" }) {
  const { title, excerpt, img_url } = data || {};

  const eventVariant = variant === "event";

  const tags = eventVariant ? data?.event_tags : data?.post_tags;
  const date =
    eventVariant && data?.custom_fields?.date
      ? data?.custom_fields?.date[0]
      : data.date;

  return (
    <Box className={classes.card}>
      <Box component={Link} className={classes.image} to={link}>
        <img
          src={img_url?.full}
          alt={title?.rendered}
          width={img_url?.fullWidth}
        />
      </Box>
      <Typography className={classes.title} component={Link} to={link}>
        <span>{title?.rendered}</span>
      </Typography>
      {eventVariant ? (
        <>
          <Box className={classes.eventDateBox}>
            <CaldendarIcon />
            {data?.custom_fields?.date ? (
              <Typography className={classes.eventDate}>
                {data?.custom_fields?.date[0]}
              </Typography>
            ) : null}
          </Box>
          <Typography className={classes.date}>
            {getTagNames(tags, <CircleIcon />)}
          </Typography>
        </>
      ) : (
        <Typography className={classes.date}>
          {formatDate(data.date)}
          <CircleIcon />
          {getTagNames(tags)}
        </Typography>
      )}
      <Box
        className={classes.excerpt}
        dangerouslySetInnerHTML={{
          __html: excerpt.rendered,
        }}
      />
    </Box>
  );
}
