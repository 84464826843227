import {

  CardHeader as MUICardHeader

} from "@mui/material";

import classes from "./index.module.scss";

export default function CardHeader({ avatar }) {
  return <MUICardHeader className={classes.header} avatar={avatar} />;
}
