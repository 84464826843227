import { Typography, Box } from "@mui/material";
import classes from "./index.module.scss";
import { ReactComponent as Avatar } from "../../../../../assets/icons/avatar.svg";

export default function Title({ title: { fullName, image } }) {
  return (
    <Typography component={"h1"} variant={"h1"} className={classes.title}>
      <Box className={classes.image}>
        {image ? <img src={image} alt={fullName} width={64} /> : <Avatar />}
      </Box>
      <span>{fullName || "User"}</span>
    </Typography>
  );
}
