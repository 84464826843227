import classes from "./index.module.scss";

export default function FilterButton({ toggleFilters, id, filters, title }) {
  return (
    <button
      onClick={() => toggleFilters(id)}
      className={`${classes.filter} ${
        filters.includes(id.toString()) ? classes.filter_active : ""
      }`}
    >
      {title}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          d="M3.1126 9.71248L2.2876 8.88748L5.1751 5.99998L2.2876 3.11248L3.1126 2.28748L6.0001 5.17498L8.8876 2.28748L9.7126 3.11248L6.8251 5.99998L9.7126 8.88748L8.8876 9.71248L6.0001 6.82498L3.1126 9.71248Z"
          fill="#3D40D5"
        />
      </svg>
    </button>
  );
}
