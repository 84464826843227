import { Box } from "@mui/material";
import { useDeferredValue } from "react";
import Course from "./Course";
import classes from "./index.module.scss";

export default function CoursesList({ courses }) {
  const deferredCourses = useDeferredValue(courses || []);
  if (!courses || !courses.length) return null;
  return (
    <Box component={"ul"} className={classes.list}>
      {deferredCourses.map((course) => (
        <Box
          component={"li"}
          key={course.id}
          className={`${classes.list__item} 
          ${course.isComplete ? classes.list__item_green : ""}
          ${
            !course.isComplete && course.progressLecturesCount > 0
              ? classes.list__item_orange
              : ""
          }
          `}
        >
          <span className={classes.marker}></span>
          <Course key={course.id} course={course} />
        </Box>
      ))}
    </Box>
  );
}
