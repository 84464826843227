import {
  PayPalScriptProvider,
  PayPalButtons,
  FUNDING,
} from "@paypal/react-paypal-js";
import { api } from "../../../../instances/axiosInstances";
import errorToast from "../../../toasts/errorToast";
import successToast from "../../../toasts/successToast";
import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";
import { useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { QuestionnairePopupContext } from "../../../../context/QuestionnairePopupContext";

export default function Paypal({ courseId, handleClose }) {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData(["user"])?.data;
  const { handleOpen: handleQuestionnairePopupOpen } = useContext(
    QuestionnairePopupContext
  );
  async function createOrder() {
    try {
      const order = await api.post(
        process.env.REACT_APP_PAYPAL_CREATE_ORDER_PATH,
        {
          courseId,
        }
      );
      return order.data.transactions.id;
    } catch (error) {
      console.error(error);
      errorToast("Something went wrong. Try again.");
    }
  }

  async function onApprove(data) {
    try {
      await api.post(process.env.REACT_APP_PAYPAL_ON_APPROVE_PATH, {
        orderId: data.orderID,
        courseId,
      });
      successToast("You have full access to course content");

      await queryClient.invalidateQueries({
        queryKey: ["course", "content", "registered", courseId.toString()],
        refetchType: "all",
        exact: true,
      });

      await queryClient.invalidateQueries({
        queryKey: ["course", "registered", courseId.toString()],
        refetchType: "all",
        exact: true,
      });

      await queryClient.invalidateQueries({
        queryKey: ["lecture", "registered"],
        refetchType: "all",
        exact: true,
      });

      await queryClient.invalidateQueries({
        queryKey: ["my-courses", "registered"],
        refetchType: "all",
        exact: true,
      });

      await queryClient.invalidateQueries({
        queryKey: ["all-courses", "registered"],
        refetchType: "all",
        exact: true,
      });

      await queryClient.invalidateQueries({
        queryKey: ["landing", "registered"],
        refetchType: "all",
        exact: true,
      });

      await handleClose();
      await handleQuestionnairePopupOpen(user?.users);

      await queryClient.invalidateQueries({
        queryKey: ["user"],
        refetchType: "all",
        exact: true,
      });

      await queryClient.invalidateQueries({
        queryKey: ["paths", "registered"],
        refetchType: "all",
        exact: true,
      });

      await queryClient.invalidateQueries({
        queryKey: ["path", "registered"],
        refetchType: "all",
      });
    } catch (error) {
      console.error(error);
      errorToast("Something went wrong. Try again.");
    }
  }

  const fundingSources1 = FUNDING.PAYPAL;
  const fundingSources2 = FUNDING.CARD;

  return (
    <Box className={classes.wrapper}>
      <PayPalScriptProvider
        options={{
          clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
        }}
      >
        <PayPalButtons
          options={{
            clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
          }}
          createOrder={createOrder}
          onApprove={onApprove}
          fundingSource={fundingSources1}
          className={classes.button}
        />
        <PayPalButtons
          options={{
            clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
          }}
          createOrder={createOrder}
          onApprove={onApprove}
          fundingSource={fundingSources2}
          className={classes.button}
        />
      </PayPalScriptProvider>
      <Typography className={classes.text}>
        Processed by{" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="45"
          height="13"
          viewBox="0 0 45 13"
          fill="none"
        >
          <g clipPath="url(#clip0_5652_9812)">
            <path
              d="M8.28508 1.42055C7.73416 1.04182 7.01525 0.85199 6.12837 0.85199H2.69482C2.42293 0.85199 2.27272 0.987964 2.24419 1.25959L0.849349 10.015C0.834853 10.101 0.856367 10.1796 0.913696 10.2511C0.970697 10.3228 1.04239 10.3584 1.12825 10.3584H2.75916C3.04522 10.3584 3.20244 10.2228 3.23131 9.9507L3.61752 7.59033C3.63162 7.47593 3.68193 7.38292 3.76779 7.3113C3.85359 7.2398 3.9609 7.19296 4.08966 7.17158C4.21842 7.15033 4.33983 7.13964 4.45449 7.13964C4.56882 7.13964 4.70466 7.14685 4.86228 7.16109C5.01951 7.17538 5.11974 7.18234 5.1627 7.18234C6.39297 7.18234 7.35864 6.83568 8.05976 6.14163C8.76056 5.44785 9.11136 4.4858 9.11136 3.25526C9.11136 2.41121 8.83573 1.79962 8.28508 1.42029V1.42055ZM6.51471 4.04953C6.44295 4.55034 6.25719 4.87922 5.95677 5.03665C5.65629 5.19427 5.22711 5.27259 4.66924 5.27259L3.96103 5.2941L4.32593 2.99788C4.3544 2.84065 4.44734 2.76194 4.60483 2.76194H5.01269C5.58466 2.76194 5.9998 2.84439 6.25732 3.00863C6.51471 3.17327 6.60057 3.52039 6.51471 4.04953Z"
              fill="#003087"
            />
            <path
              d="M44.5194 0.851868H42.9315C42.7738 0.851868 42.6809 0.930581 42.6526 1.08794L41.2576 10.0152L41.2361 10.0581C41.2361 10.13 41.2647 10.1976 41.3221 10.262C41.379 10.3262 41.4508 10.3585 41.5366 10.3585H42.9531C43.2245 10.3585 43.3748 10.2229 43.4038 9.95084L44.7987 1.1738V1.15248C44.7985 0.952226 44.7052 0.852065 44.5194 0.852065V0.851868Z"
              fill="#009CDE"
            />
            <path
              d="M25.3345 4.30731C25.3345 4.23595 25.3058 4.16786 25.2489 4.10358C25.1915 4.03923 25.1271 4.0069 25.0557 4.0069H23.4033C23.2457 4.0069 23.117 4.07879 23.017 4.22145L20.7423 7.56934L19.7981 4.35034C19.7262 4.12155 19.569 4.0069 19.326 4.0069H17.7163C17.6446 4.0069 17.5802 4.03917 17.5234 4.10358C17.466 4.16786 17.4375 4.23601 17.4375 4.30731C17.4375 4.33618 17.577 4.75801 17.8559 5.57346C18.1348 6.38905 18.4353 7.26892 18.7572 8.2132C19.0792 9.15735 19.2471 9.65829 19.2615 9.71516C18.0883 11.3176 17.5019 12.1761 17.5019 12.2903C17.5019 12.4765 17.5949 12.5694 17.7809 12.5694H19.4332C19.5906 12.5694 19.7193 12.498 19.8196 12.3548L25.2918 4.45739C25.3202 4.42899 25.3345 4.37914 25.3345 4.30725V4.30731Z"
              fill="#003087"
            />
            <path
              d="M40.7215 4.0069H39.0905C38.8901 4.0069 38.7689 4.24297 38.7259 4.71511C38.3535 4.14314 37.6744 3.85663 36.6871 3.85663C35.657 3.85663 34.7806 4.24297 34.0583 5.01553C33.3357 5.78816 32.9746 6.69675 32.9746 7.741C32.9746 8.58525 33.2213 9.25758 33.7148 9.75813C34.2084 10.2592 34.87 10.5092 35.7001 10.5092C36.1148 10.5092 36.5369 10.4232 36.966 10.2517C37.3953 10.0801 37.7312 9.85127 37.9748 9.56502C37.9748 9.57938 37.9602 9.6436 37.9319 9.758C37.903 9.87265 37.8889 9.95871 37.8889 10.0155C37.8889 10.2447 37.9817 10.3588 38.168 10.3588H39.6488C39.9202 10.3588 40.0778 10.2232 40.1206 9.9511L41.0005 4.35015C41.0146 4.26429 40.9933 4.18571 40.9362 4.11408C40.8787 4.04278 40.8074 4.0069 40.7215 4.0069ZM37.9211 8.23452C37.5562 8.5922 37.1163 8.77101 36.6013 8.77101C36.1863 8.77101 35.8504 8.65675 35.5927 8.4277C35.3351 8.19923 35.2063 7.88445 35.2063 7.48342C35.2063 6.95447 35.3852 6.50712 35.7429 6.14229C36.1001 5.77746 36.5441 5.59505 37.0734 5.59505C37.4737 5.59505 37.8063 5.71312 38.0712 5.94906C38.3357 6.18513 38.4684 6.51073 38.4684 6.92561C38.4683 7.44058 38.2858 7.87704 37.9211 8.23452Z"
              fill="#009CDE"
            />
            <path
              d="M16.3432 4.0069H14.7123C14.5117 4.0069 14.3904 4.24297 14.3474 4.71511C13.9611 4.14314 13.2815 3.85663 12.3087 3.85663C11.2786 3.85663 10.4022 4.24297 9.67986 5.01553C8.95728 5.78816 8.59619 6.69675 8.59619 7.741C8.59619 8.58525 8.84302 9.25758 9.33661 9.75813C9.8302 10.2592 10.4917 10.5092 11.3216 10.5092C11.722 10.5092 12.1371 10.4232 12.5662 10.2517C12.9954 10.0801 13.3388 9.85127 13.5963 9.56502C13.5389 9.73661 13.5104 9.88689 13.5104 10.0155C13.5104 10.2447 13.6034 10.3588 13.7894 10.3588H15.2701C15.5417 10.3588 15.6993 10.2232 15.7423 9.9511L16.6221 4.35015C16.6362 4.26429 16.6147 4.18571 16.5577 4.11408C16.5004 4.04278 16.429 4.0069 16.3432 4.0069ZM13.5427 8.24515C13.1778 8.59614 12.7305 8.77101 12.2015 8.77101C11.7864 8.77101 11.4538 8.65675 11.2036 8.4277C10.9531 8.19923 10.828 7.88445 10.828 7.48342C10.828 6.95447 11.0067 6.50712 11.3646 6.14229C11.722 5.77746 12.1655 5.59505 12.6951 5.59505C13.0954 5.59505 13.4281 5.71312 13.693 5.94912C13.9575 6.18519 14.09 6.5108 14.09 6.92567C14.09 7.45495 13.9075 7.89501 13.5427 8.24515Z"
              fill="#003087"
            />
            <path
              d="M32.6633 1.42055C32.1125 1.04182 31.3937 0.85199 30.5067 0.85199H27.0945C26.8083 0.85199 26.6508 0.987964 26.6224 1.25959L25.2275 10.015C25.213 10.101 25.2345 10.1797 25.2919 10.2512C25.3486 10.3228 25.4206 10.3584 25.5064 10.3584H27.266C27.4377 10.3584 27.5521 10.2656 27.6094 10.0795L27.9958 7.59033C28.0099 7.47593 28.06 7.38292 28.146 7.3113C28.2318 7.2398 28.339 7.19303 28.4679 7.17158C28.5966 7.15033 28.7179 7.13964 28.8327 7.13964C28.9471 7.13964 29.0829 7.14685 29.2403 7.16109C29.3977 7.17538 29.4982 7.18234 29.5408 7.18234C30.7713 7.18234 31.7368 6.83568 32.4379 6.14163C33.1389 5.44785 33.4894 4.4858 33.4894 3.25526C33.4895 2.41127 33.214 1.79968 32.6633 1.42036V1.42055ZM30.4637 4.95079C30.1489 5.16534 29.6768 5.27259 29.0474 5.27259L28.3607 5.2941L28.7255 2.99788C28.7538 2.84065 28.8468 2.76194 29.0044 2.76194H29.3905C29.7053 2.76194 29.9556 2.77624 30.1418 2.80477C30.3275 2.83363 30.5067 2.92277 30.6783 3.07298C30.8501 3.22325 30.9359 3.44148 30.9359 3.7276C30.9359 4.32844 30.7783 4.7361 30.4637 4.95079Z"
              fill="#009CDE"
            />
          </g>
          <defs>
            <clipPath id="clip0_5652_9812">
              <rect
                width="44"
                height="11.8314"
                fill="white"
                transform="translate(0.821777 0.794128)"
              />
            </clipPath>
          </defs>
        </svg>
      </Typography>
    </Box>
  );
}
