import { Box, Typography } from "@mui/material";
import CourseType from "./CourseType";
import { Link } from "react-router-dom";
import { formatDateTime } from "../../../../helpers/formatDateTime";
import { formatTimeMilliseconds } from "../../../../helpers/formatTime";
import useMixpanel from "../../../../hooks/useMixpanel";
import {
  mixpanelLandingOurCourses,
  mixpanelStudentPlatform,
} from "../../../../constants/mixpanel";
import DifficultyLevel from "./DifficultyLevel";
import HTMLComponent from "../../../Common/HTMLComponent";

import classes from "./index.module.scss";

export default function CourseCard({ course, openFromLanding = false }) {
  const { handleEventTrack } = useMixpanel();
  const { courseDetailsLink } = mixpanelLandingOurCourses;
  const { courseDetailsStudLink } = mixpanelStudentPlatform;
  const startDate =
    course.type === "live" &&
    course.liveSchedule &&
    course.liveSchedule[0]?.from
      ? formatDateTime(course.liveSchedule[0].from)
      : null;

  return (
    <Box
      component={Link}
      className={classes.card}
      to={`/courses/${course.id}`}
      onClick={(e) =>
        handleEventTrack(
          e,
          openFromLanding
            ? {
                ...courseDetailsLink,
                options: {
                  ...courseDetailsLink.options,
                  course: course.title,
                  courseId: course.id,
                },
              }
            : {
                ...courseDetailsStudLink,
                options: {
                  ...courseDetailsStudLink.options,
                  course: course.title,
                  courseId: course.id,
                },
              }
        )
      }
    >
      <Box className={classes.image}>
        <img src={course.promoImageUrl} alt={course.title} />
      </Box>
      <Box className={classes.info}>
        <CourseType type={course.type} />
        <DifficultyLevel level={course.difficultyLevel} />
      </Box>
      <Typography component={"h3"} className={classes.title}>
        <span>{course.title}</span>
      </Typography>
      {course.type && (course.type === "recorded" || course.type === "live") ? (
        <Typography className={classes.duration}>
          <span>
            {course.type === "recorded" &&
              `${
                course.lecturesCount
                  ? course.lecturesCount +
                    (course.lecturesCount === 1
                      ? " lecture • "
                      : " lectures • ")
                  : null
              }
            ${
              course.duration
                ? formatTimeMilliseconds(course.duration).trim()
                : null
            }`}
            {course.type === "live" &&
              `${
                startDate?.formattedDate ? startDate.formattedDate + " • " : ""
              } ${
                course.lecturesCount
                  ? course.lecturesCount +
                    (course.lecturesCount === 1 ? " lecture " : " lectures ")
                  : null
              }
            ${
              course.duration
                ? "(" + formatTimeMilliseconds(course.duration).trim() + ")"
                : null
            }`}
          </span>
        </Typography>
      ) : null}
      {course.shortDescription.toString().includes("</") ? (
        <Box className={classes.htmlDescription}>
          <HTMLComponent
            html={course.shortDescription}
            fontSize={"regular"}
            textColor={"dark-090"}
          />
        </Box>
      ) : (
        <Typography className={classes.description}>
          {course.shortDescription}
        </Typography>
      )}
      <Box className={classes.footer}>
        <Typography className={classes.price}>
          Price:
          {Number(course.price) ? (
            <span>${course.price}</span>
          ) : (
            <span className={classes.price__free}>Free</span>
          )}
        </Typography>
        {course.isPaid ? (
          <Typography className={classes.status}>
            {course.type === "recorded" && !course.isComplete
              ? `Finished ${course.progressLecturesCount} / ${course.lecturesCount}`
              : null}
            {course.type === "recorded" && course.isComplete
              ? `Completed`
              : null}
            {course.type === "live" && !course.isComplete ? `Ongoing` : null}
            {course.type === "live" && course.isComplete ? `Archived` : null}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
}
