import { Box } from "@mui/material";
import Tag from "../../../../../components/Common/Tag";
import classes from "./index.module.scss";

export default function CardTags({tags}) {
  return (
    <Box className={classes.tags}>
      {tags.map((tag, index) => (
        <Tag key={index}>{tag}</Tag>
      ))}
    </Box>
  );
}
