import { useRelatedPosts } from "../../Posts/hooks/useRelatedPosts";
import { Box, Typography } from "@mui/material";
import List from "./List";
import classes from "./index.module.scss";

export default function RelatedPosts({ tags, id }) {
  const { data } = useRelatedPosts({
    id,
    tags,
    enabled: !!tags && !!tags?.length && !!id,
  });

  if ((!tags && !tags?.length) || !data) return null;

  return (
    <Box className={classes.wrapper}>
      <Typography component={"h3"} variant={"h3"} className={classes.title}>
        Related articles
      </Typography>
      <List posts={data.relatedPosts} />
    </Box>
  );
}
