import { useEffect, useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./schema";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Container, Typography } from "@mui/material";
import {
  AccentButton,
  CarouselButton,
} from "../../components/MUI/buttons/button";
import Stepper from "./Stepper";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as LogoWhite } from "../../assets/images/logo-white.svg";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "../../instances/axiosInstances";
import classes from "./index.module.scss";
import errorToast from "../../components/toasts/errorToast";

const steps = [
  { label: "Step 1", component: Step1 },
  { label: "Step 2", component: Step2 },
  { label: "Step 3", component: Step3 },
];

export default function Questionnaire() {
  const [step, setStep] = useState(0);
  const queryClient = useQueryClient();

  const {
    trigger,
    register,
    handleSubmit,
    resetField,
    clearErrors,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(
    (data) => api.put("/users/profile", data),
    {
      onSuccess: async({ data }) => {
        // successToast(data.emails.message);
        await queryClient.invalidateQueries({
          queryKey: ["user"],
          refetchType: "all",
          exact: true,
        });
        navigate("/profile");
      },
      onError: (error) => {
        errorToast(error.response.data.message);
      },
    }
  );

  const StepComponent = steps[step].component;

  const onSubmit = (data) => {
    const { otherTopics: _, ...newObj } = data;
    mutate(newObj);
  };

  const onNextStep = async () => {
    const stepFields = getStepFields(step);
    trigger(stepFields).then((data) =>
      data ? setStep((prevStep) => prevStep + 1) : false
    );
  };

  const onPrevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const getStepFields = (step) => {
    switch (step) {
      case 0:
        return ["firstName", "lastName", "phoneNumber", "discordUsername"];
      case 1:
        return ["company", "position", "linkedinLink", "yearsOfExperience"];
      default:
        return [];
    }
  };
  const renderTitle = (step) => {
    switch (step) {
      case 0:
        return "General info";
      case 1:
        return "Tell us more about your job";
      case 2:
        return "And last but not least";
      default:
        return "";
    }
  };

  useEffect(() => {
    localStorage.removeItem("redirectPath")
  }, []);

  return (
    <Container maxWidth={false} disableGutters className={classes.container}>
      <Box className={classes.questionnaire} component={"section"}>
        <Box className={classes.layout}></Box>
        <Box component={"header"} className={classes.header}>
          <Box className={classes.header__logo} component={Link} to="/">
            <LogoWhite />
          </Box>
        </Box>
        <Box className={classes.card}>
          <Box className={classes.card__logo} component={Link} to="/">
            <Logo />
          </Box>
          {step === 0 ? (
            <Typography className={classes.banner}>
              We would like to ask you a few questions in order to make our
              courses best suited for all our students
            </Typography>
          ) : null}
          <Typography component={"h1"} variant={"h1"} className={classes.title}>
            {renderTitle(step)}
          </Typography>
          <Stepper steps={steps} activeStep={step} />
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <StepComponent
              register={register}
              errors={errors}
              clearErrors={clearErrors}
              resetField={resetField}
              trigger={trigger}
              control={control}
              getValues={getValues}
              setValue={setValue}
            />
            <Box sx={{ display: "flex", gap: "0.75rem" }}>
              {step > 0 && (
                <CarouselButton onClick={onPrevStep} variant="outlined">
                  <ChevronLeftIcon />
                </CarouselButton>
              )}
              {step < steps.length - 1 ? (
                <AccentButton
                  isBig={false}
                  type="button"
                  onClick={onNextStep}
                  variant="contained"
                  endIcon={<ChevronRightIcon />}
                  fullWidth
                  disabled={isLoading}
                >
                  Next
                </AccentButton>
              ) : (
                <AccentButton
                  isBig={false}
                  type="button"
                  variant="contained"
                  fullWidth
                  disabled={isLoading}
                  onClick={() => handleSubmit(onSubmit)()}
                >
                  Finish
                </AccentButton>
              )}
            </Box>
          </form>
        </Box>
        <Box component={"footer"} className={classes.footer}>
          <Box className={classes.footer__logo} component={Link} to="/">
            <Logo />
          </Box>

          <Typography
            className={classes.footer__link}
            component={Link}
            to="/privacy-policy"
          >
            Privacy Policy
          </Typography>

          <Typography className={classes.footer__text}>
            {new Date().getFullYear()} Engenious University Inc. (c) All rights
            reserved
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}
