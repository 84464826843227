import React, { useRef, useState, useEffect } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Intersection } from "@splidejs/splide-extension-intersection";
import "@splidejs/react-splide/css";
import classes from "./index.module.scss";
import { Box, Typography } from "@mui/material";
import slideBgImgMob from "../images/slide-bg-mob.png";
import slideBgImgDesk from "../images/slide-bg-desk.png";
import CarouselNavigation from "../../../../../components/Common/CarouselNavigation";

export default function VerticalSlider({ data }) {
  const splideRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const splide = splideRef.current.splide;

    if (splide) {
      const handleSlideChange = (newIndex) => {
        setCurrentSlide(newIndex);
      };

      splide.on("move", handleSlideChange);

      return () => {
        splide.off("move", handleSlideChange);
      };
    }
  }, [splideRef]);

  return (
    <Box className={classes.sliderContainer}>
      <Box className={classes.sliderBox}>
        <Splide
          className={classes.splide}
          ref={splideRef}
          extensions={{ Intersection }}
          options={{
            direction: "ttb",
            height: "600px",
            arrows: false,
            pagination: false,
            rewind: true,
            gap: "1rem",
            type: "loop",
            autoplay: "pause",
            interval: 3000,
            perPage: 1,
            perMove: 1,
            omitEnd: true,
            intersection: {
              inView: {
                autoplay: false,
              },
              outView: {
                autoplay: false,
              },
            },
          }}
          aria-label="Tech Trajectory"
        >
          {data.map((item, index) => (
            <SplideSlide className={classes.slide} key={index}>
              <Box className={classes.slideBg}>
                <img
                  className={classes.mob}
                  src={slideBgImgMob}
                  alt="Slide background"
                />
                <img
                  className={classes.desk}
                  src={slideBgImgDesk}
                  alt="Slide background"
                />
              </Box>
            </SplideSlide>
          ))}
        </Splide>
        <Box className={classes.content}>
          {data.map((item, index) => (
            <Box key={index}>
              {index === currentSlide ? (
                <Box className={classes.contentItem}>
                  <Box className={classes.titleBox}>
                    <Typography className={classes.title} component="h3">
                      {index + 1}. {item?.title?.rendered}
                    </Typography>
                  </Box>
                  <Box
                    className={classes.description}
                    dangerouslySetInnerHTML={{
                      __html: item?.content?.rendered,
                    }}
                  />
                </Box>
              ) : null}
            </Box>
          ))}
        </Box>
        <Box className={`${classes.fadeBg} ${classes.fadeBgTop}`}></Box>
        <Box className={`${classes.fadeBg} ${classes.fadeBgBottom}`}></Box>
      </Box>
      <Box className={classes.nav}>
        <CarouselNavigation
          splideRef={splideRef}
          variant="outlined"
          position="vertical"
        />
      </Box>
    </Box>
  );
}
