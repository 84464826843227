import SectionTitle from "../components/SectionTitle";
import SectionText from "../components/SectionText";
import SectionGrid from "../components/SectionGrid";
import HTMLComponent from "../../../../components/Common/HTMLComponent";

export default function WhoIsCourseFor({ whoIsCourseFor }) {
  if (!whoIsCourseFor) {
    return null;
  }
  return (
    <SectionGrid>
      <SectionTitle>Who this course is for:</SectionTitle>
      {whoIsCourseFor.toString().includes("</") ? (
        <HTMLComponent html={whoIsCourseFor} />
      ) : (
        <SectionText>{whoIsCourseFor}</SectionText>
      )}
    </SectionGrid>
  );
}
