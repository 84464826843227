export default function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const hoursText = hours < 10 ? `0${hours}` : `${hours}`;
  const minutesText = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const secondsText =
    remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;

  return `${hours ? hoursText + ":" : ""}${minutesText}:${secondsText}`;
}

function formatTimeMinutes(duration) {
  const hours = Math.floor(duration / 60);
  const minutes = Math.floor(duration % 60);

  const hoursText = hours ? `${hours}h` : ``;
  const minutesText = minutes
    ? minutes < 10
      ? `0${minutes}m`
      : `${minutes}m`
    : ``;

  return `${hoursText} ${minutesText}`;
}

function formatTimeMilliseconds(milliseconds) {
  let totalSeconds = Math.floor(milliseconds / 1000);
  let hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds % 3600) / 60);

  const hoursText = hours ? `${hours} ${hours === 1 ? "hour " : "hours "}` : ``;
  const minutesText = minutes
    ? `${minutes} ${minutes === 1 ? "minute" : "minutes"}`
    : ``;

  return hours || minutes ? `${hoursText}${minutesText}` : "less then 1 minute";
}

const getLecturesCount = (modules) => {
  return modules?.reduce((acc, module) => acc + module.lectures.length, 0);
};

const getLecturesDuration = (modules) => {
  return modules?.reduce((acc, module) => {
    return (
      acc +
      module.lectures.reduce((acc, lecture) => {
        return acc + lecture.duration;
      }, 0)
    );
  }, 0);
};

export {
  formatTimeMinutes,
  formatTimeMilliseconds,
  getLecturesCount,
  getLecturesDuration,
};
