import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";

export const Chip = () => {
  return (
    <Box className={classes.chip}>
      <svg
        className={classes.chip__icon}
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          d="M1.758 10.2415C1.18494 9.68803 0.727844 9.02596 0.41339 8.29394C0.0989363 7.56191 -0.066581 6.77459 -0.0735039 5.97792C-0.0804268 5.18124 0.0713834 4.39116 0.373069 3.65379C0.674754 2.91641 1.12027 2.2465 1.68363 1.68314C2.24699 1.11978 2.9169 0.674266 3.65427 0.37258C4.39165 0.0708951 5.18173 -0.0809151 5.97841 -0.0739922C6.77508 -0.0670693 7.5624 0.0984481 8.29443 0.412902C9.02645 0.727356 9.68852 1.18445 10.242 1.75751C11.3349 2.88912 11.9397 4.40474 11.926 5.97792C11.9124 7.5511 11.2814 9.05597 10.1689 10.1684C9.05646 11.2809 7.55159 11.9119 5.97841 11.9256C4.40523 11.9392 2.88961 11.3345 1.758 10.2415ZM9.396 9.39551C10.2967 8.49484 10.8027 7.27326 10.8027 5.99951C10.8027 4.72576 10.2967 3.50419 9.396 2.60351C8.49532 1.70284 7.27375 1.19684 6 1.19684C4.72625 1.19684 3.50467 1.70284 2.604 2.60351C1.70332 3.50419 1.19733 4.72576 1.19733 5.99951C1.19733 7.27326 1.70332 8.49484 2.604 9.39551C3.50467 10.2962 4.72625 10.8022 6 10.8022C7.27375 10.8022 8.49532 10.2962 9.396 9.39551ZM4.2 3.59951L9 5.99951L4.2 8.39951V3.59951Z"
          fill="#2EA1E8"
        />
      </svg>
      <Typography className={classes.chip__text}>Recorded Course</Typography>
    </Box>
  );
};
