import { Box, Container, Typography } from "@mui/material";
import classes from "./index.module.scss";
import { ReactComponent as ArrowIcon } from "./images/arrow.svg";
import { AccentButton } from "../../../../components/MUI/buttons/button";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { apiCmsData } from "../../../../instances/axiosInstances";

export default function Solution({ data }) {
  const itemsRes = useQuery(["homeSolution"], () =>
    apiCmsData.get(
      `/home-solution${process.env.REACT_APP_WP_HOME_QUERY_PARAMS}`
    )
  );

  const items = itemsRes.isSuccess ? itemsRes?.data?.data : [];

  if (!data || !items.length) return null;

  const { title, custom_fields } = data || {};

  if (custom_fields?.display_section[0] === "off") return null;

  return (
    <Box className={classes.section} component="section">
      <Container maxWidth="lg">
        <Typography className={classes.title} component="h2">
          {title?.rendered}
        </Typography>
        <Box className={classes.list} component="ul">
          {items.map((item) => (
            <Box className={classes.listItem} key={item.id} component="li">
              <Box
                className={classes.itemDescription}
                dangerouslySetInnerHTML={{
                  __html: item?.content?.rendered,
                }}
              />
              <ArrowIcon />
              <Box className={classes.itemTitleBox}>
                <Typography className={classes.itemTitle} component="h3">
                  {item?.title?.rendered}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <Box className={classes.itemButton}>
          <AccentButton
            isBig={false}
            component={Link}
            variant="contained"
            target="_blank"
            rel="noreferrer"
            to={custom_fields?.button_link[0]}
          >
            {custom_fields?.button}
          </AccentButton>
        </Box>
      </Container>
    </Box>
  );
}
