import { Box, Container, Typography } from "@mui/material";
import classes from "./index.module.scss";
import { useDeferredValue } from "react";
import FilterButton from "./FilterButton";
import MentorCard from "./MentorCard";

export default function Mentors({
  mentors,
  specializations,
  toggleFilters,
  filters,
}) {
  const deferredMentors = useDeferredValue(mentors?.mentors.mentors);
  const deferredSpecializations = useDeferredValue(
    specializations?.specializations.mentorsSpecializations
  );

  if (!deferredMentors) return null;

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Box className={classes.body} component={"section"}>
        <Typography
          component={"h2"}
          variant={"h2"}
          className={`${classes.title}`}
        >
          ⚡ We can help with any tech questions
        </Typography>
        {deferredSpecializations ? (
          <Box className={classes.filters}>
            {deferredSpecializations.map(({ id, title }) => (
              <FilterButton
                key={id}
                toggleFilters={toggleFilters}
                id={id}
                filters={filters}
                title={title}
              />
            ))}
          </Box>
        ) : null}
        {deferredMentors.length ? (
          <Box className={classes.mentors}>
            {deferredMentors.map((mentor) => (
              <MentorCard key={mentor.id} mentor={mentor} />
            ))}
          </Box>
        ) : (
          <Typography className={classes.empty}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M13.3332 24C15.6998 23.9995 17.9982 23.2073 19.8625 21.7494L25.7238 27.6107L27.6092 25.7254L21.7478 19.864C23.2065 17.9996 23.9993 15.7006 23.9998 13.3334C23.9998 7.45202 19.2145 2.66669 13.3332 2.66669C7.45184 2.66669 2.6665 7.45202 2.6665 13.3334C2.6665 19.2147 7.45184 24 13.3332 24ZM13.3332 5.33335C17.7452 5.33335 21.3332 8.92135 21.3332 13.3334C21.3332 17.7454 17.7452 21.3334 13.3332 21.3334C8.92117 21.3334 5.33317 17.7454 5.33317 13.3334C5.33317 8.92135 8.92117 5.33335 13.3332 5.33335Z"
                fill="#3D40D5"
                fillOpacity="0.4"
              />
            </svg>{" "}
            Nothing found...
          </Typography>
        )}
      </Box>
    </Container>
  );
}
