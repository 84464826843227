import { toast } from "react-toastify";
import { ReactComponent as Icon } from "../../assets/images/error-icon.svg";
const errorToast = (message, options = {}) => {
  const defaultOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "light",
    icon: <Icon />,
  };
  const mergedOptions = { ...defaultOptions, ...options };

  toast.error(message, mergedOptions);
};

export default errorToast;
