import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";

export default function Badge({ title, icon, data }) {
  return (
    <Typography component={"h3"} variant={"h3"} className={classes.badge}>
      <span>{title}</span>
      <Box>
        {icon} <span>{data}</span>
      </Box>
    </Typography>
  );
}
