import { Box } from "@mui/material";
import classes from "./index.module.scss";
import { Accordion, Summary, Details } from "../Accordion/ModuleAccordion";
import Header from "./Header";
import Content from "./Content";
import useAccordion from "../../../../hooks/useAccordion";
import { useLocation } from "react-router-dom";

export default function Tasks({ tasks }) {
  const { pathname } = useLocation();
  const defaultExpanded = pathname.includes("preparation-tasks") ? "panel1" : false;
  const { expanded, handleChange } = useAccordion(defaultExpanded);

  if (!tasks || !tasks.length) {
    return null;
  }
  return (
    <Box className={classes.tasks}>
      <Accordion expanded={expanded} handleChange={handleChange} index={1}>
        <Summary index={1}>
          <Header title={"Preparation tasks"} />
        </Summary>
        <Details>
          <Content list={tasks} />
        </Details>
      </Accordion>
    </Box>
  );
}
