import { Box, Container, Typography } from "@mui/material";
import { AccentButton } from "../../../../components/MUI/buttons/button";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link } from "react-router-dom";
import image from "./images/robot.png";
import classes from "./index.module.scss";

export default function EngeniousUniversity() {
  return (
    <Container maxWidth="lg" className={classes.container}>
      <Box className={classes.body} component={"section"}>
        <Typography
          component={"h2"}
          variant={"h2"}
          className={`${classes.title} ${classes.title__mobile}`}
        >
          🎓 Engenious University
        </Typography>
        <Box className={classes.content}>
          <Typography
            component={"h2"}
            variant={"h2"}
            className={`${classes.title} ${classes.title__desktop}`}
          >
            🎓 Engenious University
          </Typography>
          <Typography className={classes.text}>
            Are you ready to supercharge your tech career beyond mentorship?
            Engenious University offers live online classes, real-world
            projects, and 1:1 mentorship with seasoned professionals to propel
            you into the world of QA Manual, QA Automation, or Full Stack
            Development. Plus, our thriving Discord Community ensures 24/7
            support. Don't settle for less – embrace limitless possibilities
            with us at Engenious University! 🚀📚🌟 Click now to embark on your
            tech journey!
          </Typography>
          <Box className={classes.actions}>
            <AccentButton
              isBig={false}
              variant={"contained"}
              component={Link}
              to="/"
              endIcon={<ChevronRightIcon />}
            >
              Engenious University
            </AccentButton>
          </Box>
        </Box>
        <Box className={classes.image}>
          <img src={image} alt="Engenious University" width={523} />
        </Box>
      </Box>
    </Container>
  );
}
