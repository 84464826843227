import { Button } from "../../MUI";
import { ReactComponent as Icon } from "../../../assets/images/chat.svg";
import classes from "./index.module.scss";
import useMixpanel from "../../../hooks/useMixpanel";
import { mixpanelLiveChat } from "../../../constants/mixpanel";

export default function LiveChatButton() {
  const { handleEventTrack } = useMixpanel();
  const { liveChatButton } = mixpanelLiveChat;

  return (
    <>
      <Button
        onClick={(e) => {
          handleEventTrack(e, liveChatButton);
        }}
        variant="outlined"
        className={`${classes.button} ${classes.button__desktop}`}
        startIcon={<Icon />}
        component={"a"}
        target="_blank"
        rel="noopener noreferrer"
        href="https://discord.gg/hjGcUdxJF4"
      >
        Ask question
      </Button>
      <Button
        onClick={(e) => {
          handleEventTrack(e, liveChatButton);
        }}
        variant="outlined"
        className={`${classes.button} ${classes.button__mobile}`}
        component={"a"}
        target="_blank"
        rel="noopener noreferrer"
        href="https://discord.gg/hjGcUdxJF4"
      >
        <Icon />
      </Button>
    </>
  );
}
