import { createTheme } from "@mui/material/styles";

export const theme = () =>
  createTheme({
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            "text-16": "p",
            "text-16-const": "h3",
            "text-12": "p",
            "text-14": "p",
            "text-20": "p",
            "text-24-const": "h4",
          },
        },
      },
    },
    typography: {
      "text-12": {
        fontSize: "0.75rem",
      },
      "text-14": {
        fontSize: "0.875rem",
      },
      "text-16": {
        fontSize: "clamp(0.875rem, 0.7321rem + 0.2976vw, 1rem)",
      },
      "text-20": {
        fontSize: "clamp(1rem, 0.7143rem + 0.5952vw, 1.25rem)",
      },
      "text-20-const": {
        fontSize: "1.25rem",
      },
      "text-16-const": {
        fontSize: "1rem",
      },
      "text-24-const": {
        fontSize: "clamp(1.125rem, 0.6964rem + 0.8929vw, 1.5rem);",
      },
    },
  });
