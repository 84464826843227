import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";

export default function CouponDetails({
  coupon,
  discountedPrice,
  defaultPrice,
  isPriceVisible = false,
}) {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getUTCMonth()];
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    const formattedDate = month + " " + day + ", " + year;
    return formattedDate;
  };

  const diffBetweenDates = (start, end) => {
    const date1 = new Date(start);
    const date2 = new Date(end);
    const differenceInMillis = date2 - date1;
    const differenceInDays = Math.round(
      differenceInMillis / (24 * 60 * 60 * 1000)
    );
    return differenceInDays;
  };

  return (
    <Box className={classes.couponDetails}>
      <Typography component={"h3"} className={classes.couponDetails__title}>
        Coupon:
      </Typography>
      <Typography className={classes.couponDetails__discount}>
        Only for {diffBetweenDates(coupon.durationFrom, coupon.durationTo)}
        {diffBetweenDates(coupon.durationFrom, coupon.durationTo) === 1
          ? " day "
          : " days "}
        - {coupon.discount}%
      </Typography>
      {isPriceVisible ? (
        <Typography className={classes.couponDetails__price}>
          ${discountedPrice} <span>${defaultPrice}</span>
        </Typography>
      ) : null}
      <Typography className={classes.couponDetails__date}>
        Expiration date: {formatDate(coupon.durationTo)}
      </Typography>
      <Typography className={classes.couponDetails__info}>
        ✅ Coupon successfully applied
      </Typography>
    </Box>
  );
}
