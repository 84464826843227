import { Link } from "react-router-dom";
import classes from "./index.module.scss";
import { Box, Typography } from "@mui/material";
import userImg from "../../../../../assets/images/avatarV3.png";
import HTMLComponent from "../../../../../components/Common/HTMLComponent";

export default function MentorCard({ mentor }) {
  return (
    <Link to={`/mentorship/${mentor.id}`} className={classes.card}>
      <Box className={classes.image}>
        <img
          src={mentor.photoUrl || userImg}
          alt={(
            (mentor.firstName || "") +
            " " +
            (mentor.lastName || "")
          ).trim()}
          width={145}
        />
      </Box>
      <Box className={classes.content}>
        <Typography component={"h3"} variant={"h3"} className={classes.title}>
          {((mentor.firstName || "") + " " + (mentor.lastName || "")).trim()}
        </Typography>
        <Typography className={classes.specialization}>
          {mentor.specializations
            .map(({ title }) => title)
            .join(", ")
            .trim()}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="4"
            height="4"
            viewBox="0 0 4 4"
            fill="none"
          >
            <circle cx="2" cy="2" r="2" fill="#141416" fillOpacity="0.4" />
          </svg>
          <span>
            {mentor.yearsOfExperience}{" "}
            {Number(mentor.yearsOfExperience) === 1 ? "year" : "years"} of
            experience
          </span>
        </Typography>
        <Box className={classes.htmlDescription}>
          <HTMLComponent
            html={mentor.shortBio}
            fontSize={"regular"}
            textColor={"dark-090"}
          />
        </Box>
      </Box>
    </Link>
  );
}
