import { useQuery } from "@tanstack/react-query";
import { Box, Container, Typography } from "@mui/material";
import classes from "./index.module.scss";
import { api } from "../../../../instances/axiosInstances";
import ImageClip from "../../../../components/Common/ImageClip/ImageClip";
import { WhiteButton } from "../../../../components/MUI/buttons/button";
import { Link } from "react-router-dom";

export default function TopStudents({ data }) {
  const itemsRes = useQuery(
    ["topStudents"],
    () => api.get(`/usersRating/unregistered`),
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
    }
  );

  const items = itemsRes.isSuccess
    ? itemsRes.data?.data?.usersRating?.users
    : [];

  if (!data || !items.length) return null;

  if (data?.custom_fields?.display_section[0] === "off") return null;

  return (
    <Box className={classes.section} component="section">
      <Container className={classes.container} maxWidth="lg">
        <Box>
          <Typography component="h2" className={classes.title}>
            {data?.title?.rendered}
          </Typography>
          <Box
            className={classes.description}
            dangerouslySetInnerHTML={{
              __html: data?.content?.rendered,
            }}
          />
        </Box>
        <Box className={classes.items}>
          {items.slice(0, 3).map((item, index) => (
            <Box key={index} component="article">
              <Box className={classes.imgContainer}>
                <ImageClip imgUrl={item?.profilePhotoUrl} variant="user" />
              </Box>
              <Typography className={classes.itemTitle}>
                {item?.fullName}
              </Typography>
              <Typography className={classes.itemScore}>
                💎 {item?.points?.score}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box className={classes.btnBox}>
          <WhiteButton
            isBig={false}
            component={Link}
            variant="contained"
            className={classes.button}
            to={data?.custom_fields?.button_link[0]}
          >
            {data?.custom_fields?.button}
          </WhiteButton>
        </Box>
      </Container>
    </Box>
  );
}
