import { useEffect } from "react";
import Header from "../components/Header";
import Main from "../components/Main";
import Footer from "../components/Footer";

export default function LandingLayout({ children }) {
  useEffect(() => {
    localStorage.removeItem("redirectPath");
  }, []);

  return (
    <>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </>
  );
}
