import { useRef } from "react";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Intersection } from "@splidejs/splide-extension-intersection";
import "@splidejs/react-splide/css";
import classes from "../index.module.scss";
import CarouselNavigation from "../../../../../components/Common/CarouselNavigation";
import { AccentButton } from "../../../../../components/MUI/buttons/button";

export default function MobSlider({ data }) {
  const splideRef = useRef(null);

  return (
    <Box className={classes.slider}>
      <Splide
        ref={splideRef}
        extensions={{ Intersection }}
        options={{
          arrows: false,
          pagination: false,
          rewind: true,
          gap: "1rem",
          type: "loop",
          autoplay: "pause",
          interval: 3000,
          perPage: 1,
          perMove: 1,
          omitEnd: true,
          intersection: {
            inView: {
              autoplay: false,
            },
            outView: {
              autoplay: false,
            },
          },
        }}
        aria-label="Insiders Edge"
      >
        {data.map((item, index) => (
          <SplideSlide className={classes.slide} key={index}>
            <Box className={classes.item} component="article">
              <Box>
                <Box className={classes.itemIndex}>{index + 1}</Box>
                <Box>
                  <Typography className={classes.itemTitle} component="h3">
                    {item?.title?.rendered}
                  </Typography>
                  <Box
                    className={classes.itemDescription}
                    dangerouslySetInnerHTML={{
                      __html: item?.content?.rendered,
                    }}
                  />
                </Box>
              </Box>
              <Box>
                <Box className={classes.itemImgContainer}>
                  <img src={item?.img_url?.large} alt={item?.title?.rendered} />
                </Box>
                <Box className={classes.itemButton}>
                  <AccentButton
                    isBig={false}
                    component={Link}
                    variant="contained"
                    to={item?.custom_fields?.button_link[0]}
                    {...(item?.custom_fields?.external_link &&
                    item?.custom_fields?.external_link[0] === "true"
                      ? { target: "_blank", rel: "noreferrer" }
                      : {})}
                  >
                    {item?.custom_fields?.button}
                  </AccentButton>
                </Box>
              </Box>
            </Box>
          </SplideSlide>
        ))}
      </Splide>
      <Box className={classes.nav}>
        <CarouselNavigation splideRef={splideRef} variant="outlined" />
      </Box>
    </Box>
  );
}
