import { useQuery } from "@tanstack/react-query";
import axios from "axios";

async function fetchSection({ slug }) {
  const response = await axios.get(
    `${process.env.REACT_APP_WP_PATH}sections?slug=${slug}&_fields=id,title,content`
  );
  const data = { section: response.data };
  return data;
}

export function useSection({ slug }) {
  return useQuery({
    queryKey: ["rating-rules", slug],
    queryFn: () => fetchSection({ slug }),
    staleTime: Infinity,
  });
}
