import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useMutation } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import { Box } from "@mui/system";
import classes from "./index.module.scss";
import errorToast from "../../../components/toasts/errorToast";
import successToast from "../../../components/toasts/successToast";
import { Typography } from "@mui/material";
import { WhiteButton } from "../../../components/MUI/buttons/button";
import useMixpanel from "../../../hooks/useMixpanel";
import { mixpanelResendForgotenPassword } from "../../../constants/mixpanel";
import BackLink from "../../../components/Common/BackLink";

const ResendForgottenPassword = () => {
  const { user } = useContext(AuthContext);
  const { handleEventTrack } = useMixpanel();
  const { resetPasswordButton } = mixpanelResendForgotenPassword;

  const { mutate, isLoading } = useMutation(
    (data) => api.post("/auth/forgotPassword", data),
    {
      onSuccess: ({ data }) => {
        handleEventTrack(null, resetPasswordButton)
        successToast(data.message);
      },
      onError: (error) => {
        errorToast(error.response.data.message);
      },
    }
  );

  const onClick = () => {
    const newUser = user || JSON.parse(localStorage.getItem("user"));
    newUser && mutate(newUser);
  };

  return (
    <Box className={classes.wrapper}>
      <BackLink path={"/forgot-password"} />
      <Typography component={"h2"} variant={"h2"} className={classes.title}>
        Forgot password
      </Typography>
      <Typography className={classes.text}>
        We have sent you a password recovery link to{" "}
        <span>
          {user?.email ||
            JSON.parse(localStorage.getItem("user"))?.email ||
            "Your email"}
        </span>
      </Typography>

      <WhiteButton
        isBig={false}
        variant="outlined"
        disabled={isLoading}
        fullWidth
        onClick={onClick}
        className={classes.button}
      >
        {isLoading ? "Loading..." : "Resend confirmation link"}
      </WhiteButton>
    </Box>
  );
};

export default ResendForgottenPassword;
