import SectionTitle from "../components/SectionTitle";
import SectionText from "../components/SectionText";
import SectionGrid from "../components/SectionGrid";
import classes from "./index.module.scss";
import HTMLComponent from "../../../../components/Common/HTMLComponent";

export default function WhatToLearn({ whatToLearn, type, className = "" }) {
  const renderTitle = (type) => {
    switch (type) {
      case "live":
        return "Why you should sign up";
      default:
        return "What you'll learn";
    }
  };
  if (!whatToLearn || !whatToLearn.length) {
    return null;
  }
  return (
    <SectionGrid className={className}>
      <SectionTitle>{renderTitle(type)}</SectionTitle>
      {whatToLearn.toString().includes("</") ? (
        <HTMLComponent listStyle={"check"} html={whatToLearn} />
      ) : (
        <ul className={classes.list}>
          {whatToLearn.map((item, index) => (
            <li key={index} className={classes.list__item}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.99997 15.586L6.70697 12.293L5.29297 13.707L9.99997 18.414L19.707 8.70697L18.293 7.29297L9.99997 15.586Z"
                  fill="#2BAD28"
                />
              </svg>
              <SectionText>{item}</SectionText>
            </li>
          ))}
        </ul>
      )}
    </SectionGrid>
  );
}
