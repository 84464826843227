import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEvents } from "./hooks/useEvents";
import classes from "./index.module.scss";
import { Box, Container, Typography } from "@mui/material";
import { LandingLayout } from "../../../components/Layouts";
import Loader from "../../../components/Common/Loader";
import List from "./List";
import SubscribeForm from "../../../components/Common/SubscribeForm";
import Discord from "../../../components/Common/Discord";
import Pagination from "../../../components/Common/Pagination";
import { ReactComponent as NotFoundIcon } from "./images/not-found.svg";
import Filters from "../../../components/Common/PostFilters";
import { useEventsTags } from "./hooks/useEventsTags";
import Categories from "./Categories";
import { useEventsCategories } from "./hooks/useEventsCategories";

export default function Events() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const [page, setPage] = useState(Number(searchParams.get("page")) || 1);
  const [tags, setTags] = useState(searchParams.get("tags")?.split(",") || []);
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const [category, setCategory] = useState(
    searchParams.get("categories") || ""
  );

  const perPage = searchParams.get("per_page") || 10;

  const setDynamicParams = (paramName, value) => {
    searchParams.set(paramName, value);
    const newSearchString = searchParams.toString();
    navigate(
      {
        search: newSearchString,
      },
      { replace: true }
    );
  };

  const deleteDynamicParams = (paramName) => {
    searchParams.delete(paramName);
    const newSearchString = searchParams.toString();
    navigate(
      {
        search: newSearchString,
      },
      { replace: true }
    );
  };

  const { data, isFetching } = useEvents({
    page,
    perPage,
    search,
    tags,
    category,
  });
  const tagsList = useEventsTags();
  const categoriesList = useEventsCategories();

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      clearTimeout(timeout);
    }, 100);
  }, [data]);

  useEffect(() => {
    setDynamicParams("page", page);
  }, [page]);

  useEffect(() => {
    setDynamicParams("per_page", perPage);
  }, [perPage]);

  useEffect(() => {
    tags && tags.length
      ? setDynamicParams("tags", tags)
      : deleteDynamicParams("tags");
  }, [tags]);

  useEffect(() => {
    category
      ? setDynamicParams("categories", category)
      : deleteDynamicParams("categories");
  }, [category]);

  useEffect(() => {
    search ? setDynamicParams("search", search) : deleteDynamicParams("search");
  }, [search]);

  return (
    <LandingLayout>
      {isFetching ? <Loader /> : null}
      <Container maxWidth={"lg"} className={classes.container}>
        <Box className={classes.wrapper}>
          <Typography variant={"h1"} component={"h1"} className={classes.title}>
            🗓️️ Events
          </Typography>
          <Box component={"section"} className={classes.content}>
            <Box className={classes.events}>
              <Categories
                category={category}
                categoriesList={categoriesList?.data}
                setCategory={setCategory}
              />
              <Box className={classes.events__filters}>
                <Filters
                  setSearch={setSearch}
                  search={search}
                  setPage={setPage}
                  setTags={setTags}
                  tags={tags}
                  tagsList={tagsList?.data}
                  page={page}
                />
              </Box>
              <List events={data?.events} />
              {(!data || !data?.events || !data?.events.length) &&
              !isFetching ? (
                <Typography className={classes.empty}>
                  <NotFoundIcon />
                  Sorry, we couldn't find the events you are looking for.
                </Typography>
              ) : null}
              <Pagination
                count={Number(data?.totalPages)}
                page={page}
                setPage={setPage}
                disabled={isFetching}
              />
            </Box>
            <Box component={"aside"} className={classes.sidebar}>
              <Box className={classes.sidebar__filters}>
                <Filters
                  setSearch={setSearch}
                  search={search}
                  setPage={setPage}
                  setTags={setTags}
                  tags={tags}
                  tagsList={tagsList?.data}
                  page={page}
                />
              </Box>
              <Discord />
              <SubscribeForm />
            </Box>
          </Box>
        </Box>
      </Container>
    </LandingLayout>
  );
}
