import { Box } from "@mui/material";
import classes from "./index.module.scss";

export default function TagsList({ page, setPage, setTags, tags, tagsList }) {
  const onTagClick = (id) => {
    page !== 1 && setPage(1);
    setTags((prev) => {
      return prev.some((tagId) => Number(tagId) === Number(id))
        ? prev.filter((tagId) => Number(tagId) !== Number(id))
        : [...prev, id];
    });
  };

  if (!tagsList || !tagsList.length) return null;

  return (
    <Box className={classes.tags} component={"ul"}>
      {tagsList.map((tag) => (
        <Box
          key={tag.id}
          className={`${classes.tags__item} ${
            tags.some((tagId) => Number(tagId) === Number(tag.id))
              ? classes.tags__item_selected
              : ""
          }`}
          component={"li"}
          onClick={() => onTagClick(tag.id)}
        >
          {tag.name}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              d="M3.1126 9.71248L2.2876 8.88748L5.1751 5.99998L2.2876 3.11248L3.1126 2.28748L6.0001 5.17498L8.8876 2.28748L9.7126 3.11248L6.8251 5.99998L9.7126 8.88748L8.8876 9.71248L6.0001 6.82498L3.1126 9.71248Z"
              fill="#3D40D5"
            />
          </svg>
        </Box>
      ))}
    </Box>
  );
}
