import { useRef, useMemo } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Intersection } from "@splidejs/splide-extension-intersection";
import "@splidejs/react-splide/css";
import { Box } from "@mui/material";
import classes from "./index.module.scss";
import CarouselNavigation from "../../../../../components/Common/CarouselNavigation";
import PathCard from "../../../../../components/MUI/cards/PathCard";

export default function Slider({ data }) {
  const splideRef = useRef(null);

  const splideOptions = useMemo(() => {
    const itemCount = data.length;

    return {
      arrows: false,
      pagination: false,
      rewind: true,
      gap: "0.5rem",
      type: itemCount > 3 ? "loop" : "slide",
      autoplay: "pause",
      interval: 3000,
      perPage: itemCount >= 3 ? 3 : itemCount,
      perMove: 1,
      omitEnd: true,
      fixedWidth: "442px",
      intersection: {
        inView: {
          autoplay: false,
        },
        outView: {
          autoplay: false,
        },
      },
      breakpoints: {
        374: {
          perPage: 1,
          fixedWidth: "100%",
        },
        1024: {
          perPage: 1,
          fixedWidth: "320px",
        },
      },
    };
  }, [data.length]);

  return (
    <>
      <Splide
        ref={splideRef}
        extensions={{ Intersection }}
        options={splideOptions}
        aria-label="Pick Your Path"
      >
        {data.map((item, index) => (
          <SplideSlide className={classes.slide} key={index}>
            <PathCard path={item} />
          </SplideSlide>
        ))}
      </Splide>
      <Box
        className={`${classes.nav} ${data.length <= 3 && classes.hideDeskNav}`}
      >
        <CarouselNavigation splideRef={splideRef} variant="outlined" />
      </Box>
    </>
  );
}
