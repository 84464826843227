import { Chip as MUIChip } from "@mui/material";
import { styled } from "@mui/material/styles";


export const Chip = styled(MUIChip)(({ theme }) => ({
  fontFamily: "inherit",
  fontWeight: "700",
  fontSize: "0.75rem",
  lineHeight: 1,
  textTransform: "initial",
  padding: theme.spacing(0.5, 1.5),
  borderRadius: "1000px",
  width: "max-content",
  height: "min-content",
  minHeight: "1.5rem",
  alignItems: "center",

  "& .MuiChip-label": {
    padding: 0,
  },
}));
