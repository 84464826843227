import { Box } from "@mui/material";
import { Accordion, Summary, Details } from "../Accordion/ModuleAccordion";
import Header from "./Header";
import Content from "./Content";
import useAccordion from "../../../../hooks/useAccordion";
import classes from "./index.module.scss";

export default function Tasks({ tasks, title="Course preparation tasks" }) {
  const { expanded, handleChange } = useAccordion(false);

  if (!tasks || !tasks.length) {
    return null;
  }
  return (
    <Box className={classes.tasks}>
      <Accordion
        expanded={expanded}
        handleChange={handleChange}
        index={0}
      >
        <Summary index={0}>
          <Header title={title} count={tasks.length} />
        </Summary>
        <Details>
          <Content list={tasks} />
        </Details>
      </Accordion>
    </Box>
  );
}
