import { Box } from "@mui/material";
import Tasks from "../Tasks";
import Description from "../Description";
import Reviews from "../Reviews";
import Requirements from "../Requirements";
import WhatToLearn from "../WhatToLearn";
import WhoIsCourseFor from "../WhoIsCourseFor";
import Instructors from "../Instructors";
import FAQ from "../FAQ";
import WhatWeTalkAbout from "../WhatWeTalkAbout";
import Schedule from "../Schedule";
import ModulesLive from "../ModulesLive";
import CourseCardLive from "../Cards/CourseCardLive";
import classes from "./index.module.scss";
import SectionTitle from "../components/SectionTitle";

export default function Live({ data, feedbacks }) {
  return (
    <Box className={classes.course}>
      <CourseCardLive data={data} feedbacks={feedbacks} />
      <Box className={classes.wrapper}>
        <Box className={classes.left}>
          <WhatWeTalkAbout
            type={data.type}
            whatToTalkAbout={data.whatToTalkAbout}
            className={classes.whatWeTalkAbout}
          />
          <WhatToLearn
            whatToLearn={data.whatToLearn}
            className={classes.whatToLearn}
            type={data.type}
          />
          <Schedule
            className={classes.schedule__mobile}
            liveSchedule={data.liveSchedule}
            courseTimeZone={data?.courseTimeZone}
          />
          <Requirements
            className={classes.requirements__mobile}
            requirements={data.requirements}
          />
          <Box
            className={`${classes.content} ${classes.syllabus}`}
            id="syllabus"
          >
            <Box className={classes.content__header}>
              <SectionTitle>Course syllabus</SectionTitle>
            </Box>
            <Tasks
              tasks={data.preparationTasks}
              title={"Webinar preparation tasks"}
            />
            <ModulesLive modules={data.modules} />
          </Box>
          <Description
            description={data.description}
            className={classes.description}
          />
          <Reviews
            alumniFeedbacks={feedbacks}
            rating={data.rating}
            className={classes.alumniFeedbacks__desktop}
          />
        </Box>

        <Box className={classes.right}>
          <Schedule
            className={classes.schedule__desktop}
            liveSchedule={data.liveSchedule}
            courseTimeZone={data?.courseTimeZone}
          />
          <Requirements
            className={classes.requirements__desktop}
            requirements={data.requirements}
          />

          <WhoIsCourseFor whoIsCourseFor={data.whoIsCourseFor} />
          <Instructors
            instructors={data.instructors}
            alumniFeedbacks={data.alumniFeedbacks}
          />
          <FAQ faq={data.frequentlyAskedQuestions} />
          <Reviews
            alumniFeedbacks={feedbacks}
            rating={data.rating}
            className={classes.alumniFeedbacks__mobile}
          />
        </Box>
      </Box>
    </Box>
  );
}
