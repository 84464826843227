import { useQuery } from "@tanstack/react-query";
import { api } from "../../../../instances/axiosInstances";

async function fetchCertificate({ certificateId, isAuth }) {
  const response = await api.get(
    `/certificates${"/" + certificateId}${isAuth ? "" : "/unregistered"}`
  );

  const data = { certificate: response.data };
  return data;
}

export function useCertificate({ certificateId, isAuth }) {
  return useQuery({
    queryKey: isAuth
      ? ["userCertificate", "registered", certificateId]
      : ["userCertificate", "unregistered", certificateId],
    queryFn: () => fetchCertificate({ certificateId, isAuth }),
    staleTime: Infinity,
  });
}
