import { Box, Container } from "@mui/material";
import { useParams } from "react-router-dom";
import { useInstructor } from "./hooks/useInstructor";
import Loader from "../../../components/Common/Loader";
import { LandingLayout, PlatformLayout } from "../../../components/Layouts";
import BackLink from "../../../components/Common/BackLink";
import InstructorCard from "./InstructorCard";
import InstructorInfo from "./InstructorInfo";
import classes from "./index.module.scss";

export default function Instructor({ isAuth }) {
  const { courseId, instructorId } = useParams();

  const { data: instructorData, isFetching: instructorIsFetching } =
    useInstructor({
      id: instructorId,
    });

  const instructor = instructorData?.instructor.instructors;

  return (
    <>
      {instructorIsFetching ? <Loader /> : null}
      {isAuth ? (
        <PlatformLayout>
          {instructor ? (
            <Box className={classes.wrapper}>
              <Box className={classes.back}>
                <BackLink
                  path={`/courses/${courseId}`}
                  isLogoVisible={false}
                  text="Back to Course page"
                />
              </Box>
              <Box className={classes.body}>
                <InstructorCard instructor={instructor} isAuth={isAuth} />
                <InstructorInfo instructor={instructor} />
              </Box>
            </Box>
          ) : null}
        </PlatformLayout>
      ) : (
        <LandingLayout>
          <Container maxWidth={"lg"} className={classes.container}>
            <Box className={classes.back}>
              <BackLink
                path={`/courses/${courseId}`}
                isLogoVisible={false}
                text="Back to Course page"
              />
            </Box>

            {instructor ? (
              <Box className={classes.body}>
                <InstructorCard instructor={instructor} isAuth={isAuth} />
                <InstructorInfo instructor={instructor} />
              </Box>
            ) : null}
          </Container>
        </LandingLayout>
      )}
    </>
  );
}
