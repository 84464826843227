import { Pagination as MuiPagination, PaginationItem } from "@mui/material";

import classes from "./index.module.scss";

export default function Pagination({ count, page, setPage, disabled }) {
  if (!count || count <= 1) return null;
  return (
    <MuiPagination
      count={count}
      variant="outlined"
      shape="rounded"
      page={page}
      onChange={(_, page) => {
        setPage(page);
      }}
      disabled={disabled}
      renderItem={(item) => (
        <PaginationItem
          slots={{
            previous: ArrowBackIcon,
            next: ArrowNextIcon,
          }}
          disableRipple
          {...item}
        />
      )}
      boundaryCount={1}
      siblingCount={1}
      className={classes.pagination}
    />
  );
}

const ArrowNextIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M13.1499 9.40004C13.2672 9.51714 13.3331 9.676 13.3333 9.84171L13.3333 10.1584C13.3313 10.3237 13.2657 10.4819 13.1499 10.6L8.86658 14.875C8.78835 14.9539 8.68185 14.9983 8.57075 14.9983C8.45965 14.9983 8.35315 14.9539 8.27492 14.875L7.68325 14.2834C7.60486 14.2066 7.56069 14.1014 7.56069 13.9917C7.56069 13.882 7.60486 13.7768 7.68325 13.7L11.3916 10L7.68325 6.30004C7.60437 6.22181 7.56 6.11531 7.56 6.00421C7.56 5.89311 7.60437 5.78661 7.68325 5.70837L8.27492 5.12504C8.35316 5.04616 8.45965 5.00179 8.57075 5.00179C8.68185 5.00179 8.78835 5.04616 8.86659 5.12504L13.1499 9.40004Z"
        fill="#3D40D5"
      />
    </svg>
  );
};
const ArrowBackIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M6.85008 10.6C6.73284 10.4829 6.66689 10.324 6.66675 10.1583V9.84162C6.66867 9.67628 6.73434 9.51806 6.85008 9.39996L11.1334 5.12496C11.2117 5.04608 11.3181 5.00171 11.4292 5.00171C11.5403 5.00171 11.6468 5.04608 11.7251 5.12496L12.3167 5.71662C12.3951 5.79343 12.4393 5.89855 12.4393 6.00829C12.4393 6.11803 12.3951 6.22316 12.3167 6.29996L8.60841 9.99996L12.3167 13.7C12.3956 13.7782 12.44 13.8847 12.44 13.9958C12.44 14.1069 12.3956 14.2134 12.3167 14.2916L11.7251 14.875C11.6468 14.9538 11.5403 14.9982 11.4292 14.9982C11.3181 14.9982 11.2117 14.9538 11.1334 14.875L6.85008 10.6Z"
        fill="#3D40D5"
      />
    </svg>
  );
};
