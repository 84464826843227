import { Divider as MUIDivider } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Divider = styled(MUIDivider)(({ theme }) => ({
  fontFamily: "inherit",
  fontWeight: "400",
  fontSize: "0.875rem",
  lineHeight: 1,
  textTransform: "initial",
  color: theme.palette["dark-60"],

  "&:before": { top: 0, borderTop: "1px solid #D9D9D9" },
  "&:after": { top: 0, borderTop: "1px solid #D9D9D9" },
}));
