import {
  Autocomplete,
  Box,
  Checkbox,
} from "@mui/material";
import { ReactComponent as Chevron } from "../../assets/images/bx-chevron-down.svg.svg";
import { Input } from "../../components/MUI/inputs";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useEffect, useState } from "react";
import { experience } from "../../constants/experience";
import { topics } from "./topics";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


export default function Step2({
  register,
  errors,
  trigger,
  getValues,
  setValue,
}) {
  const [showCustomOptions, setShowCustomOptions] = useState(false);

  const updateObjectByLabel = (name, label, value) => {
    const updatedArray = getValues(name).map((obj) => {
      if (obj.label === label) {
        return { ...obj, value };
      }
      return obj;
    });
    setValue(name, updatedArray);
  };

  useEffect(() => {
    getValues("interestedTopics") &&
      setShowCustomOptions(
        Boolean(
          getValues("interestedTopics").filter(
            (topic) => topic.label === "Other options"
          ).length
        )
      );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    showCustomOptions &&
      getValues("otherTopics") &&
      updateObjectByLabel(
        "interestedTopics",
        "Other options",
        getValues("otherTopics")
      );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCustomOptions]);

  return (
    <>
      <Autocomplete
        multiple
        id="interestedTopics-select"
        fullWidth
        options={topics}
        autoHighlight
        disableCloseOnSelect
        getOptionLabel={(option) => {
          return option.label;
        }}
        renderTags={(value) => {
          const names = value.reduce((acc, option) => {
            return [...acc, option.label];
          }, []);

          return <span>{names.join(", ")}</span>;
        }}
        defaultValue={getValues("interestedTopics") || []}
        popupIcon={<Chevron />}
        onChange={(_, value) => {
          setValue("interestedTopics", value);
          setShowCustomOptions(
            Boolean(
              getValues("interestedTopics").filter(
                (topic) => topic.label === "Other options"
              ).length
            )
          );
        }}
        sx={{
          "& .MuiInputBase-root": {
            padding: "0 4rem 0 1rem",
            flexWrap: "nowrap",

            "& span": {
              width: "100%",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            },

            "& #interestedTopics-select": {
              paddingLeft: "0 !important",
              minWidth: "0 !important",
            },
          },
        }}
        renderOption={(props, option, { selected }) => {
          return (
            <Box component="li" {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </Box>
          );
        }}
        renderInput={(params) => (
          <Input
            {...params}
            label="Topics you are interested to learn"
            type="text"
            margin="normal"
            variant="outlined"
            fullWidth
            autoComplete="new-password"
            InputLabelProps={{
              shrink: true,
              disableAnimation: true,
            }}
            placeholder={
              getValues("interestedTopics") &&
              getValues("interestedTopics").length > 0
                ? ""
                : "Select topics"
            }
          />
        )}
      />

      {showCustomOptions ? (
        <Input
          label="Other topics"
          type="text"
          margin="normal"
          variant="outlined"
          fullWidth
          autoComplete="new-password"
          InputLabelProps={{
            shrink: true,
            disableAnimation: true,
          }}
          placeholder="Other topics"
          {...register("otherTopics", {
            onChange: () => {
              trigger(["otherTopics"]);
            },
          })}
          onChange={(e) => {
            updateObjectByLabel(
              "interestedTopics",
              "Other options",
              e.target.value
            );
          }}
          error={!!errors.otherTopics}
          helperText={errors.otherTopics && errors.otherTopics.message}
        />
      ) : null}

      <Input
        label="LinkedIn profile link"
        type="text"
        margin="normal"
        variant="outlined"
        fullWidth
        autoComplete="new-password"
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        placeholder="Link"
        {...register("linkedinLink", {
          onChange: () => trigger(["linkedinLink"]),
        })}
        error={!!errors.linkedinLink}
        helperText={errors.linkedinLink && errors.linkedinLink.message}
      />
      <Input
        label="What is your current company?"
        type="text"
        margin="normal"
        variant="outlined"
        fullWidth
        autoComplete="new-password"
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        placeholder="Company name"
        {...register("company", {
          onChange: () => trigger(["company"]),
        })}
        error={!!errors.company}
        helperText={errors.company && errors.company.message}
      />
      <Input
        label="Your position"
        type="text"
        margin="normal"
        variant="outlined"
        fullWidth
        autoComplete="new-password"
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        placeholder="Your position"
        {...register("position", {
          onChange: () => trigger(["position"]),
        })}
        error={!!errors.position}
        helperText={errors.position && errors.position.message}
      />
      <Autocomplete
        id="years-of-experience-select"
        fullWidth
        options={experience}
        autoHighlight
        getOptionLabel={(option) => option}
        defaultValue={
          getValues("yearsOfExperience") ? getValues("yearsOfExperience") : null
        }
        popupIcon={<Chevron />}

        sx={{
          "& .MuiInputBase-root": {
            padding: "0 4rem 0 0",
          },
        }}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            {option}
          </Box>
        )}
        renderInput={(params) => (
          <Input
            {...params}
            label="Years of experience"
            type="text"
            margin="normal"
            variant="outlined"
            fullWidth
            autoComplete="new-password"
            InputLabelProps={{
              shrink: true,
              disableAnimation: true,
            }}
            placeholder="Experience"
            {...register("yearsOfExperience")}
            error={!!errors.yearsOfExperience}
            helperText={
              errors.yearsOfExperience && errors.yearsOfExperience.message
            }
          />
        )}
      />
    </>
  );
}
