import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import useModal from "../components/Common/Modal/useModal";
export const WebinarPopupContext = createContext();

export default function WebinarPopupContextProvider({ children }) {
  const [isWebinarPopupFieldsLoaded, setIsWebinarPopupFieldsLoaded] =
    useState(false);

  const [wasWebinarPopupOpen, setWasWebinarPopupOpen] = useState(false);
  const [webinarPopupRef, setWebinarPopupRef] = useState(null);
  const [currentWebinarId, setCurrentWebinarId] = useState();

  const { pathname } = useLocation();
  const { handleOpen, handleClose } = useModal(webinarPopupRef);

  const handleWebinarPopupClose = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const handleWebinarPopupOpen = useCallback(() => {
    handleOpen();
    setWasWebinarPopupOpen(true);
  }, [handleOpen]);

  useEffect(() => {
    const webinars = localStorage.getItem("webinars");
    const isPopupHidden = webinars
      ? JSON.parse(webinars).some((id) => id === currentWebinarId)
      : false;
    let timeout;
    if (
      isWebinarPopupFieldsLoaded &&
      !pathname.includes("thank-you") &&
      !pathname.includes("lecture") &&
      !wasWebinarPopupOpen &&
      !isPopupHidden
    ) {
      timeout = setTimeout(() => {
        handleWebinarPopupOpen();
        clearTimeout(timeout);
      }, 10000);
    } else {
      clearTimeout(timeout);
    }

    return () => {
      clearTimeout(timeout);
      handleWebinarPopupClose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWebinarPopupFieldsLoaded, pathname, currentWebinarId]);

  const value = useMemo(
    () => ({
      wasWebinarPopupOpen,
      isWebinarPopupFieldsLoaded,
      webinarPopupRef,
      setWebinarPopupRef,
      setIsWebinarPopupFieldsLoaded,
      handleWebinarPopupClose,
      handleWebinarPopupOpen,
      setCurrentWebinarId,
    }),
    [
      handleWebinarPopupClose,
      handleWebinarPopupOpen,
      isWebinarPopupFieldsLoaded,
      wasWebinarPopupOpen,
      webinarPopupRef,
    ]
  );

  return (
    <WebinarPopupContext.Provider value={value}>
      {children}
    </WebinarPopupContext.Provider>
  );
}
