import { Link } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import classes from "./index.module.scss";
import ImageClip from "../../../../components/Common/ImageClip/ImageClip";
import { WhiteButton } from "../../../../components/MUI/buttons/button";
import { ReactComponent as ArrowIcon } from "../../../../assets/icons/Arrow-Right.svg";

export default function Webinar({ data }) {
  if (!data) return null;

  const { img_url, title, content, custom_fields } = data || {};
  const { button, button_link, date, tag, small_text, display_section } =
    custom_fields || {};

  if (display_section && display_section[0] === "off") return null;

  return (
    <Box className={classes.section} component="section">
      <Container maxWidth="lg">
        <Box className={classes.container}>
          <Box className={classes.content}>
            <Box className={classes.tag}>{tag}</Box>
            <Typography component="h2" className={classes.title}>
              {title?.rendered}
            </Typography>
            <Box
              className={classes.textContent}
              dangerouslySetInnerHTML={{
                __html: content?.rendered,
              }}
            />
            <Box className={classes.date}>{date}</Box>
            <Box className={classes.smallText}>{small_text}</Box>
            <WhiteButton
              isBig={false}
              component={Link}
              variant="contained"
              className={classes.button}
              to={button_link[0]}
              target="_blank"
              rel="noreferrer"
            >
              {button}
              <ArrowIcon />
            </WhiteButton>
          </Box>
          <ImageClip imgUrl={img_url?.full} variant="section" />
        </Box>
      </Container>
    </Box>
  );
}
