// component landing Header (EU)
const MIXPANEL_LANDING_HEADER = {
  page: "Landing",
  section: "Header",
};

const mixpanelLandingHeader = {
  coursesAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_HEADER,
      name: "Courses",
      component: "Header",
      element: "Anchor",
      elementInner: "Courses",
    },
  },
  freeResourcesAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_HEADER,
      name: "Free resources",
      component: "Header",
      element: "Anchor",
      elementInner: "Free resources",
    },
  },
  testimonialsAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_HEADER,
      name: "Testimonials",
      component: "Header",
      element: "Anchor",
      elementInner: "Testimonials",
    },
  },
  discordAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_HEADER,
      name: "Discord Community",
      component: "Header",
      element: "Anchor",
      elementInner: "Discord Community",
    },
  },
  studentPlatformLink: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_HEADER,
      name: "Student Platform",
      component: "Header",
      element: "Link",
      elementInner: "Student Platform",
    },
  },
  signUpLogin: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_HEADER,
      name: "Sign Up / Login",
      component: "Header",
      element: "button",
      elementInner: "Sign Up / Login",
    },
  },
};

// component landing Header burger (EU)
const MIXPANEL_LANDING_HEADER_BURGER = {
  page: "Landing",
  section: "Header burger",
};

const mixpanelLandingHeaderBurger = {
  coursesAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_HEADER_BURGER,
      name: "Courses",
      component: "Burger list",
      element: "Anchor",
      elementInner: "Courses",
    },
  },
  freeResourcesAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_HEADER_BURGER,
      name: "Free resources",
      component: "Burger list",
      element: "Anchor",
      elementInner: "Free resources",
    },
  },
  testimonialsAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_HEADER_BURGER,
      name: "Testimonials",
      component: "Burger list",
      element: "Anchor",
      elementInner: "Testimonials",
    },
  },
  discordAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_HEADER_BURGER,
      name: "Discord Community",
      component: "Burger list",
      element: "Anchor",
      elementInner: "Discord Community",
    },
  },
  studentPlatformLink: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_HEADER_BURGER,
      name: "Student Platform",
      component: "Burger list",
      element: "Link",
      elementInner: "Student Platform",
    },
  },
  signUpLogin: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_HEADER_BURGER,
      name: "Sign Up / Login",
      component: "Burger list",
      element: "button",
      elementInner: "Sign Up / Login",
    },
  },
};

// component landing Hero (EU)
const MIXPANEL_LANDING_HERO_DEFAULT = {
  page: "Landing",
  section: "Hero",
};

const mixpanelLandingHero = {
  startForFreeLink: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_HERO_DEFAULT,
      name: "Start for Free",
      component: "Intro",
      element: "Link",
      elementInner: "Courses",
    },
  },
  startForFreeAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_HERO_DEFAULT,
      name: "Start for Free",
      component: "Intro",
      element: "Anchor",
      elementInner: "Courses",
    },
  },
  watchVideo: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_HERO_DEFAULT,
      name: "Watch Video",
      component: "Intro",
      element: "Button",
      elementInner: "Watch Video",
    },
  },
};

// component Our Courses (EU)
export const MIXPANEL_LANDING_OUR_COURSES = {
  page: "Landing",
  section: "Our Courses",
};

const mixpanelLandingOurCourses = {
  courseDetailsLink: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_OUR_COURSES,
      name: "Course details",
      component: "CourseCard",
      element: "Link",
      elementInner: "Course details",
    },
  },
  navigationRightAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_OUR_COURSES,
      name: "Navigation right",
      component: "CourseCard",
      element: "Anchor",
      elementInner: "Navigation right",
    },
  },
  navigationLeftAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_OUR_COURSES,
      name: "Navigation left",
      component: "CourseCard",
      element: "Anchor",
      elementInner: "Navigation left",
    },
  },
};

// component landing Crushing Interview (EU)
const mixpanelLandingCrushingInterview = {
  crushInterviewAnchor: {
    eventName: "Click",
    options: {
      page: "Landing",
      section: "Crushing Interview",
      name: "Crush interview",
      component: "Content",
      element: "Anchor",
      elementInner: "Crush interview",
    },
  },
};

// component landing Individual mentorship (EU)
export const MIXPANEL_LANDING_INDIVIDUAL_MENTORSHIP_DEFAULT = {
  page: "Landing",
  section: "Individual mentorship",
};

const mixpanelLandingIndividualMentorShip = {
  watchIntroductionVideo: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_INDIVIDUAL_MENTORSHIP_DEFAULT,
      name: "Watch introduction video",
      component: "Image",
      element: "Button",
      elementInner: "Watch introduction video",
    },
  },
  enrollAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_INDIVIDUAL_MENTORSHIP_DEFAULT,
      name: "Enroll",
      component: "Content",
      element: "Anchor",
      elementInner: "Mentors",
    },
  },
};

// component landing 24/7 Discord Support (EU)
const mixpanelLandingDiscordSupport = {
  discordAnchor: {
    eventName: "Click",
    options: {
      page: "Landing",
      section: "24/7 Discord Support",
      name: "Join our Discord",
      component: "DiscordSupport",
      element: "Anchor",
      elementInner: "Discord",
    },
  },
};

// component landing Our YouTube videos (EU)
export const MIXPANEL_LANDING_OUR_YOUTUBE_VIDEOS = {
  page: "Landing",
  section: "Our Youtube videos",
};

const mixpanelLandingOurYouTubeVideos = {
  subscribeAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_OUR_YOUTUBE_VIDEOS,
      name: "Subscribe",
      component: "Our Youtube videos",
      element: "Anchor",
      elementInner: "YouTube",
    },
  },
  navigationRightAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_OUR_YOUTUBE_VIDEOS,
      name: "Navigation right",
      component: "Our Youtube videos",
      element: "Anchor",
      elementInner: "Navigation right",
    },
  },
  navigationLeftAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_OUR_YOUTUBE_VIDEOS,
      name: "Navigation left",
      component: "Our Youtube videos",
      element: "Anchor",
      elementInner: "Navigation left",
    },
  },
};

// component landing Our alumni (EU)
const MIXPANEL_LANDING_ALUMNI = {
  page: "Landing",
  section: "Alumni",
};

const mixpanelLandingAlumni = {
  navigationRightAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_ALUMNI,
      name: "Navigation right",
      component: "OurAlumniAboutUs",
      element: "Anchor",
      elementInner: "Navigation right",
    },
  },
  navigationLeftAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_ALUMNI,
      name: "Navigation left",
      component: "OurAlumniAboutUs",
      element: "Anchor",
      elementInner: "Navigation left",
    },
  },
  startForFreeLink: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_ALUMNI,
      name: "Start for free",
      component: "OurAlumniAboutUs",
      element: "Link",
      elementInner: "Courses",
    },
  },
  startForFreeAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_ALUMNI,
      name: "Start for free",
      component: "OurAlumniAboutUs",
      element: "Anchor",
      elementInner: "Courses",
    },
  },

};

// component landing Why join us (EU)
export const MIXPANEL_LANDING_WHY_JOIN_US_DEFAULT = {
  page: "Landing",
  section: "Why join us",
};

const mixpanelLandingWhyJoinUs = {
  startForFreeLink: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_WHY_JOIN_US_DEFAULT,
      name: "Start for Free",
      component: "WhyEnginiousUniversity",
      element: "Link",
      elementInner: "Courses",
    },
  },
  startForFreeAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_WHY_JOIN_US_DEFAULT,
      name: "Start for Free",
      component: "WhyEnginiousUniversity",
      element: "Anchor",
      elementInner: "Courses",
    },
  },
};

// component footer (EU)
const MIXPANEL_FOOTER = {
  section: "Footer",
};

const mixpanelFooter = {
  menuLogoLink: {
    eventName: "Click",
    options: {
      ...MIXPANEL_FOOTER,
      name: "Menu Logo",
      component: "Footer",
      element: "Link",
      elementInner: "Landing",
    },
  },
  eventsAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_FOOTER,
      name: "Events",
      component: "Footer",
      element: "Anchor",
      elementInner: "Events",
    },
  },
  communityAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_FOOTER,
      name: "Community",
      component: "Footer",
      element: "Anchor",
      elementInner: "Community",
    },
  },
  contactUsAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_FOOTER,
      name: "Contact us",
      component: "Footer",
      element: "Anchor",
      elementInner: "Contact us",
    },
  },
  youTubeAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_FOOTER,
      name: "You Tube",
      component: "Footer",
      element: "Anchor",
      elementInner: "You Tube",
    },
  },
  facebookAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_FOOTER,
      name: "Facebook",
      component: "Footer",
      element: "Anchor",
      elementInner: "Facebook",
    },
  },
  discordAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_FOOTER,
      name: "Discord",
      component: "Footer",
      element: "Anchor",
      elementInner: "Discord",
    },
  },
  phoneAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_FOOTER,
      name: "Phone",
      component: "Footer",
      element: "Anchor",
      elementInner: "Phone",
    },
  },
  emailAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_FOOTER,
      name: "Email",
      component: "Footer",
      element: "Anchor",
      elementInner: "Email",
    },
  },
};

// component Student Platform (EU)
const MIXPANEL_STUDENT_PLATFORM = {
  page: "Student platform",
};

const mixpanelStudentPlatform = {
  menuLogoLink: {
    eventName: "Click",
    options: {
      ...MIXPANEL_STUDENT_PLATFORM,
      section: "Sidebar",
      name: "Menu Logo",
      component: "Sidebar",
      element: "Link",
      elementInner: "Landing",
    },
  },
  coursesLink: {
    eventName: "Click",
    options: {
      ...MIXPANEL_STUDENT_PLATFORM,
      section: "Sidebar",
      name: "Courses",
      component: "Sidebar",
      element: "Link",
      elementInner: "Courses Student platform",
    },
  },
  pathsLink: {
    eventName: "Click",
    options: {
      ...MIXPANEL_STUDENT_PLATFORM,
      section: "Sidebar",
      name: "Paths",
      component: "Sidebar",
      element: "Link",
      elementInner: "Paths Student platform",
    },
  },
  courseDetailsStudLink: {
    eventName: "Click",
    options: {
      ...MIXPANEL_STUDENT_PLATFORM,
      section: "Courses",
      name: "Course details",
      component: "CourseCard",
      element: "Link",
      elementInner: "Course details",
    },
  },
  discordAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_STUDENT_PLATFORM,
      section: "Header",
      name: "Discord Community",
      component: "DesktopMenu",
      element: "Anchor",
      elementInner: "Discord",
    },
  },
  youTubeAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_STUDENT_PLATFORM,
      section: "Header",
      name: "Our YouTube",
      component: "DesktopMenu",
      element: "Anchor",
      elementInner: "YouTube",
    },
  },
  joinUsAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_STUDENT_PLATFORM,
      section: "Header",
      name: "Join us",
      component: "DesktopMenu",
      element: "Anchor",
      elementInner: "Facebook",
    },
  },
  profileButton: {
    eventName: "Click",
    options: {
      ...MIXPANEL_STUDENT_PLATFORM,
      name: "Profile",
      component: "UserMenu",
      element: "Button",
      elementInner: "Profile",
    },
  },
  logOutButton: {
    eventName: "Click",
    options: {
      ...MIXPANEL_STUDENT_PLATFORM,
      name: "Log Out",
      component: "UserMenu",
      element: "Button",
      elementInner: "Registration",
    },
  },
}


// component Student platform burger (EU)
const MIXPANEL_STUDENT_PLATFORM_BURGER = {
  page: "Student plaform",
  section: "burger",
  component: "Drawer",
};

const mixpanelStudentPlaformBurger = {
  coursesLink: {
    eventName: "Click",
    options: {
      ...MIXPANEL_STUDENT_PLATFORM_BURGER,
      name: "Courses",
      element: "Link",
      elementInner: "Courses",
    },
  },
  pathsLink: {
    eventName: "Click",
    options: {
      ...MIXPANEL_STUDENT_PLATFORM_BURGER,
      name: "Paths",
      element: "Link",
      elementInner: "Paths",
    },
  },
  profileLink: {
    eventName: "Click",
    options: {
      ...MIXPANEL_STUDENT_PLATFORM_BURGER,
      name: "Profile",
      element: "Link",
      elementInner: "Profile",
    },
  },
  discordAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_STUDENT_PLATFORM_BURGER,
      name: "Discord Community",
      element: "Anchor",
      elementInner: "Discord Community",
    },
  },
  youTubeAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_STUDENT_PLATFORM_BURGER,
      name: "Our YouTube",
      element: "Anchor",
      elementInner: "YouTube",
    },
  },
  joinUsAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_STUDENT_PLATFORM_BURGER,
      name: "Join us",
      element: "Anchor",
      elementInner: "facebook",
    },
  },
  logOutButton: {
    eventName: "Click",
    options: {
      ...MIXPANEL_STUDENT_PLATFORM_BURGER,
      name: "Log Out",
      element: "Button",
      elementInner: "Registration",
    },
  },
};

// component Registration (EU)
export const MIXPANEL_REGISTRATION = {
  page: "Registration",
  section: "Registration",
};

const mixpaneRegistration = {
  registrationNewUser: {
    eventName: "Registration",
    options: {
      ...MIXPANEL_REGISTRATION,
      component: "Registration",

    },
  },
  createAccountDiscordAnchor: {
    eventName: "Click",
    options: {
      page: "Create password",
      section: "Create password",
      name: "Create account Discord",
      component: "Step 1",

    },
  },
  resendConfirmationButton: {
    eventName: "Click",
    options: {
      page: "Resend password",
      section: "Resend password",
      name: "Resend confirmation link (sign up)",
      component: "ResendPassword",
      elementInner: "Resend confirmation link",
    },
  },
};

// component Sign In (Login) (EU)
export const MIXPANEL_SIGN_IN = {
  page: "Login",
  section: "Login",
};

const mixpanelSignIn = {
  signInBySocialMedia: {
    eventName: "SIgn In",
    options: {
      ...MIXPANEL_SIGN_IN,
      component: "SocialAuth",

    },
  },
  signInByEmail: {
    eventName: "SIgn In",
    options: {
      ...MIXPANEL_SIGN_IN,
      name: 'User by Email',
      component: "Login",
    },
  },
};

// component Resend Forgotten Passsword (EU)
const mixpanelResendForgotenPassword = {
  resetPasswordButton: {
    eventName: "Click",
    options: {
      page: "Forgot Password",
      section: "Forgot Password",
      name: "Reset password (sign in)",
      component: "ResendForgottenPassword",
      elementInner: "Forgot Password",
    },
  },
}

// component CourseCardLive (EU)
export const MIXPANEL_COURSE_CARD_LIVE = {
  page: "Course details (Live)",
  section: "CourseCardLive",
  component: "CourseCardLive",
};


const mixpanelCourseCardLive = {
  freeRegistrationButton: {
    eventName: "Click",
    options: {
      ...MIXPANEL_COURSE_CARD_LIVE,
      name: "Free Registration",
      element: "Button",
      elementInner: "BuyCourseModal",
    },
  },
  freeRegistrationLink: {
    eventName: "Click",
    options: {
      ...MIXPANEL_COURSE_CARD_LIVE,
      name: "Free Registration",
      element: "Link",
      elementInner: "Registration",
    },
  },
  buyForButton: {
    eventName: "Click",
    options: {
      ...MIXPANEL_COURSE_CARD_LIVE,
      element: "Button",
      elementInner: "BuyCourseModal",
    },
  },
  buyForLink: {
    eventName: "Click",
    options: {
      ...MIXPANEL_COURSE_CARD_LIVE,
      element: "Link",
      elementInner: "Registration",
    },
  },
  onlineLecturesAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_COURSE_CARD_LIVE,
      name: "Online lectures",
      element: "Anchor",
      elementInner: "Zoom link",
    },
  },
};

// component CourseCardRecorded (EU)
export const MIXPANEL_COURSE_CARD_RECORDED = {
  page: "Course details (Recorded)",
  section: "CourseCardRecorded",
  component: "CourseCardRecorded",
};
const mixpanelCourseCardRecorded = {
  buyForButton: {
    eventName: "Click",
    options: {
      ...MIXPANEL_COURSE_CARD_RECORDED,
      element: "Button",
      elementInner: "BuyCourseModal",
    },
  },
  buyForLink: {
    eventName: "Click",
    options: {
      ...MIXPANEL_COURSE_CARD_RECORDED,
      element: "Link",
      elementInner: "Registration",
    },
  },
  startFreeCourseLink: {
    eventName: "Click",
    options: {
      ...MIXPANEL_COURSE_CARD_RECORDED,
      name: "Start Free Course",
      element: "Link",
      elementInner: "Preparation tasks",
    },
  },
  startForFreeLink: {
    eventName: "Click",
    options: {
      ...MIXPANEL_COURSE_CARD_RECORDED,
      name: "Start for FREE",
      element: "Link",
      elementInner: "Preparation tasks",
    },
  },
  continueLink: {
    eventName: "Click",
    options: {
      ...MIXPANEL_COURSE_CARD_RECORDED,
      name: "Continue",
      element: "Link",
      elementInner: "lecture / preparation tasks",
    },
  },
};


// component Lecture (EU)
export const MIXPANEL_LECTURE = {
  page: "Lecture",
  section: "Lecture",
  component: "GetFullAccess",
};
const mixpanelLecture = {
  signUpLink: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LECTURE,
      name: "SignUp",
      element: "Link",
      elementInner: "Registration",
    },
  },
  buyForButton: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LECTURE,
      element: "Button",
      elementInner: "BuyCourseModal",
    },
  },
  buyForLink: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LECTURE,
      element: "Link",
      elementInner: "Registration",
    },
  },

};

// component Payment (EU)
const mixpanelPayment = {
  purchaseCourse: {
    eventName: "Purchase",
    options: {
      section: "Buy Course Modal",
      component: "Buy Course Modal",
    },
  },
};

// component CouponForm (EU)
const mixpanelCouponForm = {
  couponFormButton: {
    eventName: "Applied",
    options: {
      page: "CouponForm",
      section: "CouponForm",
      name: "Coupon",
      component: "CouponForm",
      element: "Button",
      elementInner: "CouponForm",
    },
  },
};

// component Live chat (EU)
const mixpanelLiveChat = {
  liveChatButton: {
    eventName: "Click",
    options: {
      name: "Live chat",
      component: "LiveChatButton",
      element: "Button",
      elementInner: "LiveChatButton",
    },
  },
};

export {
  mixpanelLandingHeader,
  mixpanelLandingHeaderBurger,
  mixpanelLandingHero,
  mixpanelLandingOurCourses,
  mixpanelLandingCrushingInterview,
  mixpanelLandingIndividualMentorShip,
  mixpanelLandingDiscordSupport,
  mixpanelLandingOurYouTubeVideos,
  mixpanelLandingAlumni,
  mixpanelLandingWhyJoinUs,
  mixpanelFooter,
  mixpanelStudentPlatform,
  mixpanelStudentPlaformBurger,
  mixpaneRegistration,
  mixpanelSignIn,
  mixpanelResendForgotenPassword,
  mixpanelCourseCardLive,
  mixpanelCourseCardRecorded,
  mixpanelLecture,
  mixpanelPayment,
  mixpanelCouponForm,
  mixpanelLiveChat,
};
