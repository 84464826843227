import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

export default function Carousel({ data }) {
  return (
    <Splide
      options={{
        type: "loop",
        autoplay: true,
        interval: 0,
        speed: 400000,
        pauseOnHover: false,
        pauseOnFocus: false,
        resetProgress: false,
        arrows: false,
        pagination: false,
        drag: "free",
        perMove: 1,
        perPage: 1,
        gap: "2.75rem",
        width: "100%",
        fixedWidth: "max-content",
      }}
    >
      {data.map((item, index) => (
        <SplideSlide key={index}>
          <img src={item?.img_url?.large} alt={item?.title?.rendered} />
        </SplideSlide>
      ))}
    </Splide>
  );
}
