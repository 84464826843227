import React from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import {
  Accordion as AccordionRoot,
  AccordionSummary,
  Box,
  Typography,
  styled,
} from "@mui/material";
import classes from "../index.module.scss";
import { AccentButton } from "../../../../../components/MUI/buttons/button";
import { Link } from "react-router-dom";

export default function Accordion({ data, expanded, onActiveIndexChange }) {
  const handleChange = (index) => (newExpanded) => {
    if (onActiveIndexChange) {
      onActiveIndexChange(newExpanded ? index : null);
    }
  };

  return (
    <Box className={classes.accordion}>
      <Box>
        {data.map((item, index) => (
          <Box className={classes.row} key={index}>
            <Box>
              <Box
                className={`${classes.itemIndex} ${
                  expanded !== index && classes.itemIndexInactive
                }`}
              >
                {index + 1}
              </Box>
            </Box>
            <StyledAccordionRoot
              expanded={expanded === index}
              onChange={handleChange(index)}
            >
              <StyledAccordionSummary
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography className={classes.itemTitle} component="h3">
                  {item?.title?.rendered}
                </Typography>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <Box
                  className={classes.itemDescription}
                  dangerouslySetInnerHTML={{
                    __html: item?.content?.rendered,
                  }}
                />
                <Box className={classes.itemButton}>
                  <AccentButton
                    isBig={false}
                    component={Link}
                    variant="contained"
                    to={item?.custom_fields?.button_link[0]}
                    {...(item?.custom_fields?.external_link &&
                    item?.custom_fields?.external_link[0] === "true"
                      ? { target: "_blank", rel: "noreferrer" }
                      : {})}
                  >
                    {item?.custom_fields?.button}
                  </AccentButton>
                </Box>
              </StyledAccordionDetails>
            </StyledAccordionRoot>
          </Box>
        ))}
      </Box>
      <Box>
        {data.map((item, index) => (
          <React.Fragment key={index}>
            {expanded === index ? (
              <Box className={classes.itemImgContainer}>
                <img src={item?.img_url?.full} alt={item?.title?.rendered} />
              </Box>
            ) : null}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
}

const StyledAccordionRoot = styled(AccordionRoot)(() => ({
  boxShadow: "none",
  overflow: "hidden",
  "&.Mui-expanded": {
    margin: 0,
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  padding: 0,
  border: "0px",
  marginTop: "8px",
  minHeight: "max-content",
  "&.Mui-expanded": {
    minHeight: "max-content",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  padding: 0,
  "& .MuiAccordionSummary-content": {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
}));
