import { Typography } from "@mui/material";
import classes from "./index.module.scss";

export default function SectionText({ children, ...rest }) {
  return (
    <Typography {...rest} className={classes.text}>
      {children}
    </Typography>
  );
}
