import { Box, Typography } from "@mui/material";
import { Rating } from "../../../../../../components/MUI";
import { ReactComponent as Avatar } from "../../../../../../assets/icons/avatar.svg";

import classes from "./index.module.scss";
import HTMLComponent from "../../../../../../components/Common/HTMLComponent";

export default function Review({ review }) {
  const options = review && {
    stars: Number(review.rating),
    readOnly: true,
  };

  return (
    <Box className={classes.review}>
      <Box className={classes.image}>
        {review.user && review.user.profilePhotoUrl ? (
          <img
            src={review.user.profilePhotoUrl}
            alt={review.userFullName || review.user.fullName}
            width={44}
          />
        ) : (
          <Avatar />
        )}
      </Box>
      <Box className={classes.content}>
        <Typography component={"h3"} className={classes.title}>
          {review.userFullName || review.user.fullName}
        </Typography>
        {review.rating ? (
          <Rating options={options} aria-label="Mentor rating" />
        ) : null}
        {review.text ? (
          <Box className={classes.feedback}>
            <HTMLComponent html={review.text} />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
