import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import classes from "./index.module.scss";

export default function BackLink({
  text = "Back",
  path = "/",
  onClick = () => false,
  isLogoVisible = true,
}) {
  return (
    <Box className={classes.wrapper}>
      <Typography
        variant={"h2"}
        component={Link}
        className={classes.link}
        to={path}
        onClick={onClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
        >
          <path
            d="M9.51344 2.49994L3.51344 8.49994L9.51344 14.4999L11 13.0133L6.48663 8.49994L11 3.98654L9.51344 2.49994Z"
            fill="#3D40D5"
          />
        </svg>
        {text}
      </Typography>
      {isLogoVisible ? (
        <Link to={"/"} className={classes.logo}>
          <Logo />
        </Link>
      ) : null}
    </Box>
  );
}
