import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useRating } from "../hooks/useRating";
import { useFilters } from "../hooks/useFilters";
import Loader from "../../../../components/Common/Loader";
import { ReactComponent as Avatar } from "../../../../assets/icons/avatar.svg";
import { updateStore, useStore } from "../store";
import { WhiteButton } from "../../../../components/MUI/buttons/button";
import { Input } from "../../../../components/MUI/inputs";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ModalContent from "./ModalContent";
import NotFound from "../../../../components/Common/NotFound";
import { useSection } from "../hooks/useSection";
import Rules from "./Rules";
import useModal from "../../../../components/Common/Modal/useModal";
import Modal from "../../../../components/Common/Modal";
import classes from "./index.module.scss";

export default function RatingScreen({ isAuth }) {
  const ref = useRef();
  const modalRef = useRef();
  const { handleOpen, handleClose } = useModal(modalRef);
  const [courseId, setCourseId] = useState("");
  const { pathname } = useLocation();
  const { data: list, isLoading: listLoading } = useRating({
    courseId,
    isAuth,
  });
  const { data: filters, isLoading: filtersLoading } = useFilters();
  const { data: rules, isLoading: rulesLoading } = useSection({
    slug: "user-rating-rules",
  });

  const position = list?.rating.usersRating.position;
  const rating = list?.rating.usersRating.users;
  const options = filters?.filters.courses;
  const currentFilter = options?.find((course) => course.id === courseId);
  const ratingRules = rules?.section[0];

  const step = 12;
  const defaultValue = 24;
  const key = `allUsersRating`;
  const itemsCount = useStore((state) => state[key]);

  const dUsers = rating && itemsCount ? rating.slice(0, itemsCount) : [];

  const renderPlace = (index) => {
    switch (index) {
      case 0:
        return "st";
      case 1:
        return "nd";
      case 2:
        return "rd";
      default:
        return "";
    }
  };

  const handleChange = (event) => {
    setCourseId(event.target.value);
  };

  useEffect(() => {
    rating &&
      rating.length &&
      !itemsCount &&
      updateStore({ key, itemsCount, defaultValue, step });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rating]);

  useEffect(() => {
    localStorage.removeItem("redirectPath");
  }, []);

  return (
    <Box className={classes.body}>
      {ratingRules ? (
        <Modal modalRef={modalRef}>
          <ModalContent handleClose={handleClose} rules={ratingRules} />
        </Modal>
      ) : null}
      {listLoading || filtersLoading || rulesLoading ? <Loader /> : null}
      <Box className={classes.header}>
        <Typography component={"h1"} variant={"h1"} className={classes.title}>
          Rating
        </Typography>
        {ratingRules ? (
          <WhiteButton
            className={classes.header__button}
            variant={"outlined"}
            isBig={false}
            onClick={handleOpen}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.5208 8.90417 12.7125 8.7125C12.9042 8.52083 13 8.28333 13 8C13 7.71667 12.9042 7.47917 12.7125 7.2875C12.5208 7.09583 12.2833 7 12 7C11.7167 7 11.4792 7.09583 11.2875 7.2875C11.0958 7.47917 11 7.71667 11 8C11 8.28333 11.0958 8.52083 11.2875 8.7125C11.4792 8.90417 11.7167 9 12 9ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                fill="#3D40D5"
              />
            </svg>
          </WhiteButton>
        ) : null}
      </Box>

      <Box className={classes.content}>
        <Box className={classes.rating}>
          {options ? (
            <FormControl variant="outlined" fullWidth>
              <Select
                ref={ref}
                className={classes.select}
                fullWidth
                value={courseId}
                onChange={handleChange}
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  style: {
                    width: "100%",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    className: classes.menuPaper,
                    style: {
                      width: ref.current ? ref.current.offsetWidth : "auto",
                    },
                  },
                  BackdropProps: {
                    sx: {
                      opacity: "0 !important",
                    },
                  },
                }}
                input={
                  <Input
                    label="Rating by"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    select
                    InputLabelProps={{
                      shrink: true,
                      disableAnimation: true,
                    }}
                  >
                    children
                  </Input>
                }
              >
                <MenuItem disableRipple className={classes.MenuItem} value="">
                  General
                </MenuItem>
                {options.map((option) => (
                  <MenuItem
                    disableRipple
                    className={classes.MenuItem}
                    key={option.id}
                    value={option.id}
                    title={option.title}
                  >
                    <span>{option.title}</span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
          {!listLoading && rating && position ? (
            <Typography className={classes.position}>
              Your place is {position} of {rating?.length}
            </Typography>
          ) : null}
          {!listLoading && !position && options && courseId ? (
            <Typography className={classes.position}>
              To see yourself in the rating and earn points, please register for
              a course{" "}
              <Link to={`/courses/${courseId}`}>{currentFilter.title}</Link>
            </Typography>
          ) : null}
          {!listLoading && !isAuth && options && !courseId ? (
            <Typography className={classes.position}>
              Please{" "}
              <Link
                onClick={() => localStorage.setItem("redirectPath", pathname)}
                to={`/registration`}
              >
                authorize
              </Link>{" "}
              on the platform to see more details on ratings
            </Typography>
          ) : null}
          {dUsers && dUsers.length ? (
            <Box className={classes.list}>
              {dUsers.map((user, index) => (
                <Box
                  to={
                    index + 1 === position ? "/dashboard" : `/rating/${user.id}`
                  }
                  key={user.id}
                  component={Link}
                  className={`${classes.list__item} ${
                    index + 1 === position ? classes.list__item_active : ""
                  }`}
                >
                  <Typography className={classes.place}>{`${
                    index + 1
                  }${renderPlace(index)}`}</Typography>
                  <Box className={classes.image}>
                    {user.profilePhotoUrl ? (
                      <img
                        src={user.profilePhotoUrl}
                        alt={user.fullName || "User"}
                        width={44}
                      />
                    ) : (
                      <Avatar />
                    )}
                  </Box>
                  
                  <Typography className={classes.name}>
                    {user.fullName || "User"}
                  </Typography>
                  <Typography className={classes.points}>
                    💎 <span>{user.points?.score ? user.points.score : 0}</span>
                  </Typography>
                </Box>
              ))}
            </Box>
          ) : !listLoading ? (
            <NotFound text={"No one bought the course"} />
          ) : null}

          {dUsers && dUsers.length && itemsCount < rating?.length ? (
            <WhiteButton
              isBig={false}
              variant="outlined"
              sx={{ width: "max-content", margin: "0 auto" }}
              onClick={() =>
                updateStore({
                  key,
                  itemsCount,
                  defaultValue,
                  step,
                })
              }
            >
              Load more
            </WhiteButton>
          ) : null}
        </Box>
        <Rules rules={ratingRules} />
      </Box>
    </Box>
  );
}
