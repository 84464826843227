import { formatTimeMilliseconds } from "../../../../../helpers/formatTime";

export const getLecturesDurationAndCount = (module) => {
  const lecturesCount = module.lectures.length;
  let lecturesDuration = module.lectures.reduce(
    (acc, { duration }) => acc + duration,
    0
  );
  lecturesDuration = formatTimeMilliseconds(lecturesDuration);
  return { lecturesCount, lecturesDuration };
};
