import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { IconButton, Modal, styled } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import image from "../../../assets/images/questionnaire.png";
import CloseIcon from "@mui/icons-material/Close";
import classes from "./index.module.scss";
import { WhiteButton } from "../../MUI/buttons/button";
import { QuestionnairePopupContext } from "../../../context/QuestionnairePopupContext";

const CustomModal = styled(Modal)(() => ({
  "&.MuiModal-root": {
    display: "flex",
    justifyContent: "center",
  },
  ".MuiBackdrop-root": {
    backgroundColor: "rgba(255, 255, 255, 0.57)",
  },
}));

export default function QuestionnaireModal() {
  const theme = useTheme();
  const { open, handleClose, isSkipPopupLoading, skipPopup } = React.useContext(
    QuestionnairePopupContext
  );

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-Questionnaire-Modal"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.body}>
        <Box className={classes.wrapper}>
          <Box className={classes.content}>
            <Box className={classes.header}>
              <Typography
                className={classes.title}
                variant="h2"
                component="h2"
                sx={{ color: theme.palette.white, fontWeight: 700 }}
              >
                Customize your learning experience{" "}
              </Typography>
              <IconButton
                aria-label="close Questionnaire modal"
                onClick={handleClose}
                className={classes.close}
              >
                <CloseIcon sx={{ color: theme.palette.white }} />
              </IconButton>
            </Box>

            <Typography
              className={classes.description}
              sx={{ color: theme.palette.white, opacity: 0.98 }}
            >
              <span className={classes.description__desktop}>
                Answer a few questions to get personal career advice and course
                recommendations.
              </span>
              <span className={classes.description__mobile}>
                Answer a few questions to get a free mentorship consultation
                from one of the seasoned engineers in your field. And course
                recommendations also.
              </span>
            </Typography>
            <Box className={`${classes.image} ${classes.image__mobile}`}>
              <img src={image} alt="Questionnaire" width={569} height={331} />
            </Box>
            <ol className={classes.list}>
              <li style={{ color: theme.palette.white, opacity: 0.98 }}>
                <Typography>
                  Get a free mentorship consultation from one of the seasoned
                  engineers in your field.
                </Typography>
              </li>
              <li style={{ color: theme.palette.white, opacity: 0.98 }}>
                <Typography>
                  Be the first one to know about new courses launch and special
                  offers
                </Typography>
              </li>
            </ol>

            <Box className={classes.actions}>
              <WhiteButton
                isBig={false}
                variant="contained"
                component={Link}
                endIcon={<ChevronRightIcon />}
                to="/questionnaire"
                onClick={handleClose}
                disabled={isSkipPopupLoading}
              >
                Questionnaire
              </WhiteButton>
              <button onClick={skipPopup} className={classes.button}>
                Don’t show me this again
              </button>
            </Box>
          </Box>
          <Box className={`${classes.image} ${classes.image__desktop}`}>
            <img src={image} alt="Questionnaire" width={569} height={331} />
          </Box>
        </Box>
      </Box>
    </CustomModal>
  );
}
