import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";
import Carousel from "./Carousel/Carousel";
import { useQuery } from "@tanstack/react-query";
import { apiCmsData } from "../../../../instances/axiosInstances";

export default function Companies({ data }) {
  const itemsRes = useQuery(["homeCompanies"], () =>
    apiCmsData.get(
      `/home-companies${process.env.REACT_APP_WP_HOME_QUERY_PARAMS}`
    )
  );

  const items = itemsRes.isSuccess ? itemsRes?.data?.data : [];

  if (!data || !items.length) return null;

  if (data?.custom_fields?.display_section[0] === "off") return null;

  return (
    <Box className={classes.section} component="section">
      <Typography component="h2" className={classes.title}>
        {data?.title?.rendered}
      </Typography>
      <Box className={classes.carousel}>
        <Carousel data={items} />
      </Box>
    </Box>
  );
}
