import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";
import BackLink from "../../../../components/Common/BackLink";
import { useQueryClient } from "@tanstack/react-query";
import avatar from "../../../../assets/images/avatar.png";
import CourseCard from "../../../../components/MUI/cards/CourseCard";
import { AccentButton } from "../../../../components/MUI/buttons/button";
import Share from "../../../../components/Common/Share";

export default function Details({ userId, certificate, isAuth, course }) {
  const queryClient = useQueryClient();
  const currentUser = queryClient.getQueryData(["user"]);
  const currentUserId = currentUser?.data?.users.id;
  const isCurrentUser = Number(currentUserId) === Number(userId);
  const currentCertificate = certificate?.certificate.usersRating;
  const currentCourse = course?.course?.courses;

  if (!currentCertificate) return null;
  return (
    <Box className={classes.body}>
      <BackLink
        text="Back to Dashboard"
        path={isCurrentUser ? "/dashboard" : `/rating/${userId}`}
        isLogoVisible={false}
      />
      <Box component={"section"} className={classes.content}>
        <Box className={classes.certificate}>
          <Box className={classes.certificate__image}>
            <img
              src={currentCertificate.imageCertificateUrl}
              alt="Certificate"
              width={741}
            />
          </Box>
          {isCurrentUser ? (
            <Box className={classes.certificate__actions}>
              <AccentButton
                isBig={false}
                component={"a"}
                href={currentCertificate.pdfCertificateUrl}
                download
                startIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 10L12 15M12 15L7 10M12 15V3"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
              >
                Download
              </AccentButton>
              <Share />
            </Box>
          ) : null}
        </Box>
        <Box component={"aside"} className={classes.sidebar}>
          <Box className={classes.sidebar__content}>
            <Box className={classes.user}>
              <Typography component={"h3"} className={classes.user__title}>
                Certificate recipient
              </Typography>
              <Box className={classes.user__card}>
                <Box className={classes.user__image}>
                  <img
                    src={currentCertificate.user.profilePhotoUrl || avatar}
                    alt={currentCertificate.user.fullName || "User"}
                    width={44}
                  />
                </Box>
                <Typography className={classes.user__name}>
                  {currentCertificate.user.fullName || "User"}
                </Typography>
              </Box>
            </Box>
            {currentCourse ? (
              <Box className={classes.course}>
                <Typography component={"h3"} className={classes.course__title}>
                  About the course
                </Typography>
                <CourseCard course={currentCourse} />
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
