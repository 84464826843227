import { Box, Typography } from "@mui/material";
import { Rating } from "../../../../../components/MUI";
import HTMLComponent from "../../../../../components/Common/HTMLComponent";
import { ReactComponent as Avatar } from "../../../../../assets/icons/avatar.svg";
import classes from "./index.module.scss";

export default function Slide({ feedback }) {
  const options = {
    stars: Number(feedback?.rating) || 0,
    readOnly: true,
  };
  return (
    <Box className={classes.card}>
      <Box className={classes.image}>
        {feedback?.user?.profilePhotoUrl ? (
          <img
            alt={feedback.user?.fullName || feedback.userFullName || "User"}
            src={feedback?.user?.profilePhotoUrl}
            width={44}
          />
        ) : (
          <Avatar />
        )}
      </Box>
      <Box className={classes.info}>
        <Typography className={classes.title}>
          {feedback.user?.fullName || feedback.userFullName || "User"}
        </Typography>

        {feedback.rating ? (
          <Rating options={options} spacing={0.5} direction={"row"} />
        ) : null}

        {feedback.text ? (
          <Box className={classes.feedback}>
            <HTMLComponent html={feedback.text} />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
