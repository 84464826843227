import { Box } from "@mui/material";
import { Accordion, Summary, Details } from "../Accordion/ModuleAccordion";
import Header from "./Header";
import Content from "./Content";
import useAccordion from "../../../../hooks/useAccordion";
import { ReactComponent as InfoIcon } from "../../../../assets/images/material-symbols_info-outline.svg";
import classes from "./index.module.scss";
import SectionText from "../components/SectionText";

export default function ModulesLive({ modules }) {
  const { expanded, handleChange } = useAccordion("panel0");
  if (!modules || !modules.length) {
    return null;
  }

  return (
    <Box className={classes.modules}>
      {modules.map((module, index) => (
        <Accordion
          key={module.id}
          expanded={expanded}
          handleChange={handleChange}
          index={index}
        >
          <Summary index={index}>
            <Header title={module.title} />
          </Summary>
          <Details>
            <Content list={module.liveLectures} />
          </Details>
        </Accordion>
      ))}
      <Box className={classes.info}>
        <InfoIcon className={classes.info__icon} />
        <SectionText>
          Engenious University reserves the right to change the modules' order
          to ensure the most efficient education process. All live lectures will
          be recorded and share with you thereafter. The course will include a
          mix of lectures, interactive discussions, hands-on exercises, and team
          activities to ensure active participation and practical learning
          experience for the students. Each week's content will be covered over
          two days, with time allocated for exercises and discussions.
        </SectionText>
      </Box>
    </Box>
  );
}
