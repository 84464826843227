import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";
import { AccentButton } from '../../MUI/buttons/button';
import { Input } from '../../MUI/inputs';

export default function SubscribeForm() {
  return (
    <Box className={classes.card}>
      <Typography
        component={"h3"}
        variant={"h3"}
        className={classes.card__title}
      >
        ✉️ Subscribe to get our latest news
      </Typography>

      <div className={classes.form}>
        <form
          acceptCharset="UTF-8"
          action="https://jvz601.infusionsoft.com/app/form/process/211ecfbefb5655d38fc43da3652c2341"
          className="infusion-form"
          id="inf_form_211ecfbefb5655d38fc43da3652c2341"
          method="POST"
        >
          <input
            name="inf_form_xid"
            type="hidden"
            value="211ecfbefb5655d38fc43da3652c2341"
          />
          <input
            name="inf_form_name"
            type="hidden"
            value="Web Form submitted"
          />
          <input
            name="infusionsoft_version"
            type="hidden"
            value="1.70.0.642516"
          />
          <div className="infusion-field">
            <Input
              className={classes.input}
              id="inf_field_Email"
              name="inf_field_Email"
              label="Email"
              type="email"
              required
              margin="normal"
              variant="outlined"
              fullWidth
              autoComplete="new-password"
              InputLabelProps={{
                shrink: true,
                disableAnimation: true,
              }}
              placeholder="Email"
            />
          </div>
          <div className="infusion-submit">
            <AccentButton
              isBig={false}
              variant="contained"
              type="submit"
              className={classes.button}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.60377 3.3807L5.57189 3.36657C5.34888 3.26772 5.12655 3.16916 4.93901 3.1052C4.76683 3.04647 4.42017 2.93918 4.03635 3.04433C3.60262 3.16316 3.25081 3.47578 3.08643 3.88846C2.94097 4.25365 3.01394 4.60415 3.05567 4.77879C3.10113 4.96902 3.17797 5.19686 3.25505 5.42538L3.26607 5.45805L5.31305 11.5304H11.7494C12.0104 11.5304 12.222 11.742 12.222 12.0029C12.222 12.2622 12.0131 12.473 11.7539 12.4755L5.31305 12.537L3.26192 18.5696C3.18399 18.7987 3.10636 19.0269 3.0603 19.2174C3.01809 19.392 2.9439 19.7431 3.08897 20.1092C3.25278 20.5227 3.60462 20.8361 4.03877 20.9554C4.42321 21.0611 4.77066 20.9534 4.9429 20.8946C5.1308 20.8305 5.35364 20.7318 5.57736 20.6326L21.6059 13.5308C21.8239 13.4342 22.0425 13.3374 22.213 13.2429C22.3714 13.1551 22.6773 12.9732 22.8516 12.6261C23.0495 12.232 23.0495 11.7696 22.8516 11.3754C22.6773 11.0284 22.3714 10.8464 22.213 10.7587C22.0425 10.6641 21.8238 10.5673 21.6059 10.4708L5.60377 3.3807Z"
                  fill="white"
                />
              </svg>
            </AccentButton>
          </div>
        </form>
      </div>
    </Box>
  );
}
