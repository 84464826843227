import {
  TasksAccordion,
  TasksAccordionSummary,
  TasksAccordionDetails,
} from "../parts";

export const Accordion = ({ index, children, expanded, handleChange }) => {
  
  return (
    <TasksAccordion
      key={index}
      expanded={expanded === `panel${index}`}
      onChange={handleChange(`panel${index}`)}
    >
      {children}
    </TasksAccordion>
  );
};

export const Summary = ({ index, children }) => {
  return (
    <TasksAccordionSummary
      aria-controls={`panel${index}d-content`}
      id={`panel${index}d-header`}
    >
      {children}
    </TasksAccordionSummary>
  );
};

export const Details = ({ children }) => {
  return <TasksAccordionDetails>{children}</TasksAccordionDetails>;
};
