import { useEffect, useState } from "react";

export default function useFinishedCourseLecturesCount({ course }) {
  const [finishedCourseLecturesCount, setFinishedCourseLecturesCount] =
    useState(0);
  const getFinishedLecturesCount = (course) => {
    const lecturesCount = course?.reduce((acc, module) => {
      const lecturesCount = module.lectures.filter(
        (lecture) => lecture?.userLecture?.isComplete
      ).length;

      return acc + lecturesCount;
    }, 0);
    setFinishedCourseLecturesCount(lecturesCount);
  };
  useEffect(() => {
    course && getFinishedLecturesCount(course?.modules);
  }, [course]);
  return { finishedCourseLecturesCount };
}
