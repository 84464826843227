import { Box, Typography } from "@mui/material";
import SquarePaymentForm from "./SquarePayment";
import classes from "./index.module.scss";

export default function Square({
  handleClose,
  courseId,
  coursePrice,
  courseDiscountedPrice,
  courseTitle,
}) {
  return (
    <Box className={classes.square}>
      <Typography className={classes.square__title}>Only for US residents</Typography>
      <SquarePaymentForm
        handleClose={handleClose}
        courseId={courseId}
        price={coursePrice}
        discountedPrice={courseDiscountedPrice}
        title={courseTitle}
      />
    </Box>
  );
}
