import { useQuery } from "@tanstack/react-query";
import { api } from "../../../../instances/axiosInstances";

async function fetchInstructor({ id }) {
  const response = await api.get(`/instructors/platform/${id}`);
  const data = { instructor: response.data };
  return data;
}

export function useInstructor({ id }) {
  return useQuery({
    queryKey: ["instructor", id],
    queryFn: () => fetchInstructor({ id }),
    staleTime: Infinity,
  });
}
