import { Box, Container } from "@mui/material";
import Intro from "./Intro";

import classes from "./index.module.scss";
import Cards from "./Cards";

export default function Hero() {
  return (
    <Container maxWidth="lg">
      <Box className={classes.body} component="section">
        <Intro />
        <Cards />
      </Box>
    </Container>
  );
}
