import { Link } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import classes from "./index.module.scss";
import { AccentButton } from "../../../../components/MUI/buttons/button";
import { useQuery } from "@tanstack/react-query";
import { apiCmsData } from "../../../../instances/axiosInstances";

export default function Resources({ data }) {
  const itemsRes = useQuery(["homeResources"], () =>
    apiCmsData.get(
      `/home-resources${process.env.REACT_APP_WP_HOME_QUERY_PARAMS}&order=asc`
    )
  );

  const items = itemsRes.isSuccess ? itemsRes?.data?.data : [];

  if (!data || !items.length) return null;

  const { title, content, custom_fields } = data || {};

  if (custom_fields?.display_section[0] === "off") return null;

  return (
    <Box className={classes.section} component="section">
      <Container maxWidth="lg">
        <Box>
          <Typography component="h2" className={classes.title}>
            {title?.rendered}
          </Typography>
          <Box
            className={classes.description}
            dangerouslySetInnerHTML={{
              __html: content?.rendered,
            }}
          />
        </Box>
        <Box className={classes.items}>
          {items.map((item, index) => (
            <Box className={classes.item} key={index} component="article">
              <Box className={classes.itemImg}>
                <img src={item?.img_url?.large} alt={item?.title?.rendered} />
              </Box>
              <Typography component="h3" className={classes.itemTitle}>
                {item?.title?.rendered}
              </Typography>
              <Box
                className={classes.itemDescription}
                dangerouslySetInnerHTML={{
                  __html: item?.content?.rendered,
                }}
              />
              <Box className={classes.itemButton}>
                <AccentButton
                  isBig={false}
                  component={Link}
                  variant="contained"
                  to={item?.custom_fields?.button_link[0]}
                  {...(item?.custom_fields?.external_link &&
                  item?.custom_fields?.external_link[0] === "true"
                    ? { target: "_blank", rel: "noreferrer" }
                    : {})}
                >
                  {item?.custom_fields?.button}
                </AccentButton>
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
}
