import { Box, Typography } from "@mui/material";
import { AccentButton } from "../../../components/MUI/buttons/button";
import { Link } from "react-router-dom";
import classes from "./index.module.scss";

export default function QuestionnaireUnfilled({ email }) {
  return (
    <Box>
      <Box className={classes.email}>
        <Typography className={classes.email__label}>Email</Typography>
        <Typography className={classes.email__address}>{email}</Typography>
      </Box>
      <Box className={classes.questionnaire}>
        <Box className={classes.questionnaire__content}>
          <Typography component={"h3"} className={classes.questionnaire__title}>
            ✏️ Enrollment questionnaire
          </Typography>
          <Typography className={classes.questionnaire__description}>
            We truly believe in practical and interactive approach as the best
            learning experience.{" "}
          </Typography>
        </Box>
        <Box className={classes.questionnaire__actions}>
          <AccentButton
            isBig={false}
            variant="contained"
            component={Link}
            to="/questionnaire"
          >
            Fill in the questionnaire
          </AccentButton>
        </Box>
      </Box>
    </Box>
  );
}
