import { useQuery } from "@tanstack/react-query";
import { Box, Container, Typography } from "@mui/material";
import classes from "./index.module.scss";
import MobSlider from "./MobSlider/MobSlider";
import DeskSlider from "./DeskSlider/DeskSlider";
import { apiCmsData } from "../../../../instances/axiosInstances";
import Accordion from "./Accordion/Accordion";
import { useState } from "react";

export default function InsidersEdge({ data }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const itemsRes = useQuery(["homeInsiders"], () =>
    apiCmsData.get(
      `/home-insiders${process.env.REACT_APP_WP_HOME_QUERY_PARAMS}&order=asc`
    )
  );

  const items = itemsRes.isSuccess ? itemsRes?.data?.data : [];

  if (!data || !items.length) return null;

  if (data?.custom_fields?.display_section[0] === "off") return null;

  const handleActiveIndexChange = (index) => {
    setActiveIndex(index);
  };

  return (
    <Box className={classes.section} component="section">
      <Container maxWidth="lg">
        <Typography className={classes.title} component="h2">
          {data?.title?.rendered}
        </Typography>
        <Box className={classes.mobile}>
          <MobSlider data={items} />
        </Box>
        <Box className={classes.tablet}>
          <Accordion
            data={items}
            expanded={activeIndex}
            onActiveIndexChange={handleActiveIndexChange}
          />
        </Box>
        <Box className={classes.desktop}>
          <DeskSlider data={items} />
        </Box>
      </Container>
    </Box>
  );
}
