import { useContext, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../../context/AuthContext";
import { useMutation } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import { Input } from "../../../components/MUI/inputs";
import { Box } from "@mui/system";
import classes from "./index.module.scss";
import errorToast from "../../../components/toasts/errorToast";
import {  Typography } from "@mui/material";
import { PASSWORD_REGEX } from "../../../constants/regex";
import { AccentButton } from "../../../components/MUI/buttons/button";
import BackLink from "../../../components/Common/BackLink";

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .matches(
      PASSWORD_REGEX,
      "Your password should have minimum eight characters, at least one uppercase letter, one lowercase letter and one digit"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Repeat password is required"),
});

const CreateForgottenPassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { setAuthUser, setAccessToken } = useContext(AuthContext);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { mutate, isLoading } = useMutation(
    (data) => api.post("/auth/resetPassword", data),
    {
      onSuccess: ({ data }) => {
        setAuthUser(data);
      },
      onError: (error) => {
        errorToast(error.response.data.message);
      },
    }
  );

  const onSubmit = (data) => {
    delete data.confirmPassword;
    mutate(data);
  };

  useEffect(() => {
    searchParams.get("token")
      ? localStorage.setItem("accessToken", searchParams.get("token")) &&
        setAccessToken(searchParams.get("token"))
      : navigate("/forgot-password");
  }, [searchParams, navigate, setAccessToken]);

  const password = useRef({});
  password.current = watch("password", "");

  return (
    <Box className={classes.wrapper}>
      <BackLink path={"/forgot-password"} />
      <Typography component={"h2"} variant={"h2"} className={classes.title}>
        Create new password
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="New password"
          type={"text"}
          margin="normal"
          variant="outlined"
          fullWidth
          autoComplete="new-password"
          disabled={isLoading}
          InputLabelProps={{
            shrink: true,
            disableAnimation: true,
          }}
          placeholder="Create new password"
          {...register("password")}
          error={!!errors.password}
          helperText={errors.password && errors.password.message}
        />
        <Input
          label="Repeat password"
          type={"text"}
          margin="normal"
          variant="outlined"
          fullWidth
          autoComplete="new-password"
          disabled={isLoading}
          InputLabelProps={{
            shrink: true,
            disableAnimation: true,
          }}
          placeholder="Repeat new password"
          {...register("confirmPassword")}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword && errors.confirmPassword.message}
        />

        <AccentButton
          isBig={false}
          className={classes.button}
          variant="contained"
          type="submit"
          disabled={isLoading}
          fullWidth
        >
          {isLoading ? "Loading..." : "Update password"}
        </AccentButton>
      </form>
    </Box>
  );
};

export default CreateForgottenPassword;
