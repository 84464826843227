import classes from "./index.module.scss";
import { Box, Typography } from "@mui/material";

export default function ModalHeader({
  title, handleClose
}) {
  return (
    <Box className={classes.header}>
      <Typography className={classes.title} component={"h2"}>
        {title}
      </Typography>
      <button className={classes.close} onClick={handleClose}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M6.225 19.425L4.575 17.775L10.35 12L4.575 6.22495L6.225 4.57495L12 10.35L17.775 4.57495L19.425 6.22495L13.65 12L19.425 17.775L17.775 19.425L12 13.65L6.225 19.425Z"
            fill="#3D40D5"
          />
        </svg>
      </button>
    </Box>
  );
}
