import classes from "./index.module.scss";
import SectionTitle from "../components/SectionTitle";
import SectionText from "../components/SectionText";
import SectionGrid from "../components/SectionGrid";
import HTMLComponent from "../../../../components/Common/HTMLComponent";

export default function WhatWeTalkAbout({
  whatToTalkAbout,
  type,
  className = "",
}) {
  const renderTitle = (type) => {
    switch (type) {
      case "live":
        return "Key course features";
      default:
        return "What we’ll talk about";
    }
  };
  if (!whatToTalkAbout || !whatToTalkAbout.length) {
    return null;
  }
  return (
    <SectionGrid className={className}>
      <SectionTitle>{renderTitle(type)}</SectionTitle>
      {whatToTalkAbout.toString().includes("</") ? (
        <HTMLComponent html={whatToTalkAbout} />
      ) : (
        <ul className={classes.list}>
          {whatToTalkAbout.map((item, index) => (
            <SectionText key={index} component={"li"}>
              {item}
            </SectionText>
          ))}
        </ul>
      )}
    </SectionGrid>
  );
}
