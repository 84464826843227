import { Box } from "@mui/material";
import classes from "./index.module.scss";
import SectionTitle from "../SectionTitle";
import Section from "../Section";
import Badge from "./Badge";
import { useCallback, useEffect, useRef } from "react";
import { WhiteButton } from "../../../../../components/MUI/buttons/button";
import { useStore, updateStore } from "../../store";
import { useParams } from "react-router-dom";
import NotFound from "../../../../../components/Common/NotFound";

export default function Badges({
  badges,
  isCurrentUser,
  windowWidth,
  currentUserId,
}) {
  const { id } = useParams();

  const step = 6;
  const defaultValue = 12;
  const key = `ratingBadgesPagination_user-${id || currentUserId}`;
  const itemsCount = useStore((state) => state[key]);

  const dBadges = badges && itemsCount ? badges.slice(0, itemsCount) : [];

  const observer = useRef();

  const lastBadgeElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (
            entries[0].isIntersecting &&
            itemsCount < badges.length &&
            windowWidth <= 768
          ) {
            updateStore({ key, itemsCount, defaultValue, step });
          }
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0.5,
        }
      );
      if (node) {
        observer.current.observe(node);
      }
    },
    [itemsCount, badges.length, windowWidth, key]
  );

  useEffect(() => {
    badges &&
      badges.length &&
      !itemsCount &&
      updateStore({ key, itemsCount, defaultValue, step });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [badges]);

  return (
    <Section>
      <SectionTitle title={"Badges"} />
      {badges && badges.length ? (
        <Box className={classes.desktop}>
          <Box className={classes.desktop__content}>
            {dBadges.map(({ badge }, index, self) => {
              if (
                self.length === index + 1 &&
                windowWidth <= 768 &&
                self.length < badges.length
              ) {
                return (
                  <Box
                    ref={lastBadgeElementRef}
                    key={index}
                    sx={{ flex: "0 0 8.875rem" }}
                  >
                    <Badge badge={badge} isCurrentUser={isCurrentUser} />
                  </Box>
                );
              } else {
                return (
                  <Box key={index} sx={{ flex: "0 0 8.875rem" }}>
                    <Badge badge={badge} isCurrentUser={isCurrentUser} />
                  </Box>
                );
              }
            })}
          </Box>
          {dBadges &&
          dBadges.length &&
          itemsCount < badges.length &&
          windowWidth > 768 ? (
            <WhiteButton
              isBig={false}
              variant="outlined"
              sx={{ width: "max-content", margin: "1.5rem auto" }}
              onClick={() =>
                updateStore({
                  key,
                  itemsCount,
                  defaultValue,
                  step,
                })
              }
            >
              Load more
            </WhiteButton>
          ) : null}
        </Box>
      ) : (
        <NotFound text={"Sorry, we couldn't find badges."} />
      )}
    </Section>
  );
}
