import { Box } from "@mui/material";
import { Stack } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import classes from "./index.module.scss";
import { useTheme } from "@emotion/react";
import { CloseButton } from "../../MUI/buttons";
import { Text } from "../../MUI";
import Loader from "../Loader";
import ReactPlayer from "react-player/lazy";
import { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../../context/ModalContext";

export default function VideoModal({ title, url }) {
  const theme = useTheme();
  const { handleModalClose } = useContext(ModalContext);

  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  useEffect(() => {
    setIsLoaderVisible(true)
  
    return () => {
      setIsLoaderVisible(false)
    }
  }, [])
  
  return (
    <Box className={classes.body}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        pb={1}
        pr={1}
      >
        <Text
          variant="text-24-const"
          sx={{ color: theme.palette.dark, fontWeight: 700 }}
        >
          {title}
        </Text>
        <CloseButton variant="contained" onClick={handleModalClose}>
          <CloseIcon sx={{ color: theme.palette.dark }}/>
        </CloseButton>
      </Stack>
      <Box className={classes.wrapper}>
        {isLoaderVisible ? <Loader /> : null}
        <ReactPlayer
          width="100%"
          height="100%"
          controls={true}
          playsinline={true}
          playing={true}
          onReady={() => setIsLoaderVisible(false)}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          url={url}
        />
      </Box>
    </Box>
  );
}