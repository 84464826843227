import { useEffect, useState } from "react";

export default function useAppSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
  });
  const appSize = () => { 
    setWindowSize({
      width: window.innerWidth,   
    });
  };

  useEffect(() => {
    window.addEventListener("resize", appSize);

    appSize();

    return () => {
      window.removeEventListener("resize", appSize);
    };
  }, []);

  return windowSize;
}
