import { Typography } from "@mui/material";
import classes from "./index.module.scss";
import HTMLComponent from "../../../../../components/Common/HTMLComponent";

export default function CardDescription({ description }) {
  return description.toString().includes("</") ? (
    <HTMLComponent
      html={description}
      fontSize={"regular"}
      textColor={"dark-060"}
    />
  ) : (
    <Typography className={classes.description}>{description}</Typography>
  );
}
