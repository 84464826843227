import { useRef } from "react";
import ModalContent from "./ModalContent";
import Tooltip from "./Tooltip";
import  Modal  from "../../../../../../components/Common/Modal";
import  useModal  from "../../../../../../components/Common/Modal/useModal";

import classes from "./index.module.scss";

export default function Badge({ badge, isCurrentUser }) {
  const modalRef = useRef();
  const { handleOpen, handleClose } = useModal(modalRef);

  return (
    <>
      <Modal modalRef={modalRef}>
        <ModalContent
          badge={badge}
          handleClose={handleClose}
          isCurrentUser={isCurrentUser}
        />
      </Modal>
      <button
        className={classes.badge}
        onClick={handleOpen}
        aria-haspopup="true"
      >
        <img
          className={classes.badge__image}
          src={badge.imageUrl}
          alt={badge.name}
          width={173}
        />
        <Tooltip title={badge.name} points={badge.points} />
      </button>
    </>
  );
}
