import { Navigate } from "react-router-dom";

export const QuestionnaireRoute = ({
  isAuthenticated,
  children,
  isAuthLoading,
  user,
}) => {
  if (!isAuthenticated && !isAuthLoading) {
    return <Navigate to="/login" replace />;
  }

  if (isAuthenticated && user.isQuestionnaireComplete) {
    return <Navigate to="/profile" replace />;
  }

  return children;
};

export const ProtectedRoute = ({
  isAuthenticated,
  children,
  isAuthLoading,
  redirectPath = "/",
}) => {
  if (!isAuthenticated && !isAuthLoading) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};
