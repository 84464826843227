import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

export const ModuleAccordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  borderRadius: "0.75rem",
  border: "1px solid var(--on-surface-b-005)",
  background: "var(--on-surface-a-100)",
  boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.10)",

  "&:before": {
    display: "none",
  },
}));

export const ModuleAccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M6 10.4867L12 16.4867L18 10.4867L16.5134 9.00012L12 13.5135L7.4866 9.00012L6 10.4867Z"
          fill="#3D40D5"
        />
      </svg>
    }
    {...props}
  />
))(() => ({
  background: "var(--on-surface-a-100)",
  flexDirection: "row",
  borderRadius: "0.75rem",
  padding: "0.75rem 0.75rem 0.75rem 1rem",
  minHeight: "unset",
  justifyContent: "space-between",
  gap: "0.5rem",

  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "scale(1)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "scale(-1)",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
}));

export const ModuleAccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: "0 0.75rem 0 1rem",
  border: "none",
  borderTop: "1px solid var(--colors-a-010)",
}));

export const FAQAccordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  borderRadius: "0.75rem",
  border: "1px solid var(--on-surface-b-005)",
  background: "var(--on-surface-a-100)",
  boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.10)",

  "&:before": {
    display: "none",
  },
}));

export const FAQAccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ExpandMoreIcon
        sx={{ fontSize: "1.5rem", color: "var(--colors-a-100)" }}
      />
    }
    {...props}
  />
))(() => ({
  background: "var(--on-surface-a-100)",
  flexDirection: "row",
  borderRadius: "0.75rem",
  padding: "0.75rem 0.75rem 0.75rem 1rem",
  minHeight: "unset",
  justifyContent: "space-between",
  gap: "0.5rem",

  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "scale(1)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "scale(-1)",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
}));

export const FAQAccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: "0 0.75rem 0.75rem 1rem",
  border: "none",
}));

export const TasksAccordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: `none`,
  background: "var(--on-surface-a-100)",
  boxShadow: "none",
  borderRadius: 0,
  flexGrow: 1,

  "&:not(:last-child)": {
    borderBottom: "1px solid var(--colors-a-010)",
  },

  "&:before": {
    display: "none",
  },
}));

export const TasksAccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M6 10.4867L12 16.4867L18 10.4867L16.5134 9.00012L12 13.5135L7.4866 9.00012L6 10.4867Z"
          fill="#3D40D5"
        />
      </svg>
    }
    {...props}
  />
))(() => ({
  background: "var(--on-surface-a-100)",
  flexDirection: "row",
  borderRadius: 0,
  padding: "0.75rem 0 0.75rem 2rem",
  justifyContent: "space-between",
  minHeight: "unset",
  gap: "0.5rem",

  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "scale(1)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "scale(-1)",
  },

  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
}));

export const TasksAccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: "0 0 0.75rem 2rem",
  border: "none",
}));
