import React from "react";
import { Box, Drawer } from "@mui/material";
import classes from "./index.module.scss";
import Content from "./Content";

export default function NavMenu({
  open,
  user,
  window,
  handleDrawerToggle,
  variant = "default",
}) {
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box className={classes.nav} component="nav">
      <Drawer
        onClose={handleDrawerToggle}
        container={container}
        anchor="right"
        open={open}
        ModalProps={{
          keepMounted: false,
          BackdropProps: {
            style: {
              backgroundColor: "rgba(0, 0, 0, 0.6)",
            },
          },
        }}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            maxWidth: "264px",
            boxSizing: "border-box",
            borderRadius: "24px 0 0 24px",
            background: "var(--accent-gradient)",
          },
        }}
      >
        <Content
          user={user}
          variant={variant}
          handleDrawerToggle={handleDrawerToggle}
        />
      </Drawer>
    </Box>
  );
}
