import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";

export default function Tooltip({ title, points }) {
  return (
    <Box className={classes.tooltip}>
      <Typography className={classes.tooltip__title}>{title}</Typography>
      {points ? (
        <Typography className={classes.tooltip__points}>
          +{points} points
        </Typography>
      ) : null}
    </Box>
  );
}
