import { Box, Container } from "@mui/material";
import Loader from "../../../../components/Common/Loader";
import { LandingLayout } from "../../../../components/Layouts";
import classes from "./index.module.scss";
import { useParams } from "react-router-dom";
import { useMentor } from "../hooks/useMentor";
import MentorCard from "./MentorCard";
import MentorInfo from "./MentorInfo";
import BackLink from "../../../../components/Common/BackLink";

export default function Mentor({ isAuth }) {
  const { id } = useParams();

  const { data: mentorData, isFetching: mentorIsFetching } = useMentor({
    id,
    isAuth,
  });

  const mentor = mentorData?.mentor.mentors;

  return (
    <LandingLayout>
      {mentorIsFetching ? <Loader /> : null}
      <Container maxWidth={"lg"} className={classes.container}>
        <Box className={classes.back}>
          <BackLink
            path={"/mentorship"}
            isLogoVisible={false}
            text="Back to All Mentors"
          />
        </Box>

        {mentor ? (
          <Box className={classes.body}>
            <MentorCard mentor={mentor} isAuth={isAuth} />
            <MentorInfo mentor={mentor} />
          </Box>
        ) : null}
      </Container>
    </LandingLayout>
  );
}
