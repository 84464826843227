import { Box } from "@mui/material";
import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { handleNext, handlePrev } from "../../../../helpers/carouselControls";
import classes from "./index.module.scss";
import { CarouselButton } from "../../../../components/MUI/buttons/button";

export default function CustomNavigation({ sliderRef, variant }) {
  return (
    <Box className={classes.navigation}>
      <CarouselButton variant={variant} onClick={() => handlePrev(sliderRef)}>
        <ChevronLeftIcon />
      </CarouselButton>
      <CarouselButton variant={variant} onClick={() => handleNext(sliderRef)}>
        <ChevronRightIcon />
      </CarouselButton>
    </Box>
  );
}
