import { Box, LinearProgress, Typography } from "@mui/material";

import classes from "./index.module.scss";
import { useEffect, useState } from "react";

export default function Progress({
  finished,
  all,
  isComplete,
  text = "courses",
}) {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    (finished || finished === 0) && all && setProgress((finished / all) * 100);
  }, [finished, all]);

  if((!finished && finished !==0) || !all) return null

  return (
    <Box className={classes.progress}>
      <Typography className={classes.progress__title} component="h2">
        {`Finished ${finished} / ${all} ${text}`}
      </Typography>

      <LinearProgress
        className={`${classes.progress__bar} ${
          isComplete || finished === all ? classes.progress__bar_green : ""
        }`}
        variant="determinate"
        value={progress}
      />
    </Box>
  );
}
