import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "../instances/axiosInstances";
import successToast from "../components/toasts/successToast";
import errorToast from "../components/toasts/errorToast";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { QuestionnairePopupContext } from "../context/QuestionnairePopupContext";

export default function useActivateFreeCourse({ id, title }) {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData(["user"])?.data;
  const { handleOpen: handleQuestionnairePopupOpen } = useContext(
    QuestionnairePopupContext
  );
  const { setIsFreePayment } = useContext(AuthContext);

  const activateFreeCourse = useMutation(
    (data) => api.post(process.env.REACT_APP_FREE_PAYMENT_PATH, data),
    {
      onSuccess: async ({ data }) => {
        window.dataLayer.push({
          event: "purchase",
          ecommerce: {
            transaction_id: data.transactions.id.toString(),
            currency: data.transactions.currency,
            value: 0,
            items: [
              {
                item_name: title,
                item_id: id.toString(),
                price: "0",
                discount: "0",
                quantity: "1",
              },
            ],
          },
        });
        successToast("You have full access to course content");
        handleQuestionnairePopupOpen(user?.users);
        localStorage.removeItem("isFreePayment");
        setIsFreePayment(false);

        await queryClient.invalidateQueries({
          queryKey: ["courses", "registered"],
          refetchType: "all",
          exact: true,
        });

        await queryClient.invalidateQueries({
          queryKey: ["my-courses", "registered"],
          refetchType: "all",
          exact: true,
        });

        await queryClient.invalidateQueries({
          queryKey: ["course", "content", "registered", id.toString()],
          refetchType: "all",
          exact: true,
        });

        await queryClient.invalidateQueries({
          queryKey: ["course", "registered", id.toString()],
          refetchType: "all",
          exact: true,
        });

        await queryClient.invalidateQueries({
          queryKey: ["lecture", "registered"],
          refetchType: "all",
          exact: true,
        });

        await queryClient.invalidateQueries({
          queryKey: ["user"],
          refetchType: "all",
          exact: true,
        });

        await queryClient.invalidateQueries({
          queryKey: ["paths", "registered"],
          refetchType: "all",
          exact: true,
        });

        await queryClient.invalidateQueries({
          queryKey: ["path", "registered"],
          refetchType: "all",
        });
      },

      onError: (error) => {
        localStorage.removeItem("isFreePayment");
        errorToast(error.response.data.message);
        setIsFreePayment(false);
      },
    }
  );
  return activateFreeCourse;
}
