import { Typography } from "@mui/material";
import classes from "./index.module.scss";

export default function SectionTitle({ title }) {
  return (
    <Typography component={"h2"} variant={"h2"} className={classes.title}>
     {title}
    </Typography>
  );
}
