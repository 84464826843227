import { Link } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import classes from "./index.module.scss";
import { AccentButton } from "../../../../components/MUI/buttons/button";

export default function Questions({ data }) {
  if (!data) return null;

  const { title, content, custom_fields, img_url } = data || {};

  if (custom_fields?.display_section[0] === "off") return null;

  return (
    <Box className={classes.section} component="section">
      <Container maxWidth="lg">
        <Box className={classes.container}>
          <Box>
            <img src={img_url?.large} alt={title?.rendered} />
          </Box>
          <Typography component="h2" className={classes.title}>
            {title?.rendered}
          </Typography>
          <Box
            className={classes.description}
            dangerouslySetInnerHTML={{
              __html: content?.rendered,
            }}
          />
          <Box className={classes.btnBox}>
            <AccentButton
              isBig={false}
              component={Link}
              variant="contained"
              target="_blank"
              rel="noreferrer"
              className={classes.button}
              to={custom_fields?.button_link[0]}
            >
              {custom_fields?.button}
            </AccentButton>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
