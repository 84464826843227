import React from "react";
import { LandingLayout } from "../../../../components/Layouts";
import classes from "./index.module.scss";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Loader from "../../../../components/Common/Loader";
import { Box, Container, Typography } from "@mui/material";
import NotFound from "../../NotFound";

function Template() {
  const { pathname } = useLocation();

  const { data, isLoading } = useQuery(
    ["conditions", pathname.replaceAll("/", "")],
    () =>
      axios.get(
        `${process.env.REACT_APP_WP_PATH}pages?slug=${pathname.replaceAll(
          "/",
          ""
        )}&_fields=id,modified,slug,title,content`
      )
  );

  if (!isLoading && !data?.data[0]) return <NotFound />;

  return (
    <LandingLayout>
      {isLoading ? <Loader /> : null}
      {data?.data ? (
        <Container maxWidth="lg">
          <Box className={classes.wrapper}>
            <Typography
              className={classes.title}
              component={"h1"}
              variant={"h1"}
            >
              {data?.data[0]?.title.rendered}
            </Typography>
            <Typography className={classes.modified}>
              Last Updated:{" "}
              {new Date(data?.data[0]?.modified).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </Typography>
            <Box
              className={classes.content}
              dangerouslySetInnerHTML={{
                __html: data?.data[0]?.content.rendered,
              }}
            />
          </Box>
        </Container>
      ) : null}
    </LandingLayout>
  );
}

export default Template;
