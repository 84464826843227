import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../../context/AuthContext";
import { useMutation } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";

import { Input } from "../../../components/MUI/inputs";
import { Box } from "@mui/system";
import classes from "./index.module.scss";
import SocialAuth from "../../../components/Common/SocialAuth";
import { Divider } from "../../../components/MUI/dividers";
import errorToast from "../../../components/toasts/errorToast";
import { EMAIL_REGEX } from "../../../constants/regex";
import { AccentButton } from "../../../components/MUI/buttons/button";
import Navigation from "../../../components/Layouts/AuthLayout/Navigation";
import BackLink from "../../../components/Common/BackLink";

const schema = yup.object().shape({
  email: yup
    .string()
    .matches(EMAIL_REGEX, "Invalid email address")
    .email()
    .required(),
});

const Registration = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { setUser, user } = useContext(AuthContext);

  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(
    (data) => api.post("/auth/confirmRegistration", data),
    {
      onSuccess: () => {
        localStorage.removeItem("sub");
        navigate("/registration/resend-password");
      },
      onError: (error) => {
        errorToast(error.response.data.message);
      },
    }
  );

  const onSubmit = (data) => {
    localStorage.setItem("user", JSON.stringify(data));
    setUser(data);
    mutate(data);
  };

  return (
    <Box className={classes.wrapper}>
      <BackLink path={localStorage.getItem("redirectPath") || "/"} />
      <Navigation />
      <SocialAuth />
      <Divider>Or use email</Divider>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Input
            label="Email"
            type="email"
            margin="normal"
            variant="outlined"
            fullWidth
            autoComplete="user-password"
            disabled={isLoading}
            defaultValue={user?.email || ""}
            InputLabelProps={{
              shrink: true,
              disableAnimation: true,
            }}
            placeholder="Email"
            {...register("email")}
            error={!!errors.email}
            helperText={errors.email && errors.email.message}
          />
        </Box>

        <AccentButton
          isBig={false}
          variant="contained"
          type="submit"
          disabled={isLoading}
          fullWidth
          className={classes.button}
        >
          {isLoading ? "Loading..." : "Register"}
        </AccentButton>
      </form>
    </Box>
  );
};

export default Registration;
