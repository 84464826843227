import { Button as MUIButton, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Button = styled(MUIButton)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: 1,
  textTransform: "initial",
  fontFamily: "inherit",
  padding: theme.spacing(1.75, 4),
  fontWeight: "700",
  borderRadius: "0.75rem",
  whiteSpace: "nowrap",
  boxShadow: "none",
  letterSpacing: "initial",
  minHeight: "3rem",
  "&:hover": {
    boxShadow: "none",
  },
  
  "&:focus": {
    boxShadow: "none",
  },

  "& .MuiSvgIcon-root": {
    width: "19px",
    height: "19px",
  },
  "& .MuiButton-endIcon": {
    width: "19px",
    height: "19px",
    marginRight: "-8px",
  },
}));

export const NavButton = styled(Button)(() => ({
  padding: 0,
  minHeight: 0,
  height:"min-content",
}));

export const PlayButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.accent,
  backgroundColor: theme.palette.white,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translateX(-50%) translateY(-50%)",
  width: "56px",
  height: "56px",
  boxShadow: "0px 20px 30px rgba(21, 23, 61, 0.07)",

  "& .MuiSvgIcon-root": {
    fontSize: "2rem",
  },
  "&:hover": {
    backgroundColor: theme.palette.white,
  },
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.white,
  backgroundColor: "transparent",
  width: "1.5rem",
  height: "1.5rem",

  "& .MuiSvgIcon-root": {
    fontSize: "1.75rem",
  },
}));

export const CarouselButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.accent,
  backgroundColor: "transparent",
  width: "2rem",
  height: "2rem",

  "& .MuiSvgIcon-root": {
    fontSize: "2.25rem",
  },
}));

export const AlumniCarouselButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.accent,
  backgroundColor: theme.palette["white"],
  width: "2.5rem",
  height: "2.5rem",
  borderRadius: "12px",
  "&:hover": {
    backgroundColor: theme.palette["white"],
  },
  "& .MuiSvgIcon-root": {
    fontSize: "1rem",
  },
}));
