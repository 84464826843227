import { Box, Container, Typography } from "@mui/material";
import { PlatformLayout } from "../../../components/Layouts";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import classes from "./index.module.scss";
import { useContext, useEffect } from "react";
import { AuthContext } from '../../../context/AuthContext';

export default function Courses() {
  const { auth } = useContext(AuthContext);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === "/courses") {
      navigate("/courses/all-courses");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <PlatformLayout>
      <Container disableGutters maxWidth={false} className={classes.container}>
        <Box className={classes.navigation}>
          <Typography
            to={"all-courses"}
            className={classes.title}
            variant={"h2"}
            component={NavLink}
          >
            All Courses
          </Typography>
          {auth ? <Typography
            to={"my-courses"}
            className={classes.title}
            variant={"h2"}
            component={NavLink}
          >
            My Courses
          </Typography> : null}
        </Box>

        <Outlet />
      </Container>
    </PlatformLayout>
  );
}
