import { useQuery } from "@tanstack/react-query";
import { api } from "../../../../instances/axiosInstances";

async function fetchRating({ courseId, isAuth }) {
  const response = await api.get(
    `/usersRating${isAuth ? "" : "/unregistered"}${
      courseId ? "/course/" + courseId : ""
    }`
  );

  const data = { rating: response.data };
  return data;
}

export function useRating({ courseId, isAuth }) {
  return useQuery({
    queryKey: isAuth
      ? ["usersRating", "registered", courseId ? courseId : "general"]
      : ["usersRating", "unregistered", courseId ? courseId : "general"],
    queryFn: () => fetchRating({ courseId, isAuth }),
    staleTime: Infinity,
  });
}
