import { Box, Container, Typography } from "@mui/material";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Footer from "../../../components/Layouts/components/Footer";
import React, { useEffect } from "react";
import usePages from "./hooks/usePages";
import { fetchCourse, fetchLecture } from "./queries/fetchers";
import { setLecturesState, setCourseState } from "./queries/states";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTheme } from "@emotion/react";
import BuyCourseModal from "../../../components/modal/BuyCourseModal";
import Header from "../../../components/Layouts/components/Header";
import Modules from "./Modules";
import classes from "./index.module.scss";
import Loader from "../../../components/Common/Loader";
import { getCurrentLectureIsFree } from "./helpers/lections";
import useActivateFreeCourse from "../../../hooks/useMutations";
import errorToast from "../../../components/toasts/errorToast";
import Workplace from "./Lecture/Workplace";
import ShowMore from "../../../components/Common/ShowMore";
import HTMLComponent from "../../../components/Common/HTMLComponent";

export default function OngoingLecture({ isAuth }) {
  const { id, lectureId } = useParams();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [errorStatus, setErrorStatus] = React.useState(null);
  const location = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const { data: course, isLoading: isCourseLoading } = useQuery({
    queryKey: setCourseState({ isAuth, id }),
    queryFn: () => fetchCourse({ isAuth, id }),
    retry: 1,
    staleTime: Infinity,
    onError: (error) => {
      setErrorStatus(error.response.status);
    },
  });

  const {
    mutate: activateFreeCourseMutation,
    isLoading: activateFreeCourseLoading,
  } = useActivateFreeCourse({ id, title: course?.data.courses?.title });

  const { pages } = usePages({ course });

  const {
    data: lecture,
    isLoading: lectureLoading,
    isFetching: lectureIsFetching,
  } = useQuery({
    queryKey: setLecturesState({ isAuth, lectureId }),
    queryFn: () => fetchLecture({ isAuth, lectureId }),
    enabled:
      !location.pathname.includes("preparation-tasks") &&
      !isCourseLoading &&
      !!course &&
      (getCurrentLectureIsFree(pages, lectureId) ||
        !!course?.data.courses?.isPaid),
    retry: 1,
    staleTime: Infinity,
    onError: (error) => {
      errorToast(error.response.status);
    },
  });

  const handleActivateFreeCourse = (id) => {
    let body = JSON.stringify({
      courseId: id,
    });

    activateFreeCourseMutation(body);
  };

  useEffect(() => {
    !!course &&
      !course?.data.courses?.isPaid &&
      Number(course?.data.courses?.price) === 0 &&
      !!id &&
      isAuth &&
      handleActivateFreeCourse(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    course?.data.courses?.isPaid,
    id,
    isAuth,
    course,
    course?.data.courses?.price,
  ]);

  return (
    <Container maxWidth={false} disableGutters className={classes.wrapper}>
      {isCourseLoading || lectureIsFetching || activateFreeCourseLoading ? (
        <Loader />
      ) : null}
      <Container maxWidth={"lg"} className={classes.container}>
        <Header />
        <Box className={classes.tabs}>
          <Outlet
            key={lectureId}
            context={{
              isPaid: course?.data.courses?.isPaid,
              image: course?.data.courses?.promoImageUrl,
              lecture,
              lectureLoading,
              lectureId,
              pages,
              courseId: course?.data.courses.id,
              price: course?.data.courses?.price,
              title: course?.data.courses?.title,
              modules: course?.data.courses?.modules,
              errorStatus,
              isCourseLoading: isCourseLoading,
              discountedPrice: course?.data.courses?.discountedPrice,
              promoImageUrl: course?.data.courses?.promoImageUrl,
              isAuth,
            }}
          />
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="ongoing lecture tabs"
            >
              <Tab label="Course content" {...a11yProps(0)} />
              <Tab label="Lecture" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
            className={classes.SwipeableViews}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Modules course={course} setValue={setValue} />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              {lecture?.data.coursesLectures?.linkTitle &&
              lecture?.data.coursesLectures?.link ? (
                <Workplace
                  linkTitle={lecture.data.coursesLectures.linkTitle}
                  link={lecture.data.coursesLectures.link}
                />
              ) : null}

              {lecture?.data.coursesLectures?.text ? (
                <ShowMore maxHeight={150} overlayColor="white">
                  {!!lecture?.data?.coursesLectures.text
                    .toString()
                    .match(/<[^>]+>/) ? (
                    <HTMLComponent html={lecture.data.coursesLectures.text} />
                  ) : (
                    <Typography className={classes.description}>
                      {lecture.data.coursesLectures.text}
                    </Typography>
                  )}
                </ShowMore>
              ) : null}
            </TabPanel>
          </SwipeableViews>
        </Box>
        <Box className={classes.body}>
          <Modules course={course} />
          <Box component={"main"} className={classes.main}>
            <Outlet
              key={lectureId}
              context={{
                isPaid: course?.data.courses?.isPaid,
                lecture,
                lectureLoading,
                lectureId,
                pages,
                courseId: course?.data.courses.id,
                errorStatus,
                price: course?.data.courses?.price,
                title: course?.data.courses?.title,
                modules: course?.data.courses?.modules,
                image: course?.data.courses?.promoImageUrl,
                discountedPrice: course?.data.courses?.discountedPrice,
                promoImageUrl: course?.data.courses?.promoImageUrl,
              }}
            />
          </Box>
          <BuyCourseModal course={course?.data?.courses} />
        </Box>
      </Container>
      <Box className={classes.footer}>
        <Footer />
      </Box>
    </Container>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      className={classes.tabpanel}
    >
      {value === index && (
        <Box
          sx={{
            paddingTop: 2,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}
