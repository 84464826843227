import { Box, Container, Typography } from "@mui/material";
import classes from "./index.module.scss";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import React, { useDeferredValue } from "react";
import Loader from "../../../components/Common/Loader";
import PathCard from "../../../components/MUI/cards/PathCard";
import { PlatformLayout } from "../../../components/Layouts";

export default function Paths() {
  const { data, isLoading } = useQuery(["paths", "unregistered"], () =>
    api.get(process.env.REACT_APP_PATHS_UNREGISTERED_PATH)
  );

  const deferredPaths = useDeferredValue(data?.data.paths || []);

  return (
    <PlatformLayout>
      {isLoading ? <Loader /> : null}
      <Container disableGutters maxWidth={false} className={classes.container}>
        <Typography className={classes.title} variant={"h1"} component={"h1"}>
          Paths
        </Typography>

        <Box className={classes.paths}>
          {data && !isLoading
            ? deferredPaths.map((path) => (
                <PathCard key={path.id} path={path} />
              ))
            : null}
          {!data && !isLoading ? <Box>There is no paths yet</Box> : null}
        </Box>
      </Container>
    </PlatformLayout>
  );
}
