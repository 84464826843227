import { CardMedia as MUICardMedia } from "@mui/material";
import { PlayButton } from "../../../../../components/MUI/buttons/button";
import { ReactComponent as Play } from "../../../../../assets/icons/bx-play.svg";

import classes from "./index.module.scss";

export default function CardMedia({
  promoImageUrl,
  title,
  promoVideoUrl,
  showVideoModal,
}) {
  return (
    <MUICardMedia className={classes.media}>
      <img src={promoImageUrl} alt={title} width="496" />
      {promoVideoUrl ? (
        <PlayButton
          className={classes.play}
          aria-label="play"
          onClick={() =>
            showVideoModal({
              title: title,
              url: promoVideoUrl,
            })
          }
        >
          <Play />
        </PlayButton>
      ) : null}
    </MUICardMedia>
  );
}
