import { CardActions as MUICardActions } from "@mui/material";

import classes from "./index.module.scss";

export default function CardActions({ children }) {
  return (
    <MUICardActions className={classes.actions}>
      {children}
    </MUICardActions>
  );
}
