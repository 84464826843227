import { GreyButton } from "../../MUI/buttons/button";

function DiscordLogin({ mutation }) {
  function login() {
    const params =
      "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,\n" +
      "width=500,height=700";

    const url = `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_DISCORD_REDIRECT_URI}&response_type=code&scope=identify%20email`;

    const popup = window.open(url, "Discord Auth", params);

    const interval = setInterval(() => {
      popup.postMessage("", process.env.REACT_APP_DISCORD_POST_MESSAGE_URI);
    }, 500);

    const messageListener = (event) => {
      if (event.data.code) {
        clearInterval(interval);
        popup.close();
        window.removeEventListener("message", messageListener);
        mutation({
          data: { accessToken: event.data.code },
          provider: "discord",
        });
      }
    };

    window.addEventListener("message", messageListener);
  }

  return (
    <GreyButton
      isBig={false}
      sx={{
        flex: "1 0 100%",
      }}
      type="button"
      startIcon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            d="M19.7701 5.32953C18.4401 4.70953 17.0001 4.25953 15.5001 3.99953C15.487 3.99911 15.4739 4.00157 15.4618 4.00676C15.4497 4.01194 15.4389 4.01971 15.4301 4.02953C15.2501 4.35953 15.0401 4.78953 14.9001 5.11953C13.3091 4.87953 11.6911 4.87953 10.1001 5.11953C9.96012 4.77953 9.75012 4.35953 9.56012 4.02953C9.55012 4.00953 9.52012 3.99953 9.49012 3.99953C7.99012 4.25953 6.56012 4.70953 5.22012 5.32953C5.21012 5.32953 5.20012 5.33953 5.19012 5.34953C2.47012 9.41953 1.72012 13.3795 2.09012 17.2995C2.09012 17.3195 2.10012 17.3395 2.12012 17.3495C3.92012 18.6695 5.65012 19.4695 7.36012 19.9995C7.39012 20.0095 7.42012 19.9995 7.43012 19.9795C7.83012 19.4295 8.19012 18.8495 8.50012 18.2395C8.52012 18.1995 8.50012 18.1595 8.46012 18.1495C7.89012 17.9295 7.35012 17.6695 6.82012 17.3695C6.78012 17.3495 6.78012 17.2895 6.81012 17.2595C6.92012 17.1795 7.03012 17.0895 7.14012 17.0095C7.16012 16.9895 7.19012 16.9895 7.21012 16.9995C10.6501 18.5695 14.3601 18.5695 17.7601 16.9995C17.7801 16.9895 17.8101 16.9895 17.8301 17.0095C17.9401 17.0995 18.0501 17.1795 18.1601 17.2695C18.2001 17.2995 18.2001 17.3595 18.1501 17.3795C17.6301 17.6895 17.0801 17.9395 16.5101 18.1595C16.4701 18.1695 16.4601 18.2195 16.4701 18.2495C16.7901 18.8595 17.1501 19.4395 17.5401 19.9895C17.5701 19.9995 17.6001 20.0095 17.6301 19.9995C19.3501 19.4695 21.0801 18.6695 22.8801 17.3495C22.9001 17.3395 22.9101 17.3195 22.9101 17.2995C23.3501 12.7695 22.1801 8.83953 19.8101 5.34953C19.8001 5.33953 19.7901 5.32953 19.7701 5.32953ZM9.02012 14.9095C7.99012 14.9095 7.13012 13.9595 7.13012 12.7895C7.13012 11.6195 7.97012 10.6695 9.02012 10.6695C10.0801 10.6695 10.9201 11.6295 10.9101 12.7895C10.9101 13.9595 10.0701 14.9095 9.02012 14.9095ZM15.9901 14.9095C14.9601 14.9095 14.1001 13.9595 14.1001 12.7895C14.1001 11.6195 14.9401 10.6695 15.9901 10.6695C17.0501 10.6695 17.8901 11.6295 17.8801 12.7895C17.8801 13.9595 17.0501 14.9095 15.9901 14.9095Z"
            fill="#5865F2"
          />
        </svg>
      }
      fullWidth
      // disabled={true}
      variant="contained"
      onClick={login}
    >
      Login with Discord
    </GreyButton>
  );
}

export default DiscordLogin;
