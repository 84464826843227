import React, { useContext, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, Container, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link } from "react-router-dom";
import { WhiteButton } from "../../../../components/MUI/buttons/button";
import useMixpanel from "../../../../hooks/useMixpanel";
import { mixpanelLandingAlumni } from "../../../../constants/mixpanel";
import Reviews from "./Reviews";
import classes from "./index.module.scss";
import CustomNavigation from "./Reviews/CustomNavigation";
import { api } from "../../../../instances/axiosInstances";
import { AuthContext } from "../../../../context/AuthContext";

export default function OurAlumniAboutUs({ data }) {
  const { isAuthLoading, auth } = useContext(AuthContext);
  const authState = auth ? "registered" : "unregistered";

  const options = {
    enabled: !isAuthLoading,
  };

  const itemsRes = useQuery(
    ["landing", authState],
    () =>
      api.get(
        auth
          ? process.env.REACT_APP_LANDING_REGISTERED_PATH
          : process.env.REACT_APP_LANDING_UNREGISTERED_PATH
      ),
    options
  );

  const { handleEventTrack } = useMixpanel();
  const { startForFreeLink, navigationLeftAnchor, navigationRightAnchor } =
    mixpanelLandingAlumni;

  const sliderRef = useRef(null);

  const items = itemsRes.isSuccess
    ? itemsRes.data?.data.landing.alumniFeedbacksGeneral
    : [];

  if (!data || !items.length) return null;

  const { title, custom_fields, display_section } = data || {};

  if (custom_fields?.display_section[0] === "off") return null;

  return (
    <Container maxWidth="lg" className={classes.container} id="testimonials">
      <Box className={classes.body} component={"section"}>
        <Box className={classes.header}>
          <Typography component={"h2"} variant={"h2"} className={classes.title}>
            {title?.rendered}
          </Typography>
          <Box className={classes.navigation}>
            <CustomNavigation
              sliderRef={sliderRef}
              variant={"contained"}
              mixpanelLeftData={navigationLeftAnchor}
              mixpanelRightData={navigationRightAnchor}
            />
          </Box>
        </Box>

        <Box className={classes.carousel}>
          <Reviews data={items} sliderRef={sliderRef} />
        </Box>

        <Box className={classes.footer}>
          <Box className={classes.actions}>
            <WhiteButton
              variant="contained"
              color="secondary"
              component={Link}
              endIcon={<ChevronRightIcon />}
              fullWidth
              to={custom_fields?.button_link[0]}
              onClick={(e) => {
                handleEventTrack(e, startForFreeLink);
              }}
            >
              {custom_fields?.button}
            </WhiteButton>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
