import { Box, Container } from "@mui/material";
import { ReactComponent as Image } from "../../../assets/images/404.svg";
import classes from "./index.module.scss";
import { Button, Text } from "../../../components/MUI";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";

export default function NotFound() {
  const theme = useTheme();
  return (
    <Container maxWidth="sm-440" className={classes.container}>
      <Box className={classes.body}>
        <Box className={classes.image}>
          <Image />
        </Box>
        <Text
          variant="text-20"
          sx={{
            color: theme.palette.dark,
            textTransform: "initial",
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          This page does not exist
        </Text>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={"/courses"}
        >
          Go to Courses
        </Button>
      </Box>
    </Container>
  );
}
