import { Accordion, Summary, Details } from "../Accordion/TasksAccordion";
import { Box, Typography } from "@mui/material";
import useAccordion from "../../../../hooks/useAccordion";
import classes from "./index.module.scss";
import HTMLComponent from "../../../../components/Common/HTMLComponent";

export default function Content({ list }) {
  const { expanded, handleChange } = useAccordion();
  return (
    <Box className={classes.tasksList}>
      {list.map((item, index) => {
        return (
          <Accordion
            expanded={expanded}
            handleChange={handleChange}
            index={index}
            key={item.id}
          >
            <Summary index={index} className={classes.summary}>
              <Typography component="h4" className={classes.summary__title}>
                {item.title}
              </Typography>
            </Summary>
            <Details className={classes.details}>
              {item.text ? (
                item.text.toString().includes("</") ? (
                  <Box sx={{ marginBottom: "0.375rem" }}>
                    <HTMLComponent html={item.text} textColor={"dark-060"} />
                  </Box>
                ) : (
                  <Typography className={classes.details__text}>
                    {item.text}
                  </Typography>
                )
              ) : null}
              {item.fileUrl ? (
                <Typography
                  className={classes.details__link}
                  component={"a"}
                  download={item?.fileTitle || "document"}
                  href={item.fileUrl}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                  >
                    <path
                      d="M7.99992 8.5V12.5M7.99992 12.5L9.99992 11.1667M7.99992 12.5L5.99992 11.1667M8.66658 2.5H5.46659C4.71992 2.5 4.34659 2.5 4.06125 2.64533C3.81035 2.77313 3.60638 2.9771 3.47859 3.228C3.33325 3.51333 3.33325 3.88667 3.33325 4.63333V12.3667C3.33325 13.1133 3.33325 13.4867 3.47859 13.772C3.60641 14.0229 3.81038 14.2268 4.06125 14.3547C4.34592 14.5 4.71925 14.5 5.46458 14.5H10.5353C11.2806 14.5 11.6533 14.5 11.9379 14.3547C12.1893 14.2267 12.3933 14.0227 12.5213 13.772C12.6666 13.4867 12.6666 13.1147 12.6666 12.3693V6.5M8.66658 2.5C8.85725 2.502 8.97725 2.50933 9.09258 2.53667C9.22858 2.57 9.35858 2.62333 9.47792 2.69667C9.61258 2.77933 9.72792 2.89467 9.95792 3.12533L12.0419 5.20867C12.2726 5.43933 12.3873 5.554 12.4699 5.68867C12.5433 5.80867 12.5966 5.938 12.6299 6.074C12.6566 6.18933 12.6646 6.31 12.6666 6.5M8.66658 2.5V4.36667C8.66658 5.11333 8.66659 5.48667 8.81192 5.772C8.93975 6.02287 9.14371 6.22684 9.39458 6.35467C9.67925 6.5 10.0526 6.5 10.7979 6.5H12.6666"
                      stroke="#3D40D5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {item.fileTitle ? item.fileTitle : "Download file"}
                </Typography>
              ) : null}
              {item.link ? (
                <Typography
                  className={classes.details__link}
                  component={"a"}
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                  >
                    <path
                      d="M2.63997 8.08724C2.82664 7.10724 3.74664 6.4339 4.74664 6.4339H6.69997C7.04664 6.4339 7.33331 6.14724 7.33331 5.80057C7.33331 5.4539 7.04664 5.16724 6.69997 5.16724H4.81331C3.07331 5.16724 1.51997 6.44057 1.35331 8.1739C1.30721 8.63672 1.35859 9.10406 1.50411 9.54582C1.64964 9.98757 1.88609 10.3939 2.19824 10.7388C2.51039 11.0836 2.8913 11.3591 3.31644 11.5478C3.74159 11.7364 4.20153 11.8339 4.66664 11.8339H6.69997C7.04664 11.8339 7.33331 11.5472 7.33331 11.2006C7.33331 10.8539 7.04664 10.5672 6.69997 10.5672H4.66664C4.36042 10.5663 4.05821 10.4975 3.78173 10.3659C3.50525 10.2343 3.26136 10.043 3.06759 9.80589C2.87382 9.56877 2.73498 9.29168 2.66104 8.99452C2.5871 8.69736 2.57991 8.38751 2.63997 8.08724ZM5.99997 9.16724H9.99997C10.3666 9.16724 10.6666 8.86724 10.6666 8.50057C10.6666 8.1339 10.3666 7.8339 9.99997 7.8339H5.99997C5.63331 7.8339 5.33331 8.1339 5.33331 8.50057C5.33331 8.86724 5.63331 9.16724 5.99997 9.16724ZM11.1866 5.16724H9.29997C8.95331 5.16724 8.66664 5.4539 8.66664 5.80057C8.66664 6.14724 8.95331 6.4339 9.29997 6.4339H11.2533C12.2533 6.4339 13.1733 7.10724 13.36 8.08724C13.42 8.38751 13.4128 8.69736 13.3389 8.99452C13.265 9.29168 13.1261 9.56877 12.9324 9.80589C12.7386 10.043 12.4947 10.2343 12.2182 10.3659C11.9417 10.4975 11.6395 10.5663 11.3333 10.5672H9.29997C8.95331 10.5672 8.66664 10.8539 8.66664 11.2006C8.66664 11.5472 8.95331 11.8339 9.29997 11.8339H11.3333C13.28 11.8339 14.84 10.1606 14.6533 8.1739C14.4866 6.44057 12.9266 5.16724 11.1866 5.16724Z"
                      fill="#3D40D5"
                    />
                  </svg>
                  {item.linkTitle ? item.linkTitle : "Visit website"}
                </Typography>
              ) : null}
            </Details>
          </Accordion>
        );
      })}
    </Box>
  );
}
