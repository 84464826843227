import { Box, Container, Typography } from "@mui/material";
import { LandingLayout } from "../../../components/Layouts";
import {
  AccentButton,
  WhiteButton,
} from "../../../components/MUI/buttons/button";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar.svg";
import { ReactComponent as DiscordIcon } from "../../../assets/icons/Discord.svg";
import classes from "./index.module.scss";
import { useWebinarData } from "./hooks/useWebinarData";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Common/Loader";
import { LoginSocialGoogle } from "reactjs-social-login";

export default function ThankYou() {
  const { slug } = useParams();
  const { data, isFetching } = useWebinarData({ slug });
  const title = data?.webinar.title.rendered;
  const subtitle = data?.webinar.custom_fields.subtitle;
  const date = data?.webinar.custom_fields.date;
  const time = data?.webinar.custom_fields.time;
  const googleCalendarLink =
    data?.webinar.custom_fields?.google_calendar_link &&
    data?.webinar.custom_fields.google_calendar_link[0];
  const buttonText = data?.webinar.custom_fields.button_text;

  return (
    <LandingLayout>
      {isFetching ? <Loader /> : null}
      <Container maxWidth={"lg"}>
        {!isFetching ? (
          <Box className={classes.body}>
            <Box className={classes.banner}>
              {data?.webinar ? (
                <>
                  <Typography
                    variant="h2"
                    component={"h2"}
                    className={classes.banner__subtitle}
                  >
                    🤘 Thank you for registering for the Webinar
                  </Typography>
                  <Typography
                    variant="h1"
                    component={"h1"}
                    className={classes.banner__title}
                  >
                    <span>{title}</span>
                    {subtitle ? <span>{subtitle}</span> : null}
                  </Typography>
                  <Box className={classes.banner__date}>
                    <Typography className={classes.save}>
                      Save the date:
                    </Typography>
                    <Box className={classes.date}>
                      <Typography className={classes.date__start}>
                        {date}
                      </Typography>
                      <Typography className={classes.date__start}>
                        {time}
                      </Typography>
                    </Box>
                  </Box>

                  {googleCalendarLink ? (
                    <LoginSocialGoogle
                      client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                      isOnlyGetToken={true}
                      scope="openid profile email"
                      discoveryDocs="claims_supported"
                      access_type="offline"
                      redirect_uri={`${process.env.REACT_APP_URL}/thank-you/${slug}`}
                      onResolve={() => {
                        window.location = googleCalendarLink;
                      }}
                      onReject={(err) => {
                        console.error(err);
                      }}
                    >
                      <WhiteButton
                        className={classes.button}
                        variant="contained"
                        startIcon={<CalendarIcon />}
                      >
                        {buttonText || "Add to Google Calendar"}
                      </WhiteButton>
                    </LoginSocialGoogle>
                  ) : null}
                </>
              ) : (
                <Typography
                  variant="h1"
                  component={"h1"}
                  className={classes.banner__title}
                >
                  Sorry. Webinar not found.
                </Typography>
              )}
            </Box>
            <Box className={classes.opportunities}>
              <Typography
                variant="h2"
                component={"h2"}
                className={classes.opportunities__title}
              >
                ⚡ Engagement Opportunities
              </Typography>
              <Typography className={classes.opportunities__text}>
                Join Engenious University on Discord to discuss current topics
                in testing
              </Typography>
              <AccentButton
                className={classes.button}
                variant="contained"
                component={"a"}
                startIcon={<DiscordIcon />}
                target="_blank"
                href={process.env.REACT_APP_DISCORD_LINK}
              >
                Join our Discord
              </AccentButton>
            </Box>
          </Box>
        ) : null}
      </Container>
    </LandingLayout>
  );
}
