import { createContext, useMemo, useState } from "react";
export const RedirectContext = createContext();

export default function RedirectContextProvider({ children }) {
  const [currentCourseId, setCurrentCourseId] = useState(undefined);
  const [currentLectureId, setCurrentLectureId] = useState(undefined);
  const [isPayment, setIsPayment] = useState(false);

  const addCurrentCourseId = (currentCourseId) => {
    localStorage.setItem("currentCourseId", currentCourseId);
    setCurrentCourseId(currentCourseId);
  };
  const addCurrentLectureId = (currentLectureId) => {
    localStorage.setItem("currentLectureId", currentLectureId);
    setCurrentLectureId(currentLectureId);
  };

  const getCurrentCourseId = () => {
    return currentCourseId || localStorage.getItem("currentCourseId");
  };

  const getCurrentLectureId = () => {
    return currentLectureId || localStorage.getItem("currentLectureId");
  };

  const removeCurrentCourse = () => {
    localStorage.removeItem("currentCourseId");
    localStorage.removeItem("currentLectureId");
    setCurrentCourseId(undefined);
    setCurrentLectureId(undefined);
    setIsPayment(false);
  };

  const value = useMemo(
    () => ({
      currentCourseId,
      addCurrentCourseId,
      removeCurrentCourse,
      getCurrentCourseId,
      currentLectureId,
      addCurrentLectureId,
      getCurrentLectureId,
      isPayment,
    }),
    [currentCourseId, currentLectureId, isPayment]
  );

  return (
    <RedirectContext.Provider value={value}>
      {children}
    </RedirectContext.Provider>
  );
}
