import { useQuery } from "@tanstack/react-query";
import { api } from "../../../../instances/axiosInstances";

async function fetchFilters() {
  const response = await api.get(`/courses/list/filters`);
  const data = { filters: response.data };
  return data;
}

export function useFilters() {
  return useQuery({
    queryKey: ["filters", "courses", "list"],
    queryFn: () => fetchFilters(),
    staleTime: Infinity,
  });
}
