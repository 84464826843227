import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AuthContextProvider from "./context/AuthContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Smartlook from "smartlook-client";
import mixpanel from "mixpanel-browser";

import "./index.scss";
import ModalContextProvider from "./context/ModalContext";
import RedirectContextProvider from "./context/RedirectContext";
import { BrowserRouter } from "react-router-dom";
import QuestionnairePopupContextProvider from "./context/QuestionnairePopupContext";
import WebinarPopupContextProvider from "./context/WebinarPopupContext";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
  ignore_dnt: true,
});

Smartlook.init(process.env.REACT_APP_SMARTLOOK_SECRET_KEY, {
  cookies: false,
  region: "eu",
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <BrowserRouter>
          <WebinarPopupContextProvider>
            <ModalContextProvider>
              <RedirectContextProvider>
                <QuestionnairePopupContextProvider>
                  <App />
                </QuestionnairePopupContextProvider>
              </RedirectContextProvider>
            </ModalContextProvider>
          </WebinarPopupContextProvider>
        </BrowserRouter>
      </AuthContextProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
