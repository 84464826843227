import {
  ModuleAccordion,
  ModuleAccordionSummary,
  ModuleAccordionDetails,
} from "../parts";

export const Accordion = ({ index, children, expanded, handleChange }) => {
  
  return (
    <ModuleAccordion
      key={index}
      expanded={expanded === `panel${index}`}
      onChange={handleChange(`panel${index}`)}
    >
      {children}
    </ModuleAccordion>
  );
};

export const Summary = ({ index, children }) => {
  return (
    <ModuleAccordionSummary
      aria-controls={`panel${index}d-content`}
      id={`panel${index}d-header`}
    >
      {children}
    </ModuleAccordionSummary>
  );
};

export const Details = ({ children }) => {
  return <ModuleAccordionDetails>{children}</ModuleAccordionDetails>;
};
