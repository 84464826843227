import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";
import { NavLink } from "react-router-dom";

export default function Navigation() {
  return (
    <Box className={classes.navigation}>
      <Typography
        variant={"h2"}
        component={NavLink}
        className={classes.navigation__link}
        to={"/registration"}
      >
        Registration
      </Typography>
      <Typography
        variant={"h2"}
        component={NavLink}
        className={classes.navigation__link}
        to={"/login"}
      >
        Login
      </Typography>
    </Box>
  );
}
