import { Button, IconButton } from "@mui/material";
import { styled } from "@mui/system";

const DefaultButton = styled(Button)(({ isBig = true }) => {
  return `
    border-radius: 0.75rem;
    font-family: var(--proxima);
    text-align: center;
    font-size: ${isBig ? "1.25rem" : "1rem"};
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    height: ${isBig ? "3.25rem" : "3rem"};
    padding: 0.8125rem 1.5rem;
    align-items: center;
    outline: none;
    box-shadow: none;
    text-transform: initial;
    white-space: nowrap;

    &:disabled{
      opacity: 0.4;
      cursor:default;
    }

    .MuiTouchRipple-root {
      display: none;
    };
    `;
});

export const WhiteButton = styled(DefaultButton)(
  ({ variant }) => `
    background: var(--on-surface-a-100);
    color: var(--colors-a-100);
    border:${variant === "outlined" ? "1px solid var(--colors-a-100)" : "none"};
    &:hover {
      background: linear-gradient(0deg, rgba(49, 51, 170, 0.10) 0%, rgba(49, 51, 170, 0.10) 100%), #FFF;
      box-shadow: none;
      border:${
        variant === "outlined" ? "1px solid var(--colors-a-100)" : "none"
      };
    }
    &:active {
      background: linear-gradient(0deg, rgba(61, 64, 213, 0.20) 0%, rgba(61, 64, 213, 0.20) 100%), #FFF;
      box-shadow: none;
      border:${
        variant === "outlined" ? "1px solid var(--colors-a-100)" : "none"
      };
    }
    &:disabled{
      background: var(--on-surface-a-100);
      color: var(--colors-a-100);     
    }
    `
);

export const AccentButton = styled(DefaultButton)(
  ({ variant }) => `
    background: var(--colors-a-100);    
    color: var(--on-surface-a-100);
    border:${
      variant === "outlined" ? "1px solid var(--on-surface-a-100)" : "none"
    };
    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #3D40D5;
      box-shadow: none;
      border:${
        variant === "outlined" ? "1px solid var(--on-surface-a-100)" : "none"
      };
    }
    &:active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #3D40D5;
      box-shadow: none;
      border:${
        variant === "outlined" ? "1px solid var(--colors-a-100)" : "none"
      };
    }
    &:disabled{
      background: var(--colors-a-100);    
      color: var(--on-surface-a-100);    
    }
    `
);
export const RedButton = styled(DefaultButton)(
  ({ variant }) => `
    background: var(--colors-b-100);    
    color: var(--on-surface-a-100);
    border:${
      variant === "outlined" ? "1px solid var(--on-surface-a-100)" : "none"
    };
    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #ff3d2e;
      box-shadow: none;
      border:${
        variant === "outlined" ? "1px solid var(--on-surface-a-100)" : "none"
      };
    }
    &:active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #ff3d2e;
      box-shadow: none;
      border:${
        variant === "outlined" ? "1px solid var(--colors-b-100)" : "none"
      };
    }
    &:disabled{
      background: var(--colors-b-100);    
      color: var(--on-surface-a-100);    
    }
    `
);

export const TransparentButton = styled(DefaultButton)(
  ({ variant }) => `
    background: transparent;
    color: var(--on-surface-a-100);
    border:${
      variant === "outlined" ? "1px solid var(--on-surface-a-100)" : "none"
    };
    &:hover {
      background: var(--on-surface-a-010);
      box-shadow: none;
      border:${
        variant === "outlined" ? "1px solid var(--on-surface-a-100)" : "none"
      };
    }
    &:active {
      background: var(--on-surface-a-020);
      box-shadow: none;
      border:${
        variant === "outlined" ? "1px solid var(--on-surface-a-100)" : "none"
      };
    }
    &:disabled{
      background: transparent;
      color: var(--colors-a-100);     
    }
    `
);

export const GreyButton = styled(DefaultButton)(
  ({ variant }) => `
    background: var(--on-surface-b-005);
    color: var(--on-surface-b-100);
    border:${
      variant === "outlined" ? "1px solid var(--on-surface-b-100)" : "none"
    };
    &:hover {
      background: var(--on-surface-b-010);
      box-shadow: none;
      border:${
        variant === "outlined" ? "1px solid var(--on-surface-b-100)" : "none"
      };
    }
    &:active {
      background: var(--on-surface-b-020);
      box-shadow: none;
      border:${
        variant === "outlined" ? "1px solid var(--on-surface-b-020)" : "none"
      };
    }
    &:disabled{
      background: var(--on-surface-b-005);
      color: var(--on-surface-b-100);
    }
    `
);

export const PlayButton = styled(IconButton)(() => {
  return `
    display: flex;
    height: 3.5rem;
    width: 3.5rem;
    padding: 0.75rem;
    align-items: center;
    justify-content: center;
    outline: none;
    border-radius: 6.1875rem;
    background: var(--on-surface-a-100);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.10);

    &:hover {
      background: linear-gradient(0deg, rgba(49, 51, 170, 0.10) 0%, rgba(49, 51, 170, 0.10) 100%), #FFF;
    }
    &:active {
      background: linear-gradient(0deg, rgba(61, 64, 213, 0.20) 0%, rgba(61, 64, 213, 0.20) 100%), #FFF;
    }

    &:disabled{
      opacity: 0.4;
      cursor:default;
      background: var(--on-surface-a-100);
    }

    .MuiTouchRipple-root {
      display: none;
    };

    svg {
      path {
        fill: var(--colors-a-100);
      } 
    }
    `;
});

export const CarouselButton = styled(IconButton)(({ variant }) => {
  return `
    display: flex;
    height: 3rem;
    width: 3rem;
    padding: 0.75rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.75rem;
    background: var(--on-surface-a-100);
    box-shadow: none;
    border: ${
      variant === "outlined" ? "1px solid var(--colors-a-020)" : "none"
    };

    &:hover {
      background: linear-gradient(0deg, rgba(49, 51, 170, 0.10) 0%, rgba(49, 51, 170, 0.10) 100%), #FFF;
    }
    &:active {
      background: linear-gradient(0deg, rgba(61, 64, 213, 0.20) 0%, rgba(61, 64, 213, 0.20) 100%), #FFF;
    }

    &:disabled{
      opacity: 0.4;
      cursor:default;
      background: var(--on-surface-a-100);
    }

    .MuiTouchRipple-root {
      display: none;
    };

    svg {
      path {
        fill: var(--colors-a-100);
      } 
    }
    `;
});

export const BurgerButton = styled(IconButton)(() => {
  return `
    height: 1.5rem;
    width: 1.5rem;
    padding: 0;
    align-items: center;
    justify-content: center;
    outline: none;
    border-radius: 0;
    background: var(--on-surface-a-100);
    box-shadow: none;

    &:hover {
      background: linear-gradient(0deg, rgba(49, 51, 170, 0.10) 0%, rgba(49, 51, 170, 0.10) 100%), #FFF;
    }
    &:active {
      background: linear-gradient(0deg, rgba(61, 64, 213, 0.20) 0%, rgba(61, 64, 213, 0.20) 100%), #FFF;
    }

    &:disabled{
      opacity: 0.4;
      cursor:default;
      background: var(--on-surface-a-100);
    }

    .MuiTouchRipple-root {
      display: none;
    };

    svg {
      path {
        fill: var(--colors-a-100);
      } 
    }
    `;
});
