import { Box, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import classes from "./index.module.scss";

export default function Certificate({ certificate, currentUserId }) {
  const { id } = useParams();

  return (
    <Box
      component={Link}
      to={`/rating/${id || currentUserId}/certificate/${certificate.id}`}
      className={classes.card}
    >
      <Box className={classes.image}>
        <img
          src={certificate?.imageCertificateUrl}
          alt={certificate.course?.title}
          width={323}
        />
      </Box>
      <Typography component={"h2"} className={classes.title}>
        <span>{certificate.course?.title}</span>
      </Typography>
    </Box>
  );
}
