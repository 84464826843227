import { Autocomplete, Box, Typography, Checkbox } from "@mui/material";
import { AccentButton } from "../../../components/MUI/buttons/button";
import { Input } from "../../../components/MUI/inputs";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import schema from "../schema";
import countries from "../../../constants/countries.json";
import states from "../../../constants/states.json";
import { ReactComponent as Chevron } from "../../../assets/images/bx-chevron-down.svg.svg";
import { useEffect, useState } from "react";
import { topics } from "../topics";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { experience } from "../../../constants/experience";
import equal from "deep-equal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import classes from "./index.module.scss";
import errorToast from "../../../components/toasts/errorToast";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function QuestionnaireFilled({
  user: {
    firstName,
    lastName,
    email,
    phoneNumber,
    country,
    state,
    city,
    interestedTopics,
    linkedinLink,
    company,
    position,
    yearsOfExperience,
    discordUsername
  },
  isUserLoading,
}) {
  const defaultValues = {
    firstName,
    lastName,
    email,
    phoneNumber,
    country,
    state,
    city,
    interestedTopics,
    linkedinLink,
    company,
    position,
    yearsOfExperience,
    discordUsername
  };
  const {
    trigger,
    register,
    handleSubmit,
    resetField,
    clearErrors,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    values: {
      ...defaultValues,
    },
  });

  const queryClient = useQueryClient();

  const [showCustomOptions, setShowCustomOptions] = useState(false);
  const [updatedTopics, setUpdatedTopics] = useState([]);
  const [flag, setFlag] = useState(false);

  const handlePhoneInputChange = (value, country, e, formattedValue) => {
    trigger(["phoneNumber"]);
    setValue("phoneNumber", formattedValue);
  };

  const updateObjectByLabel = (name, label, value) => {
    const updatedArray = getValues(name).map((obj) => {
      if (obj.label === label) {
        return { ...obj, value };
      }
      return obj;
    });
    setValue(name, updatedArray);
  };

  const updateTopics = () => {
    const otherOptions = getValues("interestedTopics").filter(
      (topic) => topic.label === "Other options"
    )[0];

    if (otherOptions) {
      setUpdatedTopics([...topics, otherOptions]);
    } else {
      setUpdatedTopics([
        ...topics,
        {
          label: "Other options",
          value: "",
        },
      ]);
    }
  };

  const { mutate, isLoading } = useMutation(
    (data) => api.put("/users/profile", data),
    {
      onSuccess: async({ data }) => {
        // successToast(data.emails.message);
        await queryClient.invalidateQueries({
          queryKey: ["user"],
          refetchType: "all",
          exact: true,
        });
      },
      onError: (error) => {
        errorToast(error.response.data.message);
      },
    }
  );

  const onSubmit = (data) => {
    const { otherTopics: _, ...newObj } = data;
    mutate(newObj);
  };

  useEffect(() => {
    updateTopics();
    getValues("interestedTopics") &&
      setShowCustomOptions(
        Boolean(
          getValues("interestedTopics").filter(
            (topic) => topic.label === "Other options"
          ).length
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("interestedTopics")]);

  useEffect(() => {
    showCustomOptions &&
      getValues("otherTopics") &&
      updateObjectByLabel(
        "interestedTopics",
        "Other options",
        getValues("otherTopics")
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCustomOptions]);

  const isEqual = () => {
    const { otherTopics: _, ...values } = getValues();
    return equal(defaultValues, values);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Box className={classes.personalInfo}>
        <Typography component={"h3"} className={classes.title}>
          Personal info
        </Typography>
        <Box className={classes.personalInfo__grid}>
          <Input
            label="First name*"
            type="text"
            margin="normal"
            variant="outlined"
            fullWidth
            autoComplete="new-password"
            InputLabelProps={{
              shrink: true,
              disableAnimation: true,
            }}
            placeholder="First name"
            {...register("firstName", {
              onChange: () => trigger(["firstName"]),
            })}
            error={!!errors.firstName}
            helperText={errors.firstName && errors.firstName.message}
          />
          <Input
            label="Last name*"
            type="text"
            margin="normal"
            variant="outlined"
            fullWidth
            autoComplete="new-password"
            InputLabelProps={{
              shrink: true,
              disableAnimation: true,
            }}
            placeholder="Last name"
            {...register("lastName", {
              onChange: () => trigger(["lastName"]),
            })}
            error={!!errors.lastName}
            helperText={errors.lastName && errors.lastName.message}
          />
          <Input
            label="Email"
            type="text"
            margin="normal"
            variant="outlined"
            defaultValue={email}
            disabled
            fullWidth
            autoComplete="new-password"
            InputLabelProps={{
              shrink: true,
              disableAnimation: true,
            }}
          />
          <Box>
            <PhoneInput
              country={"us"}
              countryCodeEditable={false}
              copyNumbersOnly={false}
              value={getValues("phoneNumber") ? getValues("phoneNumber") : ""}
              containerClass={`${classes.phone} ${
                errors.phoneNumber ? classes.phone__error : ""
              }`}
              onChange={handlePhoneInputChange}
              inputProps={{
                autoComplete: "new-password",
              }}
              specialLabel="Phone Number*"
            />
            {errors.phoneNumber && (
              <Typography
                sx={{
                  color: "var(--colors-b-100)",
                  fontSize: "0.75rem",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "120%",
                  marginLeft: "0.5rem",
                  marginTop: "3px",
                }}
              >
                {errors.phoneNumber.message}
              </Typography>
            )}
          </Box>

          <Input
            label="Discord username*"
            type="text"
            margin="normal"
            variant="outlined"
            fullWidth
            autoComplete="new-password"
            InputLabelProps={{
              shrink: true,
              disableAnimation: true,
            }}
            placeholder="Discord username"
            {...register("discordUsername", {
              onChange: () => trigger(["discordUsername"]),
            })}
            error={!!errors.discordUsername}
            helperText={
              errors.discordUsername && errors.discordUsername.message
            }
          />

          <Autocomplete
            id="country-select"
            fullWidth
            options={countries}
            defaultValue={getValues("country") ? getValues("country") : null}
            autoHighlight
            popupIcon={<Chevron />}
            getOptionLabel={(option) => option}
            sx={{
              "& .MuiInputBase-root": {
                padding: "0 4rem 0 0",
              },
            }}
            onChange={(_, value) => {
              resetField("country");
              setValue("country", value || null);
              setValue("state", null);
              clearErrors(["country"]);
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <Input
                {...params}
                label="Country*"
                type="text"
                margin="normal"
                variant="outlined"
                fullWidth
                autoComplete="new-password"
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                placeholder="Country"
                {...register("country")}
                error={!!errors.country}
                helperText={errors.country && errors.country.message}
              />
            )}
          />

          {getValues("country") === "USA" && (
            <Autocomplete
              id="state-select"
              fullWidth
              options={states}
              autoHighlight
              getOptionLabel={(option) => option}
              defaultValue={getValues("state") ? getValues("state") : null}
              popupIcon={<Chevron />}
              onChange={(_, value) => {
                resetField("state");
                clearErrors(["state"]);
                setValue("state", value || null);
              }}
              sx={{
                "& .MuiInputBase-root": {
                  padding: "0 4rem 0 0",
                },
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <Input
                  {...params}
                  label="State*"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  autoComplete="new-password"
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                  placeholder="State"
                  {...register("state", {
                    onChange: () => trigger(["state"]),
                  })}
                  error={!!errors.state}
                  helperText={errors.state && errors.state.message}
                />
              )}
            />
          )}

          <Input
            label="City*"
            type="text"
            margin="normal"
            variant="outlined"
            fullWidth
            autoComplete="new-password"
            InputLabelProps={{
              shrink: true,
              disableAnimation: true,
            }}
            placeholder="City"
            {...register("city", {
              onChange: () => trigger(["city"]),
            })}
            error={!!errors.city}
            helperText={errors.city && errors.city.message}
          />
        </Box>
      </Box>
      <Box className={classes.professionalInfo}>
        <Typography component={"h3"} className={classes.title}>
          Professional info
        </Typography>
        <Box className={classes.professionalInfo__grid}>
          <Box className={classes.professionalInfo__top}>
            <Autocomplete
              multiple
              id="interestedTopics-select"
              fullWidth
              options={updatedTopics}
              autoHighlight
              disableCloseOnSelect
              getOptionLabel={(option) => {
                return option.label;
              }}
              renderTags={(value) => {
                const names = value.reduce((acc, option) => {
                  return [...acc, option.value || option.label];
                }, []);

                return <span>{names.join(", ")}</span>;
              }}
              value={getValues("interestedTopics") || []}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              popupIcon={<Chevron />}
              onChange={(_, value) => {
                setValue("interestedTopics", value);
                setShowCustomOptions(
                  Boolean(
                    getValues("interestedTopics").filter(
                      (topic) => topic.label === "Other options"
                    ).length
                  )
                );

                !Boolean(
                  getValues("interestedTopics").filter(
                    (topic) => topic.label === "Other options"
                  ).length
                ) && setValue("otherTopics", "");

                setFlag(!flag);
              }}
              sx={{
                "& .MuiInputBase-root": {
                  padding: "0 4rem 0 1rem",
                  flexWrap: "nowrap",

                  "& span": {
                    width: "100%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  },

                  "& #interestedTopics-select": {
                    paddingLeft: "0 !important",
                    minWidth: "0 !important",
                  },
                },
              }}
              renderOption={(props, option, { selected }) => {
                return (
                  <Box component="li" {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </Box>
                );
              }}
              renderInput={(params) => (
                <Input
                  {...params}
                  label="Topics you are interested to learn"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  autoComplete="new-password"
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                  placeholder={
                    getValues("interestedTopics") &&
                    getValues("interestedTopics").length > 0
                      ? ""
                      : "Select topics"
                  }
                />
              )}
            />
            {showCustomOptions ? (
              <Input
                defaultValue={
                  getValues("interestedTopics").filter(
                    (topic) => topic.label === "Other options"
                  )[0].value || ""
                }
                label="Other topics"
                type="text"
                margin="normal"
                variant="outlined"
                fullWidth
                autoComplete="new-password"
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                placeholder="Other topics"
                {...register("otherTopics", {
                  onChange: () => {
                    trigger(["otherTopics"]);
                  },
                })}
                onChange={(e) => {
                  updateObjectByLabel(
                    "interestedTopics",
                    "Other options",
                    e.target.value
                  );

                  setUpdatedTopics([
                    ...topics,
                    {
                      label: "Other options",
                      value: e.target.value,
                    },
                  ]);
                }}
                error={!!errors.otherTopics}
                helperText={errors.otherTopics && errors.otherTopics.message}
              />
            ) : null}
          </Box>
          <Box className={classes.professionalInfo__bottom}>
            <Input
              label="LinkedIn profile link"
              type="text"
              margin="normal"
              variant="outlined"
              fullWidth
              autoComplete="new-password"
              InputLabelProps={{
                shrink: true,
                disableAnimation: true,
              }}
              placeholder="Link"
              {...register("linkedinLink", {
                onChange: () => trigger(["linkedinLink"]),
              })}
              error={!!errors.linkedinLink}
              helperText={errors.linkedinLink && errors.linkedinLink.message}
            />
            <Input
              label="What is your current company?"
              type="text"
              margin="normal"
              variant="outlined"
              fullWidth
              autoComplete="new-password"
              InputLabelProps={{
                shrink: true,
                disableAnimation: true,
              }}
              placeholder="Company name"
              {...register("company", {
                onChange: () => trigger(["company"]),
              })}
              error={!!errors.company}
              helperText={errors.company && errors.company.message}
            />
            <Input
              label="Your position"
              type="text"
              margin="normal"
              variant="outlined"
              fullWidth
              autoComplete="new-password"
              InputLabelProps={{
                shrink: true,
                disableAnimation: true,
              }}
              placeholder="Your position"
              {...register("position", {
                onChange: () => trigger(["position"]),
              })}
              error={!!errors.position}
              helperText={errors.position && errors.position.message}
            />
            <Autocomplete
              id="years-of-experience-select"
              fullWidth
              options={experience}
              autoHighlight
              getOptionLabel={(option) => option}
              defaultValue={
                getValues("yearsOfExperience")
                  ? getValues("yearsOfExperience")
                  : null
              }
              popupIcon={<Chevron />}
              sx={{
                "& .MuiInputBase-root": {
                  padding: "0 4rem 0 0",
                },
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option}
                </Box>
              )}
              onChange={(_, value) => {
                resetField("yearsOfExperience");
                setValue("yearsOfExperience", value || null);
                clearErrors(["yearsOfExperience"]);
              }}
              renderInput={(params) => (
                <Input
                  {...params}
                  label="Years of experience"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  autoComplete="new-password"
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                  placeholder="Experience"
                  {...register("yearsOfExperience")}
                  error={!!errors.yearsOfExperience}
                  helperText={
                    errors.yearsOfExperience && errors.yearsOfExperience.message
                  }
                />
              )}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.actions}>
        <AccentButton
          isBig={false}
          variant="contained"
          fullWidth
          type={"submit"}
          disabled={isEqual() || isLoading || isUserLoading}
        >
          Save changes
        </AccentButton>
      </Box>
    </form>
  );
}
