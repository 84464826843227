import { useQuery } from "@tanstack/react-query";
import { api } from "../../../../instances/axiosInstances";

async function fetchMentors({ filters = [] }) {
  let queryParams = new URLSearchParams();

  if (filters && filters.length > 0) {
    const queryString = filters
      .map((filter) => `specializations=${filter}`)
      .join("&");

    queryParams = new URLSearchParams(queryString);
  }

  const response = await api.get(`/mentors/platform?${queryParams.toString()}`);

  const data = { mentors: response.data };
  return data;
}

export function useMentors({ filters }) {
  return useQuery({
    queryKey: ["mentors", filters],
    queryFn: () => fetchMentors({ filters }),
    keepPreviousData: true,
    staleTime: Infinity,
  });
}
