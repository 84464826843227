import { Box } from "@mui/material";
import classes from "./index.module.scss";

export default function DifficultyLevel({ level }) {
  const renderLevel = (level) => {
    switch (level) {
      case 0:
        return "Basic";
      case 1:
        return "Intermediate";
      case 2:
        return "Advanced";
      default:
        return null;
    }
  };
  if (!level && level !== 0) return null;
  return (
    <Box className={`${classes.chip} ${classes["chip__" + level]}`}>
      <svg
        width="8"
        height="10"
        viewBox="0 0 8 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="6" width="2" height="4" rx="1" fill="#3d40d533" />
        <rect x="3" y="3" width="2" height="7" rx="1" fill="#3d40d533" />
        <rect x="6" width="2" height="10" rx="1" fill="#3d40d533" />
      </svg>

      <span>{renderLevel(level)}</span>
    </Box>
  );
}
