import { Box } from "@mui/material";
import { PlatformLayout } from "../../../../components/Layouts";
import classes from "./index.module.scss";
import Dashboard from "../Dashboard";
import { useStudentRating } from "../hooks/useStudentRating";
import Loader from "../../../../components/Common/Loader";
import { useQueryClient } from "@tanstack/react-query";

export default function UserRating() {
   const queryClient = useQueryClient();
   const currentUser = queryClient.getQueryData(["user"]);
   const id = currentUser?.data?.users.id
  const { data: rating, isLoading: isRatingLoading } = useStudentRating({
    id,
  });

  const student = rating?.userRating?.usersRating;

  return (
    <PlatformLayout>
      {isRatingLoading ? <Loader /> : null}
      <Box className={classes.body}>
        <Dashboard rating={student} />
      </Box>
    </PlatformLayout>
  );
}
