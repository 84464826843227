import { Typography } from "@mui/material";
import classes from "./index.module.scss";

export default function NotFound({ text }) {
  return (
    <Typography className={classes.text}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <path
          d="M13.3337 24C15.7003 23.9995 17.9987 23.2072 19.863 21.7493L25.7243 27.6107L27.6097 25.7253L21.7483 19.864C23.207 17.9995 23.9998 15.7006 24.0003 13.3333C24.0003 7.45199 19.215 2.66666 13.3337 2.66666C7.45233 2.66666 2.66699 7.45199 2.66699 13.3333C2.66699 19.2147 7.45233 24 13.3337 24ZM13.3337 5.33332C17.7457 5.33332 21.3337 8.92132 21.3337 13.3333C21.3337 17.7453 17.7457 21.3333 13.3337 21.3333C8.92166 21.3333 5.33366 17.7453 5.33366 13.3333C5.33366 8.92132 8.92166 5.33332 13.3337 5.33332Z"
          fill="#3D40D5"
          fillOpacity="0.4"
        />
      </svg>
      <span>{text}</span>
    </Typography>
  );
}
