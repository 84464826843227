import { Link } from "react-router-dom";
import classes from "./index.module.scss";
import { Box, Typography } from "@mui/material";
import { formatTimeMilliseconds } from "../../../helpers/formatTime";
import Progress from "./Progress";
import HTMLComponent from "../../../components/Common/HTMLComponent";

export default function Course({ course }) {
  return (
    <Link to={`/courses/${course.id}`} className={classes.course}>
      <Box className={classes.course__image}>
        <img src={course.promoImageUrl} alt={course.title} width={160} />
      </Box>
      <Box className={classes.course__content}>
        <Typography className={classes.course__title} component={"h3"}>
          {course.title}
        </Typography>
        <Typography className={classes.course__subtitle}>
          {course.lecturesCount} lectures •{" "}
          {formatTimeMilliseconds(course.duration)}
        </Typography>
        {course.shortDescription.toString().includes("</") ? (
          <Box className={classes.htmlDescription}>
            <HTMLComponent
              html={course.shortDescription}
              textColor={"dark-090"}
            />
          </Box>
        ) : (
          <Typography className={classes.course__text}>
            {course.shortDescription}
          </Typography>
        )}
        {course.isPaid && course.type !== "live" ? (
          <Box className={classes.course__progress}>
            <Progress
              all={course.lecturesCount}
              finished={course.progressLecturesCount}
              text="lectures"
              isComplete={course.isComplete}
            />
          </Box>
        ) : null}
      </Box>
    </Link>
  );
}
