import { Box } from "@mui/material";
import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  handleNext,
  handlePrev,
} from "../../../../../helpers/carouselControls";
import { CarouselButton } from "../../../../../components/MUI/buttons/button";
import classes from "./index.module.scss";
import useMixpanel from "../../../../../hooks/useMixpanel";

export default function CustomNavigation({
  sliderRef,
  variant,
  mixpanelLeftData = null,
  mixpanelRightData = null,
}) {
  const { handleEventTrack } = useMixpanel();
  
  return (
    <Box className={classes.navigation}>
      <CarouselButton variant={variant} onClick={(e) => {
        handleEventTrack(e, mixpanelLeftData);
        handlePrev(sliderRef)}}>
        <ChevronLeftIcon />
      </CarouselButton>
      <CarouselButton variant={variant} onClick={(e) => {
        handleEventTrack(e, mixpanelRightData);
        handleNext(sliderRef)}}>
        <ChevronRightIcon />
      </CarouselButton>
    </Box>
  );
}
