import {
  FAQAccordion,
  FAQAccordionSummary,
  FAQAccordionDetails,
} from "../parts";

export const Accordion = ({ index, children, expanded, handleChange }) => {
  
  return (
    <FAQAccordion
      key={index}
      expanded={expanded === `panel${index}`}
      onChange={handleChange(`panel${index}`)}
    >
      {children}
    </FAQAccordion>
  );
};

export const Summary = ({ index, children }) => {
  return (
    <FAQAccordionSummary
      aria-controls={`panel${index}d-content`}
      id={`panel${index}d-header`}
    >
      {children}
    </FAQAccordionSummary>
  );
};

export const Details = ({ children }) => {
  return <FAQAccordionDetails>{children}</FAQAccordionDetails>;
};
