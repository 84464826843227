import { useQuery } from "@tanstack/react-query";
import axios from "axios";

async function fetchPosts({ page, perPage, search = "", tags = [] }) {
  const queryParams = new URLSearchParams();

  if (search) {
    queryParams.set("search", search);
    queryParams.set("search_fields", "title");
  }

  if (tags && tags.length > 0) {
    queryParams.set("tags", tags.join(","));
  }

  queryParams.set("page", page || 1);
  queryParams.set("per_page", perPage);
  queryParams.set(
    "_fields",
    "id,date,title,excerpt,img_url,post_tags,featured_media,tags,slug"
  );

  const response = await axios.get(
    `${process.env.REACT_APP_WP_PATH}posts?${queryParams.toString()}`
  );
  const totalPosts = response.headers["x-wp-total"];
  const totalPages = response.headers["x-wp-totalpages"];
  const data = { posts: response.data, totalPosts, totalPages };
  return data;
}

export function usePosts({ page, perPage, tags, search }) {
  return useQuery({
    queryKey: ["posts", page, Number(perPage), tags, search],
    queryFn: () => fetchPosts({ page, perPage, tags, search }),
    keepPreviousData: true,
    staleTime: Infinity,
  });
}
