import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";

export default function ModuleSummary({ lecturesCount, lecturesDuration, title }) {
  return (
    <Box className={classes.summary}>
      <Typography
        className={classes.summary__title}
        component="h3"
      >
        {title}
      </Typography>
      <Typography
        className={classes.summary__subtitle}
      >
        {lecturesCount} {lecturesCount !== 1 ? "lectures" : "lecture"} • {lecturesDuration} total length
      </Typography>
    </Box>
  );
}
