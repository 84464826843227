import { Box, Typography } from "@mui/material";
import { ReactComponent as Avatar } from "../../../assets/icons/avatar.svg";
import classes from "./index.module.scss";

export default function User({
  fullName,
  rating,
  icon,
  isContentVisible = true,
  isDark = false,
  iconWhite = false,
}) {
  return (
    <Box className={classes.card}>
      <Box
        className={`${classes.card__image} ${
          iconWhite ? classes.card__image_white : ""
        }`}
      >
        {icon ? (
          <img src={icon} alt={fullName ? fullName : "User"} width={36} />
        ) : (
          <Avatar />
        )}
      </Box>
      {isContentVisible ? (
        <Box className={classes.card__content}>
          <Typography
            component={"h3"}
            className={`${classes.card__title} ${
              isDark ? classes.card__title_dark : ""
            }`}
          >
            {fullName ? fullName : "User"}
          </Typography>
          <Box
            className={`${classes.card__stats} ${
              isDark ? classes.card__stats_dark : ""
            }`}
          >
            <Typography component={"span"}>
              🏅{" "}
              <span>
                {rating?.position || rating?.position === 0
                  ? rating.position
                  : "-"}
              </span>
            </Typography>
            <Typography component={"span"}>
              💎{" "}
              <span>
                {rating?.score || rating?.score === 0 ? rating.score : 0}
              </span>
            </Typography>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
