import { useContext } from "react";
import { ModalContext } from "../../../../../context/ModalContext";
import { Box } from "@mui/material";
import VideoModal from "../../../../../components/Common/EmbedVideos/VideoWrapper";
import Carousel from "./Carousel";

import classes from "./index.module.scss";

export default function Reviews({data, sliderRef}) {
  const { handleModalOpen } = useContext(ModalContext);

  const showModal = ({ url, title }) => {
    handleModalOpen(<VideoModal title={title} url={url} />);
  };

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.carousel}>
        <Carousel
          sliderRef={sliderRef}
          showModal={showModal}
          slides={data}
        />
      </Box>
    </Box>
  );
}
