import { Typography } from "@mui/material";
import classes from "./index.module.scss";

export default function CardTitle({ title }) {
  return (
    <Typography component="h1" variant="h1" className={classes.title}>
      {title}
    </Typography>
  );
}
