import { Box, Rating as StarsRating } from "@mui/material";
import { ReactComponent as StarEmpty } from "../../../../assets/icons/star-empty.svg";
import { ReactComponent as StarFilled } from "../../../../assets/icons/star-filled.svg";
import classes from "./index.module.scss";

export default function Rating({ Controller, control, errors, name }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Box className={classes.wrapper}>
            <label className={classes.label}>Stars *</label>
            <StarsRating
              className={classes.rating}
              {...field}
              precision={0.1}
              max={5}
              icon={<StarFilled />}
              emptyIcon={<StarEmpty />}
            />
            {errors[name] && (
              <span className={classes.error}>{errors[name].message}</span>
            )}
          </Box>
        );
      }}
    />
  );
}
