export const setLecturesPath = ({ isAuth, lectureId }) =>
  `${
    isAuth
      ? process.env.REACT_APP_LECTURES_PATH
      : process.env.REACT_APP_LECTURES_UNREGISTERED_PATH
  }/${lectureId}`;

export const setCoursePath = ({ isAuth, id }) =>
  `${
    isAuth
      ? process.env.REACT_APP_SINGLE_COURSE_PATH
      : process.env.REACT_APP_SINGLE_COURSE_UNREGISTERED_PATH
  }/${id}/content`;
