import { Box, Container } from "@mui/material";
import classes from "./index.module.scss";
import { LandingLayout, PlatformLayout } from "../../../../components/Layouts";
import Dashboard from "../Dashboard";
import { useParams } from "react-router-dom";
import { useStudentRating } from "../hooks/useStudentRating";
import Loader from "../../../../components/Common/Loader";

export default function StudentRating({isAuth}) {
  const { id } = useParams();
  const { data: rating, isLoading: isRatingLoading } =
    useStudentRating({
      id,
    });

  const student = rating?.userRating?.usersRating;

  return (
    <>
      {isRatingLoading ? <Loader /> : null}
      {isAuth ? (
        <PlatformLayout>
          <Box className={classes.body}>
            <Dashboard rating={student} />
          </Box>
        </PlatformLayout>
      ) : (
        <LandingLayout>
          <Container
            className={classes.wrapper}
            maxWidth={false}
            disableGutters
          >
            <Container className={classes.container} maxWidth="lg">
              <Dashboard rating={student} />
            </Container>
          </Container>
        </LandingLayout>
      )}
    </>
  );
}
