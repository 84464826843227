import { Link, Outlet } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import { ReactComponent as LogoWhite } from "../../../assets/images/logo-white.svg";
import { Container, Typography, Box } from "@mui/material";
import classes from "./index.module.scss";

export default function AuthLayout() {
  const year = new Date().getFullYear();

  return (
    <Container maxWidth={false} disableGutters className={classes.container}>
      <Container maxWidth={"lg"} className={classes.wrapper}>
        <Box className={classes.body}>
          <Box className={classes.layout}></Box>
          <Box component={"section"} className={classes.content}>
            <Box component={"header"} className={classes.header}>
              <Link to={"/"}>
                <LogoWhite />
              </Link>
            </Box>
            <Box component={"main"} className={classes.main}>
              <Box component={"section"} className={classes.card}>
                <Outlet context={{}} />
              </Box>
            </Box>
            <Box component={"footer"} className={classes.footer}>
              <Logo />
              <Typography className={classes.policy}>
                By continuing forward you agree to the{" "}
                <Typography className={classes.policy__link} component={Link} to={"/privacy-policy"}>
                  Privacy Policy
                </Typography>{" "}
                and the{" "}
                <Typography className={classes.policy__link} component={Link} to={"/terms-and-conditions"}>
                  Terms of Service
                </Typography>
                .
              </Typography>
              <Typography className={classes.rights}>
                {year} Engenious University Inc. (c) All rights reserved
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Container>
  );
}
