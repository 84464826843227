import { useQuery } from "@tanstack/react-query";
import { api } from "../../../../instances/axiosInstances";

async function fetchSpecializations() {
  const response = await api.get(`/mentorsSpecializations`);
  const data = { specializations: response.data };
  return data;
}

export function useSpecializations() {
  return useQuery({
    queryKey: ["mentorsSpecializations"],
    queryFn: () => fetchSpecializations(),
    staleTime: Infinity,
  });
}
