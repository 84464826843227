import { useContext, useState } from "react";
import Logo from "../common/Logo";
import NavMenu from "../common/NavMenu";
import classes from "./index.module.scss";
import BurgerButton from "../common/BurgerButton";
import useMixpanel from "../../../../hooks/useMixpanel";
import { WhiteButton } from "../../../MUI/buttons/button";
import { AuthContext } from "../../../../context/AuthContext";
import useButtonClick from "../../../../hooks/useButtonClick";
import { AppBar, Box, Container, Toolbar } from "@mui/material";
import { ReactComponent as BurgerIcon } from "./images/burger.svg";
import { mixpanelLandingHeaderBurger } from "../../../../constants/mixpanel";
import { ReactComponent as UniversityLogo } from "../../../../assets/images/logo.svg";

export default function Header({ variant = "landing" }) {
  const { auth } = useContext(AuthContext);

  const [openMenu, setOpenMenu] = useState(false);

  const { onStartForFreeClick } = useButtonClick();
  const { handleEventTrack } = useMixpanel();

  const handleDrawerToggle = () => {
    setOpenMenu((prevState) => !prevState);
  };

  const { signUpLogin } = mixpanelLandingHeaderBurger;

  const isPlatformVariant = variant === "platform";

  return (
    <>
      <AppBar
        className={classes.appBar}
        component="header"
        sx={{
          position: isPlatformVariant && "sticky",
          backgroundColor: isPlatformVariant
            ? "var(--on-surface-f)"
            : "var(--on-surface-a-100)",
          boxShadow: "none",
        }}
        id="header"
      >
        <Container
          maxWidth="lg"
          // sx={{
          //   padding: isPlatformVariant ? "0px !important" : "",
          // }}
        >
          <Toolbar
            className={classes.toolbar}
            sx={{
              minHeight: "unset !important",
              padding: "0px !important",
            }}
          >
            <Logo>
              <UniversityLogo />
            </Logo>

            <Box className={classes.box}>
              {auth ? null : (
                <WhiteButton
                  isBig={false}
                  variant="outlined"
                  rel="noreferrer"
                  onClick={(e) => {
                    handleEventTrack(e, signUpLogin);
                    onStartForFreeClick();
                  }}
                >
                  Sign In
                </WhiteButton>
              )}

              <Box
                className={`${classes.burger} ${
                  isPlatformVariant && classes.platformBurger
                }`}
              >
                <BurgerButton handleDrawerToggle={handleDrawerToggle}>
                  <Box className={classes.innerBurger}>
                    <BurgerIcon />
                  </Box>
                </BurgerButton>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <NavMenu
        open={openMenu}
        variant={variant}
        handleDrawerToggle={handleDrawerToggle}
      />
    </>
  );
}
