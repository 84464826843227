import { Input } from "../../components/MUI/inputs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";

export default function Step1({
  register,
  errors,
  trigger,
  setValue,
  getValues
}) {
  const handlePhoneInputChange = (value, country, e, formattedValue) => {
    trigger(["phoneNumber"]);
    setValue("phoneNumber", formattedValue);
  };
  return (
    <>
      <Input
        label="First name*"
        type="text"
        margin="normal"
        variant="outlined"
        fullWidth
        autoComplete="new-password"
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        placeholder="First name"
        {...register("firstName", {
          onChange: () => trigger(["firstName"]),
        })}
        error={!!errors.firstName}
        helperText={errors.firstName && errors.firstName.message}
      />
      <Input
        label="Last name*"
        type="text"
        margin="normal"
        variant="outlined"
        fullWidth
        autoComplete="new-password"
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        placeholder="Last name"
        {...register("lastName", {
          onChange: () => trigger(["lastName"]),
        })}
        error={!!errors.lastName}
        helperText={errors.lastName && errors.lastName.message}
      />

      <Box>
        <PhoneInput
          country={"us"}
          countryCodeEditable={false}
          copyNumbersOnly={false}
          value={getValues("phoneNumber")?getValues("phoneNumber"):""}
          containerClass={`${classes.phone} ${
            errors.phoneNumber ? classes.phone__error : ""
          }`}
          onChange={handlePhoneInputChange}
          inputProps={{
            autoComplete: "new-password",
          }}
          specialLabel="Phone Number*"
        />
        {errors.phoneNumber && (
          <Typography
            sx={{
              color: "var(--colors-b-100)",
              fontSize: "0.75rem",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "120%",
              marginLeft: "0.5rem",
              marginTop: "3px",
            }}
          >
            {errors.phoneNumber.message}
          </Typography>
        )}
      </Box>
      <Input
        label="Discord username*"
        type="text"
        margin="normal"
        variant="outlined"
        fullWidth
        autoComplete="new-password"
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        placeholder="Discord username"
        {...register("discordUsername", {
          onChange: () => trigger(["discordUsername"]),
        })}
        error={!!errors.discordUsername}
        helperText={errors.discordUsername && errors.discordUsername.message}
      />
    </>
  );
}
